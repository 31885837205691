<template>
    <div class="img_round_bx"> 
        <div class="image_round">
            <img :src="img">
            <font-awesome-icon icon="fa-solid fa-circle-check" />
        </div>
        <div class="round-info">
            <h2>{{ name }}</h2>
            <p><font-awesome-icon icon="fa-solid fa-user" />{{ designation }}</p>
            <!-- <div class="star-rate">
                <span v-for="(n,index) in 5" :key="index" :class="n <= banner.StarRating ? 'yellow' : 'gray'">
                    <font-awesome-icon icon="fa-solid fa-star" />
                </span>
            </div> -->
        </div> 
    </div>
</template>

<script>
export default {
    name: "BannerDetail",
    props: {
    name: String,
    img: String,
    designation: String
  }
    
}
</script>