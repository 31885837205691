import ApiService from '../api.service'
// import axios from 'axios';

class SearchService {
    constructor() {
        this.request = ApiService;
    }
    
    
    getCountryList = () => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            //alert(bearerToken);
            
    
            this.request.get('/country/list')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    getCityList = () => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            //alert(bearerToken);
            
    
            this.request.get('/city/list')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    getLocationList = (cityId) => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            //alert(bearerToken);
            
    
            this.request.get('/location/list?city_id='+cityId)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    getLocationByid = (id) => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            //alert(bearerToken);
            
    
            this.request.get('/location/'+ id +'/view')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    getCityById = (id) => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            //alert(bearerToken);
            
    
            this.request.get('/city/'+ id +'/view')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
   
    getCategories = () => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            //alert(bearerToken);           
    
            this.request.get('/category/list')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    subcategories = (catid) => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            //alert(bearerToken);
            
    
            this.request.get('/subcategory/list?category_id='+catid)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    getSpecialities = (specId) => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            //alert(bearerToken);
            
    
            this.request.get('/specialty/list?category_id='+ specId.cat +'&sub_category_id='+ specId.sub)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    getBloodgroups = () => {
            return new Promise((resolve, reject) => {
                this.request.get('/blood-group/list')
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        };
        allLoad = () => {
            return new Promise((resolve, reject) => {
                this.request.get('/all-category-subcategory-specialty/list')
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        };
}

export default new SearchService();
