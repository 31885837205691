import router from '@/routers'
import UserService from "@/api-services/services/user.service";
export const userModule = {
    // This makes your getters, mutations, and actions accessed by, eg: 'myModule/myModularizedNumber' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,
    state: {
        login:[],
        userName:'',
        user:[],
        status:'',
        verify:'',
        logstatus:'',
        userid:'',
        loginTime:''
    },
    mutations: {
        LoginSuccess:(state,{data,z})=> {
            state.user= data.Data
            state.loginTime=data.Message
            state.userName=data.Data.customer_name
            //console.log(data);
            // state.status = userdetail.status            
            window.localStorage.setItem("userid", data.Data.id  );
            
            window.localStorage.setItem("logstatus", data.Status);
            
            router.push( z );
            
        },
        clerPOp:(state)=>{
            state.loginTime=''

        },
        LoginSuccess1:(state,{data,z})=> {
            state.user= data.Data
            state.userName=data.Data.customer_name
            //console.log(data);
            // state.status = userdetail.status            
            window.localStorage.setItem("userid", data.Data.id  );
            
            window.localStorage.setItem("logstatus", data.Status);
            
            router.push( z );
            
        },
        LoginPending:(state,userdetail)=> {
            state.user= userdetail;   
            state.userid = userdetail.Data.customer_id     
            router.push("signup/verify");
        },
        LoginError:(state,userdetail)=> {
                //alert("test")
            state.user= userdetail;
            window.localStorage.setItem("logstatus", userdetail.Status);
            
        },
        ClearLogin:(state)=> {
                //alert("test")
            state.user= '';           
            
        },
       
      

    },

    actions: {
        async LoginUser({commit},userdetail) {
           // alert("test")
            const x = userdetail.username
            const y = userdetail.password
            const z = userdetail.ro
            const response = await UserService.LoginUser(x,y);
            if(response.data.Status == 1) {
                const data = response.data
                
                window.localStorage.setItem("token", data.access_token);
                commit("LoginSuccess",{data,z} ); 
                
            }
            else if(response.data.Status == 2) {
                commit("LoginPending", response.data); 
                
            }
            else {
                commit("LoginError",response.data);               
            }
        },
        async getUser({commit},id) {
            // alert("test")
             const response = await UserService.getUser(id);
             if(response.data.Status == 1) {
                 const data = response.data
                 commit("LoginSuccess1",{data} ); 
                 
             }
             
         },
        

    },
    getters: {
        getUser: (state) => state.user,
        userName:(state) => state.userName,
        loginMessage: (state) => state.status,
        logStatus: (state) =>  state.logstatus,
        id: (state) => state.userid,
        check: (state) => state.loginTime

    }


  };
  
//   export default userModule