<template>
    <div class="list_page_layout">
        <AppHeaderVue></AppHeaderVue>
            <div class="wrapper">
                <div class="privcyPolicy404">
                    <h2>404 <br>Page not found</h2>
                    
                </div>
            </div>
        <AppFooter></AppFooter>
        
    </div>
</template>

<script>
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
export default {
    name: "ErroNull",
    components: {
        AppHeaderVue,
        AppFooter
    },
   


    
}
</script>
<style>
.privcyPolicy {
    margin: 50px 0;
}
</style>