<template>
    <div class="addNewFam">
        <h2 class="my_title_d" style="text-align: center;"> Add Member</h2>
        <div class="famform">
            
            <div class="fields-fam">
                <div>
                    <label>Full Name <b>*</b></label>
                    <input v-model="familymember.name" @focus="clearN()">
                    <span class="error">{{ errors.name }}</span>
                </div>                
                
                <div class="err_space">
                    <label>Mobile Number <b>*</b></label>
                    <div class="prefix new-fm-prefix " :class="{hideOnlod: onld == 'hideOnlod'}">
                        <span >+91</span>
                        <input v-model="familymember.PH" @input="filterNonNumeric()" @blur="validatePh()" @focus="clearMobError">
                    </div>
                    <span v-if="errors.ph">{{ errors.ph }}</span>
                    <span v-if="errorM.mobile">{{ errorM.mobile[0] }}</span>
                    
                </div>
            </div>
            <div class="fields-fam">
                <div class="err_space">
                    <label>Email Address<b>*</b></label>
                    <input v-model="familymember.email" @blur="validateEmail()" @focus="clearE">
                    <span v-if="errors.mail">{{ errors.mail }}</span>
                </div>
                <div>
                    <label >Relation<b>*</b></label>
                    <!-- <input v-model="familymember.relation" > -->
                    <select v-model="familymember.relation" @change="clearR">
                        <option v-for="(rel,j) in ListRel" :key="j"  :value="rel.id">{{ rel.name}}</option>
                    </select>
                    
                    <span class="error">{{ errors.relation }}</span>
                </div>
                
            </div>
            <div class="fields-fam">
                <div>
                    <label>Gender<b>*</b></label>
                    <select v-model="familymember.gender" @change="clearG">
                        <option value="2">Male</option>
                        <option value="1">Female</option>
                        <option value="3">Others</option>
                    </select>
                    <span class="error">{{ errors.gender }}</span>
                </div>
                <div>
                    <label>Blood Group<b>*</b></label>
                    <!-- <input placeholder="" v-model="familymember.bloodg"> -->
                    <select  v-model="familymember.bloodg" @change="clearB">
                        <option v-for="(group,j) in bloodGroup" :key="j"  :value="group.id">{{ group.blood_group_name }}</option>
                    </select>
                    <span class="error">{{ errors.blood }}</span>
                </div>
                <div>
                    <label>DOB<b>*</b></label>
                    <input type="date" v-model="familymember.dob" :max="currentDate" @focus="clearD" @blur="checkDate">
                    <span class="error">{{ errors.dob }}</span>
                </div>
            </div>
            <div class="fields-fam no-full_width address-f">
                <div>
                    <label>Address</label>
                    <textarea v-model="familymember.address" @focus="clearA"></textarea>
                    <span class="error">{{ errors.adrs }}</span>
                </div>
            </div>
            <div class="fields-fam adrs-fm change_password_fm ">
                <button @click="submitFam()">Add Member</button>                
                <span class="loader" v-if="loader && errorM ==''"></span>
                <!-- <div class="error" v-if="errors.all">{{ errors.all }}</div> -->
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "AddnewFam",
    data() {

return {

    familymember:
        {
            name: '',
            PH:'',
            email:'',
            gender:'',
            bloodg:'',
            address:'',
            relation: '',
            dob:'',
            // image:''
        },
        errors:{
            name:'',
            mail:'',
            ph:'',
            relation:'',
            gender:'',
            blood:'',
            dob:'',
            adrs:''
                
        },
        loader:false,
        currentDate: new Date().toISOString().split('T')[0],
        onld:'hideOnlod',
        verify:false
       
}

},
computed:{
        ...mapGetters({
        errorM:"familyModule/ErrorMessage",
        ListRel:"familyModule/ListRelation",
        bloodGroup:"searchModule/Bloodlist",
        
        }),
    },
created(){
    this.loadRelations()

},
methods: {
        submitFam(){
            this.loader = true;
            

                if (this.familymember.PH  && this.familymember.bloodg && this.familymember.dob && 
                this.familymember.email && this.familymember.gender && this.familymember.name && this.familymember.relation) {
                    this.errors.all = '';
                    this.loader = true;
                    this.$store.dispatch('familyModule/addFamily', this.familymember);
                } else {
                    this.loader = false;
                    if (this.familymember.name === '') {
                        this.errors.name = 'Full name required';
                    }
                    if (this.familymember.PH === '') {
                        this.errors.ph = 'Mobile Number required';
                    }
                    if (this.familymember.email === '') {
                        this.errors.mail = 'Email required';
                    }
                    if (this.familymember.gender === '') {
                        this.errors.gender = 'Gender required';
                    }
                    if (this.familymember.bloodg === '') {
                        this.errors.blood = 'Blood Group required';
                    }
                  
                    if (this.familymember.relation === '') {
                        this.errors.relation = 'Relation required';
                    }
                    if (this.familymember.dob === '') {
                        this.errors.dob = 'Date of Birth required';
                    }
                }
        },
        validatePh() {
            if(this.familymember.PH==''){
                this.onld='hideOnlod'
            }
            else {
                this.onld=''
            }
            if (!/^(\+\d{1,3}[- ]?)?\d{10}$/i.test(this.familymember.PH)) {
                this.errors.ph="Please enter valid mobile number"
                this.familymember.PH =''
                
            }
            else {
                this.errors.ph="",
                this.verify=true
                
            }  
            
        },
        filterNonNumeric() {
					this.familymember.PH = this.familymember.PH.replace(/[^0-9]/g, "");
                    this.errors.ph=''
		},
        validateEmail() {
            // eslint-disable-next-line
            if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/i.test(this.familymember.email) && this.familymember.email !='') {
        
                this.errors.mail="Please enter a valid email"
                this.familymember.email=''
                }
                else {
                    this.errors.mail=""

            }    
        },
        checkDate() {
                const inputDate = new Date(this.familymember.dob);
                const today = new Date();
                const day = String(today.getDate()).padStart(2, '0');
                const month = String(today.getMonth() + 1).padStart(2, '0'); // Note that months are zero-based
                const year = today.getFullYear();
                const x = `${day}-${month}-${year}`;

                if (inputDate <= today) {
                    this.errors.dob=''
                } else {
                    this.errors.dob =  'The selected date should be '+ x +' or earlier';
                    this.familymember.dob=''
                }
        },
        clearE(){
            this.errors.mail=''
        },
        clearMobError(){
            this.errors.ph=''
            this.$store.commit('familyModule/clearM');
            this.onld=''
        },
        loadRelations(){
            this.$store.dispatch('familyModule/getRelations');
            this.$store.dispatch("searchModule/getBloodgroups")
        },
        clearN(){
            this.errors.name=''
        },
        clearR(){
            this.errors.relation=''
        },
        clearG(){
            this.errors.gender=''
        },
        clearB(){
            this.errors.blood=''
        },
        clearD(){
            this.errors.dob=''
        },
        clearA(){
            this.errors.adrs=''
        },
        verifyFam(mob){
            this.$store.dispatch('familyModule/getRelations',mob);
        }



    }
    
   
    
    
}
</script>
<style>
/* .no-full_width{
    width: 50% !important;
} */
</style>