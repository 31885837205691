import axios from "axios";

axios.defaults.baseURL = 'https://hexprojects.in/Service-Provider-Customer/api';

export function get(url, params, headers) {
    return axios({
        method: 'GET',
        url: url,
        params: params,
        headers: headers
    })
}

export function post(url, params = {}, data = {}, headers) {
    return axios({
        method: 'POST',
        url: url,
        params: params,
        data: data,
        headers: headers
    })
}

export function put(url, params, headers) {
    return axios({
        method: 'PUT',
        url: url,
        params: params,
        headers: headers
    })
}

export function patch(url, params, headers) {
    return axios({
        method: 'PATCH',
        url: url,
        params: params,
        headers: headers
    })
}

export function deleteApi(url, params, headers) {
    return axios({
        method: 'DELETE',
        url: url,
        params: params,
        headers: headers
    })
}
