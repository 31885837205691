<template>
    <div class="header-main" > 
        <div class="top-s">
            <HeaderTop></HeaderTop> 
        </div>
        <div class="menu">
            <div class="wrapper">
                <div class="menu-bar">
                    <AppLogo></AppLogo>
                    <AppMenu :drp="dropdownV"></AppMenu>
                </div>
            </div>
            
        </div>

        <div class="popup-login" v-if="userDet">
            <div>
                <div class="checkmark-circle">
                  <div class="background"></div>
                  <div class="checkmark draw"></div>
                </div>
                <h3>{{ userDet }} </h3>   
              </div> 
            </div>
    </div>
</template>

<script>
import HeaderTop from './HeaderTop.vue';
import AppLogo from './AppLogo.vue';
import AppMenu from './AppMenu.vue';
import {mapGetters} from "vuex";
export default {
    name: "AppHeader",
    components: {
        HeaderTop,
        AppLogo,
        AppMenu
    },
    data(){
        return{
            prevRoute:'',
            dropdownV:''
        }

    },
    props:{
        SaveDeta:String
    },
    computed:{
        ...mapGetters({
            userDet:"userModule/check"
        }),
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    mounted () {
        window.scrollTo(0, 0)
        // this.$refs.parent.addEventListener('click', this.changeDataValue);
        
        },
    created(){
        this.Clear(this.SaveDeta)
        setTimeout(() => {
                //this.delayedValue = this.valueToDelay;
                this.$store.commit('userModule/clerPOp');

        }, 2000); 
    },
   
        methods:{
            Clear(v){
                setTimeout(() => {
                    if(v != true) {
                        sessionStorage.removeItem('slotId');
                        sessionStorage.removeItem('slotDate');
                        sessionStorage.removeItem('pid');
                        sessionStorage.removeItem('famId');
                        sessionStorage.removeItem('edit');
                        sessionStorage.removeItem('BookingID');
                    }
                }, 2000); 
            },

            
        },
}
</script>

<style>


</style>