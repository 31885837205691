<template>
    <div class="booking_bar_info">
            <div class="date">
                {{formattedDate }}
            </div>
            <div class="img_bk">
                <img :src="details.service_provider.service_provider_photo_url">
            </div>
            <div class="booking_d">
                <div>
                    <h3>{{details.service_provider.service_provider_name}}</h3>
                    <span>{{details.service_provider.service_provider_designation}}</span>
                    <div class="loc">
                        <font-awesome-icon icon="fa-solid fa-clock" />
                        <p>{{formattedDay}}, {{ formattedListSlot.time_start }}-{{ formattedListSlot.time_end }}</p>
                        </div>
                    <div class="loc">
                        <font-awesome-icon icon="fa-solid fa-location-dot" />
                        <p>{{details.service_provider.service_provider_address}}</p>
                    </div>
                        
                </div>
            </div>
            <div class="booking_fe_f">
                <p>Booking Charge </p>
                <span>₹{{details.service_provider.booking_charge}}</span>
            </div>
    </div>
 
</template>

<script>
// import {mapGetters} from "vuex";
export default {
    name: "BookingTopbar",
  
    data(){
        return {
            Date: sessionStorage.getItem('slotDate'),
            prID: sessionStorage.getItem('pid'),
            TimeS: sessionStorage.getItem('slotId'),
            day:''
            // passToTimeslot: {
            //     Date: sessionStorage.getItem('slotDate'),
            //     TimeS: sessionStorage.getItem('slotId'),
            // }
            
        }
    },
    props:{
        timeslot:Object,
        details:Object
    },
    created(){
        // this.loadProvider(this.prID)
        // this.loadTimeslot(this.passToTimeslot)
    },
    computed: {
        // ...mapGetters({
        //     // details:"providerModule/detail",
        //     // timeslot:"bookingModule/slotDet",
        
        // }),
        formattedDate() {
            const months = [
                "January", "February", "March", "April",
                "May", "June", "July", "August",
                "September", "October", "November", "December"
            ];

           // console.log(this.date)
            //const dateString = new Date(this.Date).toLocaleDateString('en-GB');
            // Split the originalDate into day, month, and year
            const [day, monthIndex] = this.Date.split('-');
            
            // Convert monthIndex to a number and subtract 1 since JavaScript months are 0-indexed
            const month = months[Number(monthIndex) - 1];
            
            // Return the formatted date
            return `${day}\n${month}`;
            },
            formattedDay() {
                let dateObject; 

                    if (this.day === '') {
                    dateObject = new Date(this.timeslot.day);
                    } else {
                    dateObject = new Date(this.day);
                    }
                    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                    return dayNames[Math.max(0, Math.min(dateObject - 1, 6))];
                },
                formattedListSlot() {
                    const formattedTime = { ...this.timeslot };

                    formattedTime.time_start = this.formatTime12Hr(this.timeslot.time_start);
                    formattedTime.time_end = this.formatTime12Hr(this.timeslot.time_end);

                    return formattedTime;
                 },
                },



    methods: {               
        
        // loadProvider(id){
        //     this.$store.dispatch("providerModule/loadDetails", id)
        // },
        // loadTimeslot(passToTimeslot){
        //     this.$store.dispatch("bookingModule/loadTimeByID", passToTimeslot)
        // },
        dateChan(e){
            if(e) {
                this.Date= e.date
                this.day=e.day
            }
        },
        formatTime12Hr(timeString) {
            const [hours, minutes] = timeString.split(':');
            const parsedHours = parseInt(hours, 10);
            const period = parsedHours >= 12 ? 'PM' : 'AM';
            const formattedHours = parsedHours % 12 === 0 ? 12 : parsedHours % 12;

            return `${formattedHours}:${minutes} ${period}`;
        },
    }
}
</script>