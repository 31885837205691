<template>
    <div class="mob-men" :class="{activebox: selectedLink === 'activemenu'}">
        <div class="header-main1"> 
            <ul>
                <li v-for="(item,index) in navItems" :key="index"  >
                    <router-link :to="{name:item.route}">{{ item.name }}</router-link>
                </li>
            </ul>  

            <div class="signin-bt dropdownCls hideOnmob" v-if="loggedInUser !=0">
                <a @click="dropdown" class="drp-clc"><font-awesome-icon icon="fa-solid fa-user" /> {{userDet}} <font-awesome-icon :icon="['fas', 'chevron-down']" /></a>
                <div class="drp-dwn" :class="{activeShow: dropdownV === 'activeShow'}">
                    <router-link :to="{name:'myaccount'}">My Account</router-link>
                    <router-link  :to="{name:'ChangePassword'}"> Change Password</router-link>
                    <a @click="alertLog">Logout</a>
                </div>
            </div>
            <!-- <div class="signin-bt dropdownCls hideOnWeb" v-if="loggedInUser !=0">
                <router-link :to="{name:'myaccount'}"><font-awesome-icon icon="fa-solid fa-user" /> {{userDet}} </router-link>
                
            </div> -->
            
   
            <div class="signin-bt" v-else>
                <router-link to="/user/signin">Login / Signup</router-link>
            </div>
           
            
        </div>
        <div class="signin-bt dropdownCls hideOnWeb" v-if="loggedInUser !=0">
                <a @click="dropdown" class="drp-clc"><font-awesome-icon icon="fa-solid fa-user" /> <font-awesome-icon :icon="['fas', 'chevron-down']" /></a>
                <div class="drp-dwn" :class="{activeShow: dropdownV === 'activeShow'}">
                    <router-link :to="{name:'myaccount'}">My Account</router-link>
                    <router-link  :to="{name:'ChangePassword'}"> Change Password</router-link>
                    <a @click="alertLog">Logout</a>
                </div>
            </div>
        <div  class="menu_button" @click="e => e.target.body.toggle('active')">
            <div @click="menu()"  :class="{activem: selectedLink === 'activemenu'}">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>

        <div class="updateSuccess  popup-login " v-if="showAlert" >
                        <div>
                            <p>Are you sure you want to logout?</p>
                            <div class="yN" >
                                <button @click="CloseAlert">Cancel</button>
                                <button @click="logout">Confirm</button>
                            </div>
                        </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "AppMenu",
    data(){
        return {
            selectedLink:'',
            selectedLink1:'',
            num:1,
            navItems: [
				{name: 'Home', route: 'Home'},                
                {name: 'Services', route: 'services'},
                {name: 'About Us', route: 'about'},
                {name: 'News & Events', route: 'newslist'},
                {name: 'Contact Us', route: 'contact'},
			],
            dropdownV:this.drp,
            showAlert:false
            
        }
    },
    props: {
        drp: {
            type: String
        },
    },
    computed:{
        ...mapGetters({
            userDet:"userModule/userName"
            
        }),
    loggedInUser() {
        
            if(localStorage.getItem('token')){
                return this.$store.getters['userModule/userName']
            }
            else {
                window.localStorage.setItem("userid", ""  );
                return 0
            }
        
    },
    },
    beforeCreate(){
        document.body.classList.remove('bg-scroll', 'blurbg');
        
        
    },
    created(){
        if(localStorage.getItem('logstatus')==1 && localStorage.getItem('userid') != '' ) {
            const id = localStorage.getItem('userid')
            this.$store.dispatch("userModule/getUser",id);
        }
    },
    mounted(){
        document.addEventListener('click', this.handleDocumentClick);
    },
    methods: {
            alertLog(){
                // this.selectedLink=''
               
                this.showAlert=true
            },
            CloseAlert(){
                //alert("t")
                this.showAlert=false
            },
            menu() {
                this.dropdownV=''
            
            if(this.selectedLink==''){
            this.selectedLink="activemenu"
            document.body.classList.add('bg-scroll', 'blurbg')
            }
            else {
                this.selectedLink=''
                document.body.classList.remove('bg-scroll', 'blurbg')
            }
              
        
            
        },
        handleDocumentClick(event) {
            if (!event.target.closest('.drp-clc')) {
                // The click is not inside .drp-clc or its descendants
                // Perform your action here
                this.dropdownV = ''; // Close the dropdown, for example
            }
        },
        
        // created() {
        //     this.activemenu('Home');
        // },
        activemenu(type) {
              this.selectedLink = type;
                
               // return this.newprofiles;
                
            },
            logout(){
                this.$store.dispatch("accountModule/LogoutUser")
                window.localStorage.removeItem('userid');
                window.localStorage.removeItem('logstatus');
                window.localStorage.removeItem('token');
                this.$router.push({
                    name: "signin",
                });
            },
    dropdown(){
        this.selectedLink = '';
        if(this.dropdownV==''){
            this.dropdownV="activeShow"
        }
        else {
            this.dropdownV=''
        }
    },
    
    }
    
}
</script>
<style>
.activeShow{
    display: block !important;
}
</style>