<template>
    <div class="menu_footer_block"> 
        <h3>{{ title }}</h3>
        <ul>
            <li v-for="(item,j) in menu" :key="j">
                <router-link :to="{name:item.link}">{{item.title}}</router-link>
            </li>
        </ul>  

        
    </div>
</template>

<script>

export default {
    name: "FooterMenu",
    props: {
        menu: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true,
        }
    }
    
}
</script>