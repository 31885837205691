<template>
<div class="booking_info_1 inner_page_layout">
        <AppHeaderVue :SaveDeta="save"></AppHeaderVue>
        <div class="wrapper">
            <h2 class="bk-hd summaryH" >Booking Summary</h2>
    <div class="summary">
        <div class="summary_doc_info">
            <img :src="summary.service_provider_details.service_provider_image">
            <div class="name_sum">
                <h3>{{summary.service_provider_details.service_provider_name}}</h3>
                <span>{{summary.service_provider_details.service_provider_designation}}</span>
                <div class="loc">
                <font-awesome-icon icon="fa-solid fa-location-dot" />
                    <p>{{summary.service_provider_details.service_provider_address }}
                    </p>
                </div>
            </div>
        </div>
        <div class="patient_sum">
            <div class="info_line">
                <span>Date & Time <b>:</b></span>
                <p>{{summary.customer_details.date_and_time}}</p>
            </div>
            <div class="info_line">
                <span>Booked For <b>:</b></span>
                <p>{{ summary.customer_details.booked_for }}</p>
            </div>
            <div class="info_line">
                <span>Date Of Birth <b>:</b>   </span>
                <p>{{ summary.customer_details.date_of_birth }}</p>
            </div>
            <div class="info_line">
                <span>Gender <b>:</b></span>
                <p >{{summary.customer_details.gender.name}}</p>
            </div>
            <div class="info_line">
                <span>Mobile Number <b>:</b>   </span>
                <p>{{summary.customer_details.mobile_number}}</p>
            </div>
            <div class="info_line">
                <span>Email <b>:</b>    </span>
                <p>{{ summary.customer_details.email }}</p>
            </div>
        </div>
        <div class="payment_sum">
            <div class="wsum">
                <div class="info_line amount_line">
                    <span>Total Amount   </span>
                    <p>₹{{summary.payment_details.total_amount}}</p>
                </div>
                <div class="togglepay" style="visibility: hidden;">
                    <div class="toggle-sum">
                        <font-awesome-icon :icon="['fas', 'chevron-down']" />
                    </div>
                    <div class="info_line pay">
                        <span>Consultation :  </span>
                        <p>₹{{ summary.payment_details.consultation_fee }}</p>
                    </div>
                    <div class="info_line pay">
                        <span>Booking Fee :  </span>
                        <p>₹{{summary.payment_details.booking_charge}}</p>
                    </div>
                    <!-- <div class="info_line pay">
                        <span>Others :  </span>
                        <p>₹100</p>
                    </div> -->
                </div>
            </div>

            <div class="button_confm_sum">
                <button class="edit_sum" ><router-link  :to="{name:'booknow', params: { PId: this.ID } }">Edit Booking</router-link></button>
                <button class="cf_sum" @click="ConfmBooking">Confirm Booking</button>
                
            </div>
            <div style="text-align: center;width:100%;position:absolute;bottom:0;" >
                <span class="loader" v-if="loader && error.length==0"></span>
                {{ error.Message }}
            </div>
        </div>
    </div>
    
</div>
<AppFooter></AppFooter>
</div>
</template>

<script>
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
import {mapGetters} from "vuex";
import router from '@/routers'
export default {
    name: "BookingSummary",
    components: {
    AppHeaderVue,
    AppFooter
    // BookingSummary
    // Datepicker
    
    },
    data() {
        return {
            save:true,
            ID: sessionStorage.getItem('pid'),
            bookingData: {
                id: localStorage.getItem('userid'),
                date: sessionStorage.getItem('slotDate'),
                serviceProvider: sessionStorage.getItem('pid'),
                timeslotID: sessionStorage.getItem('slotId'),
                familyID: sessionStorage.getItem('famId'),

            },
            loader:false,
        }
    },
    created(){

        this.loadInfo();
    },
    methods: {
        loadInfo(){

                    if(this.summary.length==0) {
                        router.push({ name: 'booknow', params: { PId: this.ID } });
                    }
            // this.$store.dispatch("providerModule/loadDetails", this.ID)
        },
        ConfmBooking(){
            this.loader=true
            if(this.bookingData.familyID==null  || this.bookingData.familyID=='undefined'){
                this.bookingData.familyID=''
            }
            this.$store.dispatch("bookingModule/confirmBooking", this.bookingData)
                    
                    // router.push({ name: 'Home' });
        }
    },
    computed:{
        ...mapGetters({
            summary:"bookingModule/Summary",
            error:"bookingModule/ConfrmError",
        
        }),
        // saveD(){
        //     sessionStorage.setItem('BookingID', this.summary.booking_id);
        //     //return this.summary.booking_id
        // }
        
        
        
        
    },
    
}
</script>