<template>
    <div class="related_profiles">
        <div class="wrapper">
            <h2>Recently Booked</h2>

            <div class="comments owl-wrapper">                            
                <Carousel :responsive="{0:{items:1,nav:false,dots:false},480:{items:1.2,nav:false,dots:true,loop:true,margin:10},500:{items:1.5,nav:false,dots:true,loop:true,margin:10},1000:{items:3,nav:false,dots:true,loop:true,margin:10}}" >
                     <SingleProfile v-for="(rel,index) in profiles" :key="index" :profile="rel"></SingleProfile>
                </Carousel>
                            
            </div>
        </div>
    </div>
                
</template>

<script>
import Carousel from 'vue-owl-carousel';
import SingleProfile from '../Home/SingleProfile.vue';

export default {
    name: "RecentlyBooked",
    components: {
        Carousel,
        SingleProfile
    },
    data(){
        return{
            profiles: [
				{ 
                    id: '1', 
                    name: 'Dr. Roy',
                    dept: 'Dentist',
                    category: 'Doctor',
                    adrs: 'Indiranagar,Bangalore ', 
                    shortset: 'All Care Dental Centre - since 1969 ₹300 Consultation fee at clinic',
                    img: 'img1'
                },
                { 
                    id: '2', 
                    name: 'Dr. Roy',
                    dept: 'Dentist',
                    category: 'Doctor',
                    adrs: 'Indiranagar,Bangalore ', 
                    shortset: 'All Care Dental Centre - since 1969 ₹300 Consultation fee at clinic',
                    img: 'img1'
                },
                { 
                    id: '3', 
                    name: 'Dr. Roy',
                    dept: 'Dentist',
                    category: 'Doctor',
                    adrs: 'Indiranagar,Bangalore ', 
                    shortset: 'All Care Dental Centre - since 1969 ₹300 Consultation fee at clinic',
                    img: 'img1'
                },
                { 
                    id: '4', 
                    name: 'Dr. Roy',
                    dept: 'Dentist',
                    category: 'Doctor',
                    adrs: 'Indiranagar,Bangalore ', 
                    shortset: 'All Care Dental Centre - since 1969 ₹300 Consultation fee at clinic',
                    img: 'img1'
                },
                { 
                    id: '5', 
                    name: 'Dr. Roy',
                    dept: 'Dentist',
                    category: 'Advocate',
                    adrs: 'Indiranagar,Bangalore ', 
                    shortset: 'All Care Dental Centre - since 1969 ₹300 Consultation fee at clinic',
                    img: 'img1'
                },
                { 
                    id: '6', 
                    name: 'Dr. Roy',
                    dept: 'Dentist',
                    category: 'Artisan',
                    adrs: 'Indiranagar,Bangalore ', 
                    shortset: 'All Care Dental Centre - since 1969 ₹300 Consultation fee at clinic',
                    img: 'img1'
                },
                
			],
        }
    }

    
    
}
</script>