<template>
    <div class="booking_date_det">
        <h2>Book Your Appointment Now</h2>
        <div class="rate">Booking Charge : ₹{{ fees }} / Person</div>
        <!-- <img src="@/assets/images/date.png" class="sate"> -->
        <CalenderPopup :id="id" :show="showpopup"></CalenderPopup>
        <p class="tc_d">Note: Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
           Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        </p>
    </div>
</template>

<script>
import CalenderPopup from '../booking/CalenderPopup.vue';
export default {
    name: "CalenderBook",
    components: {
        CalenderPopup
    },
    data(){

        return {
            selectedLink:'',
            showpopup:true
        }
    },

    created() {

        this.tabs('today');
    },
    props: {
        fees: String,
        id: String

    },
    methods: {
        tabs(type) {
        this.selectedLink = type;
        //   this.selectedLink2 = type;
        //   this.tabc(type);
            
        },

    }
    
}
</script>