<template>
    <div class="page_creeer"  >
        <div class="cTitle" :class="{activeAc: career.title === selectedLink}" @click="AddActive(career.title)">
            {{ career.title }} <font-awesome-icon icon="fa-solid fa-chevron-down" />
        </div>
        <div class="cInfo" >
            <span class="infoExp"><b>Experience : </b>{{career.experiance }} </span>
            <div class="des_exp">
                {{ career.description }}
            </div>
            <button @click="showForm(career.id)">Apply Now</button>
        </div>
            
    </div>
</template>

<script>
export default {
    name: "SingleCareer",
    data(){
        return {
            selectedLink:'',
            x: 0
        }
    },
    props: {
        career: {
            type: Object,
            required: true
        },
    },
        methods: {
            AddActive(e){
               //alert("test");
                
                if(this.x <= 1 ) {
                    this.selectedLink = e;
                    this.x=this.x+1;
                }
                if(this.x > 1 ) {
                    this.selectedLink = '';
                    this.x=0;
                }
                
            },
            showForm(id){
                this.$emit('passId',{
                    ID: id
                });
            }
        }
        
    

}
</script>
<style>
/* .counter{
    background-image: url(@/assets/images/colorbg.jpg);
} */
</style>