import passwordService from "@/api-services/services/password.service";
import router from '@/routers'
export const ChangeModule = {
        // This makes your getters, mutations, and actions accessed by, eg: 'myModule/myModularizedNumber' instead of mounting getters, mutations, and actions to the root namespace.
        namespaced: true,
        state: {
            messageChange:'',
            successChange:'',
            errormess:'',
            otp:[],
            mess:'',
            otpError:'',
            resetSuccess:'',
            successVer:'',
            otpErrorVerify:''
        },
        mutations: {
            ChangeSuccess:(state,{data})=> {
                state.successChange= data.Message
                window.localStorage.removeItem('userid');
                window.localStorage.removeItem('logstatus');
                window.localStorage.removeItem('token');
                // this.$router.push({
                //     name: "signin",
                // });
                router.push({ name: "signin"});
                
            },
            ChangePending:(state,{data})=> {
                state.messageChange= data.Message
                //alert(state.mes);
                // router.push( z );
                
            },
            otpsend:(state,{data})=> {
                state.mess= data.Message,
                state.otp= data.Data
                // this.$router.push({
                //     name: "resetforgot",
                // });
                // router.push({ name: "resetforgot"});
                router.push({ name: "forgotOTP"});
                
            },
            ForgotVefifySuccess:(state,{data})=> {
                state.successVer= data,
                router.push({ name: "resetforgot"});
                
            },
            ForgotVefifyError:(state,{data})=> {
                state.otpErrorVerify= data.Message
                
            },
            ResetSuccess:(state,{data})=> {
                state.resetSuccess= data.Message,
                state.otp= data.Data
                // this.$router.push({
                //     name: "resetforgot",
                // });
                router.push({ name: "signin"});
                
            },
            ForgotError:(state,{data})=> {
                state.otpError= data.Message
                // state.otp= data.Data
                // this.$router.push({
                //     name: "resetforgot",
                // });
                router.push({ name: "forgot"});
                
            },
            MobileError:(state,{data})=> {
                state.errormess= data.Message
                //alert(state.mes);
                // router.push( z );
                
                
            },
            resetMessage(state){
                state.mess = '';
                state.otpErrorVerify=''
            },
            resetState(state) {
                // alert("t");
                // Reset state properties to initial values
                state.resetSuccess = '';
                state.errormess = '';
                state.otpError='';
                state.messageChange='';
                state.successChange=''
              }
        },
        actions: {
            async ResetPassword({commit},cred) {
                 let bearerToken = localStorage.getItem('token');
                 const response = await passwordService.ResetPassword(bearerToken,cred);
                 if(response.data.Status == 1) {
                     const data = response.data
                     //console.log(data);
                     commit("ChangeSuccess",{data} ); 
                     
                 }
                
                 else {
                    const data = response.data
                    // console.log(data);
                     commit("ChangePending",{data});               
                 }
    
             },

             async ForgotPassword({commit},mob) {
                const response = await passwordService.ForgotPassword(mob);
                if(response.data.Status == 1) {
                    const data = response.data
                    //console.log(data);
                    commit("otpsend",{data} ); 
                    
                }
               
                else {
                   const data = response.data
                   // console.log(data);
                    commit("MobileError",{data});               
                }
   
            },
            async ForgotOTP({commit},otp) {
                const response = await passwordService.ForgotOTP(otp);
                const data = response.data
                if(response.data.Status == 1 ) {
                    
                    console.log(data);
                    commit("ForgotVefifySuccess",{data} ); 
                    
                }
               
                else {
                   const data = response.data
                   // console.log(data);
                    commit("ForgotVefifyError",{data});               
                }
   
            },
            async ResendOTP({commit},mob) {
                const response = await passwordService.ResendOTP(mob);
                if(response.data.Status == 1) {
                    const data = response.data
                    //console.log(data);
                    commit("otpsend",{data} ); 
                    
                }
               
                else {
                   const data = response.data
                   // console.log(data);
                    commit("ForgotVefifyError",{data});               
                }
   
            },
            async ForgotReset({commit},creden) {
                //alert(id);
                const response = await passwordService.ForgotReset(creden);
                console.log(response);
                if(response.data.Status == 1) {
                    const data = response.data
                    //console.log(data);
                    commit("ResetSuccess",{data} ); 
                    
                }
               
                else {
                   const data = response.data
                   // console.log(data);
                    commit("ForgotError",{data});               
                }
   
            },
        },
        getters: {
            me: (state) => state.messageChange,
           nomobile: (state) => state.errormess,
           messotp: (state) => state.mess,
           resetSuccess:(state) => state.resetSuccess,
           otpsend: (state) => state.otp,
           error: (state) => state.errormess,
           error1: (state) => state.otpError,
           ChangeSuccess: (state) => state.successChange,
           otpError: (state) => state.otpErrorVerify,
           otpResetPge:(state) =>state.successVer
        }
    
    }