<template>
    <div class="location_map"> 
        <iframe :src="googleMapsSrc" width="600" height="450" 
        style="border:0;" allowfullscreen=""  ></iframe>
    </div>
</template>

<script>
export default {
    name: "LocationMap",
    data(){
        return {
            apiKey:"AIzaSyBI5pFIiLp7OEN7JCX5q1Bad852kUMpQmk"
        }
    },
    props: {
        lat: {
            type: String
        },
        long: {
            type: String
        },
    },
    computed: {
        googleMapsSrc() {
        return `https://www.google.com/maps/embed/v1/place?q=${this.lat},${this.long}&key=${this.apiKey}`;
        },
    },
    
}
</script>