<template>
    <div class="my_acc_nt">  
        <div class="updateSuccess popup-login" v-if="this.successM">
            <div>
            <div class="checkmark-circle">
                  <div class="background"></div>
                  <div class="checkmark draw"></div>
                </div>
            <h3 style="display: block;    width: 100%;">{{ this.successM }} </h3>
        </div>
        </div>       
        <div class="mine" :style="{ opacity: this.successM ? 0.5 : 1 }">

            
            <h2 class="my_title_d">My Account</h2>
            <div class="fl-ed">
                <div class="profile-img">
                    <div class="showH_Upload">
                        <!-- <font-awesome-icon icon="fa-solid fa-pen" /> -->
                        <span>Change Image</span>
                        <input type="file" @change="updateImage" class="hoverUplod">
                    </div>   
                    <img src="@/assets/images/Portrait_Placeholder.png" v-if="!profile.imageUrl">    
                    <img :src="profile.imageUrl" v-else>
                    
                    <!-- <span class="loader" v-else></span> -->
                </div>
                <div class="edit_profile">
                    <span @click="saveData()" class="save-ac">Save</span>
                    <span class="loader" v-if="loader1"></span>
                </div>

            </div>
            <span v-if="errors.imageFile" class="imageError">{{ errors.imageFile }}</span>
            <div class="detrail-form">
                <div class="form-fl">
                    <label>Full Name<b>*</b></label>
                    <input placeholder="" v-model="profile.name" >
                    <span  v-if="errors.name">{{ errors.name }}</span>
                </div>
                <div class="form-fl">
                    <label>Mobile Number<b>*</b></label>
                    <div class="prefix">
                        <span >+91</span>
                        <input placeholder="" v-model="profile.mob"  @input="filterNonNumeric()" ref="readOnlyInput" @focus="preventEditing" disabled class="dsbld">
                    </div>
                    
                    <span v-if="errors.mob">{{ errors.mob }}</span>
                </div>
                <div class="form-fl">
                    <label>Email<b>*</b></label>
                    <input placeholder="" v-model="profile.mail" @blur="validateEmail()"  @focus="clear('mail')">
                    <span v-if="errors.mail">{{ errors.mail }}</span>
                </div>
                <div class="form-fl">
                    <label>Gender<b>*</b></label>
                    <select v-model="profile.gender" @change="clear('gender')">
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="3">Others</option>
                    </select>
                    <span v-if="errors.gender">{{ errors.gender }}</span>
                </div>
                <div class="form-fl">
                    <label>DOB<b>*</b></label>
                    <input placeholder="" type="date" v-model="profile.dob" :max="currentDate" @focus="clear('dob')" @blur="checkDate">
                    <span v-if="errors.dob">{{ errors.dob }}</span>
                    
                </div>
                <div class="form-fl">
                    <label>Blood Group<b>*</b></label>
                    <!-- <input placeholder="" v-model="profile.bloodgroup"> -->
                    <select  v-model="profile.bloodgroup" @change="clear('bloodgroup')">
                        <option v-for="(group,j) in bloodGroup" :key="j"  :value="group.id">{{ group.blood_group_name }}</option>
                    </select>
                    <span v-if="errors.bloodgroup">{{ errors.bloodgroup }}</span>

                </div>
                <div class="form-fl" style="width:100%;">
                    <h3>Address</h3>
                </div>
                <div class="form-fl ">
                    <label>House Number<b>*</b></label>
                    <input placeholder="" v-model="profile.housenumber" @focus="clear('housenumber')">
                    <span v-if="errors.housenumber">{{ errors.housenumber }}</span>
                </div>
                <div class="form-fl ">
                    <label>Street<b>*</b></label>
                    <input placeholder="" v-model="profile.street" @focus="clear('street')">
                    <span v-if="errors.street">{{ errors.street }}</span>
                </div>
                <div class="form-fl ">
                    <label>Country<b>*</b></label>
                    <select v-model="profile.country" @change="clear('country')">
                        <option v-for="(city,j) in country" :key="j"  :value="city.country_id">{{ city.country_name }}</option>
                    </select>
                    <span v-if="errors.country">{{ errors.country }}</span>
                </div>
                <div class="form-fl ">
                    <label>City<b>*</b></label>
                    <select v-model="profile.city"  @change="handleSelectChange" v-if="cities.length != 0">
                        <option v-for="(city,j) in cities" :key="j"  :value="city.id">{{ city.city_name }}</option>
                    </select>
                    <select v-model="profile.city"  @click="loadAll"  v-else>
                        <option :value="city.id">{{ city.city_name }}</option>
                    </select>
                    <span v-if="errors.city">{{ errors.city }}</span>
                </div>
                <div class="form-fl ">
                    <label>Location<b>*</b></label>
                    <select v-model="profile.location" @change="clear('location')"  v-if="locations.length != 0">
                        <option v-for="(loc,j) in locations" :key="j"  :value="loc.id">{{ loc.location_name }}</option>
                    </select>
                    <select v-model="profile.location" @change="clear('location')"  v-else>
                        <option :value="location.id">{{ location.location_name }}</option>
                    </select>
                    <span v-if="errors.location">{{ errors.location }}</span>
                </div>
                <div class="form-fl ">
                    <label>Pincode</label>
                    <input placeholder="" v-model="profile.pincode">
                </div>
            </div>
            
        </div>
         
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "AccountDetails",
    data(){
        return {
            profile:{
                name:'',
                mob:'',
                mail:'',
                gender:'',
                // dob: new Date().toISOString().substr(0, 10),
                dob: '',
                bloodgroup:'',
                housenumber:'',
                street:'',
                country:'',
                city:'',
                location:'',
                pincode:'',
                imageUrl:'',
                imageFile:''
            },
            isReadOnly: true,
            selectedCity:'',

            errors:{
                
                name:'',
                mob:'',
                mail:'',
                gender:'',
                dob: '',
                bloodgroup:'',
                housenumber:'',
                street:'',
                country:'',
                city:'',
                location:'',
                pincode:'',
                imageUrl:'',
                imageFile:''
            },
            flag:'1',
            loader1:false,
            currentDate: new Date().toISOString().split('T')[0],
        }
    },
    computed:{
        ...mapGetters({
        details:"accountModule/profile",
        successM:"accountModule/successMessage",
        cities:"searchModule/citylist",
        locations:"searchModule/locationlist",
        country:"searchModule/countrylist",
        bloodGroup:"searchModule/Bloodlist",
        failM:"accountModule/failMessage",
        city:"searchModule/cityId",
        location:"searchModule/locationId",
        
        
        }),
        // filteredItems() {
        //     const condition = this.selectedCity; 
        //     if(condition){
                
        //     return Object.values(this.locations).filter(item => item.city_id == condition);
        //     }
        //     return this.locations
        // },
       
        successS() {
        return this.successM;
        },
        
        
    },
    watch: {
        
        successS: {
        deep: true,
        handler() {
            this.messageHide();
        },
        },
        details(newValue){
            if (newValue.customer_name) {
                this.profile.name = newValue.customer_name;
            }
            if (newValue.customer_email) {
                this.profile.mail = newValue.customer_email;
            }
            if (newValue.customer_mobile) {
                this.profile.mob = newValue.customer_mobile;
            }
            if ( newValue.customer_gender.id) {
                this.profile.gender = newValue.customer_gender.id;
            }
            if (newValue.blood_group.id) {
                this.profile.bloodgroup = newValue.blood_group.id;
            }
            if (newValue.dob) {
                this.profile.dob = newValue.dob;
            }
            if (newValue.image) {
                const lastSlashIndex = newValue.image.lastIndexOf('/');
                if (lastSlashIndex >= 0) {
                    const substringAfterLastSlash = newValue.image.slice(lastSlashIndex + 1);
                    var x= substringAfterLastSlash.includes('.');
                }
                if(x){
                    this.profile.imageUrl = newValue.image;
                }
                else {
                    this.profile.imageUrl = '';
                }
                
            }
            if (newValue.customer_address.house_number) {
                this.profile.housenumber = newValue.customer_address.house_number;
            }
            if (newValue.customer_address.country_id) {
                this.profile.country = newValue.customer_address.country_id;
            }
            if (newValue.customer_address.city_id) {
                this.profile.city = newValue.customer_address.city_id;
            }
            if (newValue.customer_address.location_id) {
                this.profile.location = newValue.customer_address.location_id;
            }
            if (newValue.customer_address.street) {
                this.profile.street = newValue.customer_address.street;
            }
            if (newValue.customer_address.pin) {
                this.profile.pincode = newValue.customer_address.pin;
            }

            
            if (/^\d{2}-\d{2}-\d{4}$/.test(newValue.dob)) {
                const [day, month, year] = newValue.dob.split("-");
                this.profile.dob = `${year}-${month}-${day}`;
            }
            // this.getLocations(newValue.customer_address.city_id);
            
                this.getLocationById(newValue.customer_address.location_id);
                this.getCitybyiD(newValue.customer_address.city_id);

            
        }
        
    },
    created() {
        this.getdetails(),
        this.getCitybyiD(),
        this.getCountries(),
        this.getLocationById(),
        this.getBloodgroups();
        
    },
    
    methods: {

        // filterNonNumeric() {
		// 			// Replace non-numeric characters with an empty string
		// 			this.ph = this.ph.replace(/[^0-9]/g, "");
		// },
        validateEmail() {
            // eslint-disable-next-line
            if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/i.test(this.mail) && this.mail !='') {
        
                this.errors.mail="Please enter a valid email"
                this.mail=''
                }
                else {
                    this.errors.mail=""

            }
              
        },
        
    
    validatePh() {
        

        if (!/^(\+\d{1,3}[- ]?)?\d{10}$/i.test(this.ph)) {
            this.errors.mob="Please enter valid mobile number"
            this.ph=''
            
        }
        else {
            this.errors.mob=""
            
        }  
    },
    clear(name){
            //this.errors.mail=""
            this.errors[name]=''
        if(name=='country'){
            this.profile.city=''
            this.profile.location=''
        }
        },


        getdetails(){
            this.$store.dispatch("accountModule/getProfile")
        },
        // changeVal() {
            
        //     this.profile.name=this.details.customer_name;
        //     this.profile.mail=this.details.customer_email;
        //     this.profile.mob=this.details.customer_mobile;
        //     this.profile.gender=this.details.customer_gender.id;
        //     this.profile.bloodgroup=this.details.blood_group.id;
        //     this.profile.dob=this.details.dob;
        //     this.profile.housenumber=this.details.customer_address.house_number;
        //     this.profile.country=this.details.customer_address.country_id;
        //     this.profile.city=this.details.customer_address.city_id;
        //     this.profile.location=this.details.customer_address.location_id;
        //     this.profile.street=this.details.customer_address.street;
        //     this.profile.pincode=this.details.customer_address.pin;
        //     this.profile.imageUrl=this.details.image;
            
            
        // },
        messageHide(){
            this.loader1=false;
            setTimeout(() => {
                //this.delayedValue = this.valueToDelay;
                this.$store.commit('accountModule/clearM');

            }, 2000);
        },
        saveData(){
            // this.loader1=true;
            //alert("test")
            if (this.profile.name && this.profile.mail && this.profile.bloodgroup  && this.profile.city && this.profile.country &&
             this.profile.dob  && this.profile.gender &&  this.profile.street   && this.profile.housenumber && this.profile.location && this.profile.imageUrl) {
                this.loader = true;
                //alert("test")
                this.$store.dispatch("accountModule/setProfile", this.profile);
                }

            else {
                //alert("test")
                for (const key in this.profile) {
                    if (!this.profile[key]) {
                        this.errors[key] = "This field is Mandatory.";
                    }
                    }
            }
            
        },
        getCities() {
            this.$store.dispatch("searchModule/getCityList")
        },
        handleSelectChange(event) {
            this.errors.city=''
            this.selectedCity = event.target.value;
            this.getLocations(this.selectedCity)
        },
        getLocations(id) {
            this.$store.dispatch("searchModule/getLocationList", id)
        },
        getCountries(){
            this.$store.dispatch("searchModule/CountryList")
        },
        updateImage(event) {
            //alert("t")
            this.errors.imageFile=''
            const maxSize = 2 * 1024 * 1024;
            var file = event.target.files[0];
            
            
            if (file && file.size > maxSize) {
                this.errors.imageFile='File size exceeds 2MB. Please select a smaller file.';
                event.target.value = null; // Reset the input element to clear the file selection
                file=''
            } else {
                this.profile.imageFile=event.target.files[0];
            }
            
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                this.profile.imageUrl = e.target.result;
                };
                reader.readAsDataURL(file);
            }

            setTimeout(() => {
                this.errors.imageFile=''

            }, 2000);
            },
            getBloodgroups(){
                this.$store.dispatch("searchModule/getBloodgroups")
            },
            preventEditing() {
            this.$refs.readOnlyInput.blur(); // Remove focus immediately
            },
            getLocationById(id){
                //alert("tets")
                this.$store.dispatch("searchModule/getLocationById",id)
            },
            getCitybyiD(id){
                //alert("tets")
                this.$store.dispatch("searchModule/getCItyById",id)
            },
            loadAll(){
                this.errors.city=''
                this.getCities()
            },
            checkDate() {
                const inputDate = new Date(this.profile.dob);
                const today = new Date();
                const day = String(today.getDate()).padStart(2, '0');
                const month = String(today.getMonth() + 1).padStart(2, '0'); // Note that months are zero-based
                const year = today.getFullYear();
                const x = `${day}-${month}-${year}`;

                if (inputDate <= today) {
                    this.errors.dob=''
                } else {
                    this.errors.dob =  'The selected date should be '+ x +' or earlier';
                    this.profile.dob=''
                }
                }
            
    }
    
}
</script>

<style>
.dsbld{
    /* opacity: .7; */
    background: #dddddd;
}
</style>