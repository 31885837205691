<template>
    <div class="related_profiles">
        <h2>Related Profiles</h2>

            <div class="comments owl-wrapper" v-if="related">                            
                <Carousel :responsive="{0:{items:1,nav:false,dots:false},600:{items:1.8,nav:false,dots:true,loop:false,margin:10},
            1200:{items:3,nav:false,dots:true,loop:false,margin:10}
            }" >
                     <RelatedSingle v-for="(rel,index) in related" :key="index" :profile="rel"></RelatedSingle>
                </Carousel>
                            
            </div>
    </div>
                
</template>

<script>
import Carousel from 'vue-owl-carousel';
import RelatedSingle from './RelatedSingle.vue';

export default {
    name: "RelatedProfile",
    components: {
        Carousel,
        RelatedSingle
    },
    props: {
        related:{
            type: Array,
            required: true
        },

    }

    
    
}
</script>