<template>
    <div class="single_profile related-single"> 
          <div class="profile_img">
            <router-link
            :to="{ name: 'single_provider', params: { providername: profile.service_provider_name  }, query: { Id: profile.id } }"
                        class="position-route"></router-link>
            <img :src="profile.service_provider_photo_url">
          </div>
          <div class="profile_det">
            <router-link
            :to="{ name: 'single_provider', params: { providername: profile.service_provider_name  }, query: { Id: profile.id } }"
                        class="position-route"></router-link>
            <h3>{{ profile.service_provider_name }}</h3>
            <span class="dept">{{ profile.service_provider_designation }}</span>
            <p class="exp_adrsp">{{ profile.service_provider_address }}</p>
            <p class="shrt-des">{{ profile.service_provider_organisation }}</p>
            <p class="shrt-des">₹{{ profile.consultation_fee }}</p>
          </div>
    </div>
</template>

<script>
export default {
    name: "RelatedSingle",
    props: {
        profile:{
            type: Object,
            required: true
        }
    }
    
}
</script>