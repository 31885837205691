import router from '@/routers'
import UserService from "@/api-services/services/user.service";
export const userRegister = {
    // This makes your getters, mutations, and actions accessed by, eg: 'myModule/myModularizedNumber' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,
    state: {
        fieldError:'',
        adduser:[],
        otpMessage:'',
        otperror:'',
        CustId:'',
        code:'',
        resend:'',
       otpErrorMess:[]
        // regmessag:''
    },
    mutations: {
        

      RegisterSuccess:(state,userdetail)=> {
            state.adduser= userdetail
            state.CustId= userdetail.Data.customer_id
            state.code = userdetail.Data.otp
            // state.regstat = "Success"  
            //alert("successvalue;")        
            // window.localStorage.setItem("userid", userdetail.customer_id  );
            //  router.push("/user/signup/verify");
             router.push({ name: "otp"});
            
        },
        RegisterErrorRemove:(state)=> {
            
            state.fieldError= '';
            // alert( state.mailerror)     
            // console.log(state.mailerror.Message  )
            
        },
        RegisterError:(state,userdetail)=> {
            state.fieldError= userdetail.Message;
            // alert( state.mailerror)     
            // console.log(state.mailerror.Message  )
            
        },
        OtpSuccess:(state,otpdetails)=> {
            state.otpMessage= otpdetails;
            // router.push("/user/signin");
            router.push({ name: "signin"});
            
        },
        OtpError:(state,otpdetails)=> {
            state.otpErrorMess= otpdetails;
            // router.push("/user/signup");
            
        },
        resendOtp:(state,otpdetails)=> {
            state.resend= otpdetails;
            state.otpMessage= otpdetails;
            // router.push("/user/signup");
            state.CustId= otpdetails.Data.customer_id
        },
        emptyMessage:(state) => {
            state.otpMessage= '';
           
        },
        resetMessage(state){
            state.otpErrorMess=''
        },

    },

    actions: {
        
        async RegisterUser({commit},userdetail) {
            // const x = userdetail.username
            // const y = userdetail.password
            const response = await UserService.RegisterUser(userdetail);
           // console.log(response.data);
            if(response.data.Status == 1) {
               // alert("success");
                commit("RegisterSuccess", response.data); 
                
            }        
            if(response.data.Status == 0) {
               // alert("fail");
              commit("RegisterError",response.data); 
              
          }
        },
        async VerifyOTP({commit},otpdetails) {
            // const x = userdetail.username
            // const y = userdetail.password
            const response = await UserService.VerifyOTP(otpdetails);
           // console.log(response);
            if(response.data.Status == 1) {
                commit("OtpSuccess", response.data); 
                
            }        
            else {
              commit("OtpError",response.data); 
              
          }
        },
        
        async ResendOTP({commit},id) {
            // const x = userdetail.username
            // const y = userdetail.password
            const response = await UserService.ResendOTP(id);
            //console.log(response);
            commit("resendOtp", response.data); 
                
            
        },
        

    },
    getters: {
        register: (state) => state.adduser,
        regid: (state) => state.CustId,
        regotp: (state) => state.code,
        fieldError: (state) => state.fieldError,
        regMessage: (state) => state.otpMessage,
        reOTP: (state) => state.resend,
        otpError: (state) => state.otpErrorMess

    }


  };
  
//   export default userModule