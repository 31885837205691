<template>
  <div>
    
      <carousel  v-if="imgs.length > 0" 
                      :responsive="{0:{items:1,nav:false,dots:false, autoplay:true},600:{items:2,nav:false,dots:false,margin:20, autoplay:true}}"
                  >
        <a v-for="(item, j) in imgs" :key="j" :href="item.ads_redirection_URL" class="show-hide-img image">
          <img alt="Vue logo" :src="item.ads_image" :style="item.style" />
        </a>
    </carousel>

  </div>
    
  </template>
  
  <script>
  import carousel from 'vue-owl-carousel';
  export default {
    name: "AdsHome",
    components: { 
        carousel
    
    },
    props: {
      imgs: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        currentIndex: 0,
      };
    },
    computed: {
      visibleImages() {
        const start = this.currentIndex;
        const end = (this.currentIndex + 2) % this.imgs.length;
        return [this.imgs[start], this.imgs[end]];
      },
    },
    methods: {
      toggleImages() {
        this.visibleImages.forEach((image) => {
          image.style = {
            display: "none",
          };
        });
  
        this.currentIndex = (this.currentIndex + 2) % this.imgs.length;
  
        this.visibleImages.forEach((image) => {
          image.style = {
            display: "block",
          };
        });
      },
    },
    mounted() {
      this.toggleImages();
      setInterval(this.toggleImages, 8000); // Adjust the time interval as needed
    },
  };
  </script>
  