
<template>
    <div>
        <div class="bookings"> 

            <div class="updateSuccess  popup-login" v-if="cancelMsg">
                <div>
                    <div class="checkmark-circle">
                        <div class="background"></div>
                        <div class="checkmark draw"></div>
                    </div>
                    <h3 style="display: block;    width: 100%;"> {{ cancelMsg }}</h3>
                </div>
            </div>

            <h2 class="my_title_d"> My Bookings</h2>
            <div class="center-loader" v-if="BookingList.length==0">
                <span class="loader" ></span>
            </div>
            <div class="center-loader" v-else-if="BookingList.data.length==0 ">
                <p>No Bookings <br>
                
                </p>
            </div>
            <div v-else>
                <BookingSingle  v-for="(booking,i) in BookingList.data" :key="i" :booking="booking" ></BookingSingle>
            </div>
            

            <div class="pagination" v-if="pageValues.length > 3">
                <ul>
                    <li v-for="(pages,i) in pageValues" :key="i"  :class="{ activePage: pages.active, disabled: pages.page==null  } "   @click="loadBookings(pages.page)">
                        <span v-if="pages.label== '&laquo; Previous'">Previous</span> 
                        <span v-else-if="pages.label=='Next &raquo;'">Next</span> 
                        <span v-else>{{ pages.label }}</span> 
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import BookingSingle from './BookingSingle.vue';
import {mapGetters} from "vuex";

export default {
    name: "MyBookings",
    components: {
        BookingSingle
    },
    
    data(){
        return {
            loader:false
        }
    },
    
    computed:{
        ...mapGetters({
            BookingList:"bookingModule/Bookinglist",
            cancelMsg:"bookingModule/cancel",        
        }),
        pageValues() {
            const extractedValues = this.BookingList.links;
            const result = [];
                if(extractedValues){
            for (const link of extractedValues) {
                if (link.url) {
                const url = new URL(link.url);
                const page = url.searchParams.get("page");
                result.push({
                    ...link,
                    page: page
                });
                } else {
                result.push({
                    ...link,
                    page: null
                });
                }
            }
        }

        return result;
        }
    
    },
    mounted(){
        sessionStorage.removeItem('slotId');
                    sessionStorage.removeItem('slotDate');
                    sessionStorage.removeItem('pid');
                    sessionStorage.removeItem('famId');
                    sessionStorage.removeItem('edit');
                    sessionStorage.removeItem('BookingID');
    },
    created() {
        this.loadBookings()
        setTimeout(() => {
                //this.delayedValue = this.valueToDelay;
                this.$store.commit('bookingModule/clearCancelMessage');

            }, 1000);
    },
    methods:{
        loadBookings(pageid){
            this.$store.dispatch("bookingModule/getBookings",pageid)
        }
    }
}
</script>