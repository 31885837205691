<template>
    <div class="signin forgot"> 
        
        <div class="authform">
            
            <div>
                <AuthTitle :title="title"></AuthTitle>
                <div class="error" v-if="this.Forgoterror" >{{ this.Forgoterror }}</div>
                <div class="form">
                    <label>Mobile Number
                        <input v-model="mob" @input="filterNonNumeric()" @blur="validatePh()" @focus="reseterror()" maxlength="10">
                        <span v-if="error">{{ this.error }}</span>
                    </label>
                    <button @click="SubmitPassword">Send OTP</button>
                    <div class="error" v-if="errorm" >{{ this.errorm }}</div>
                    
                    <span class="loader" v-if="loader && errorm  ==''"></span>
                </div>
            </div>
            
        </div>
        <img src="@/assets/images/pswd1.png" class="pos-image-auth">
        

    </div>
</template>

<script>
import {mapGetters} from "vuex";
// import { mapMutations } from 'vuex';
import AuthTitle from '@/components/Authentication/AuthTitle.vue';
export default {
    name: "ForgotPassword",
    components: {
        AuthTitle
    },
   
    data(){
        return {
            title:'Reset Password ',
            mob:'',
            error:'',
            loader:false,
            flag:0,
            checkMessage:'',
            errorotp:''
            

        }
    },
    computed:{
        ...mapGetters({
            errorm:"ChangeModule/error",
            Forgoterror:"ChangeModule/error1",
            
        }),
       
    },
    beforeDestroy() {
        this.clearmessage()
    },
    mounted() {
        this.error='';

    },
    methods: {
        SubmitPassword(){
            
            if( this.mob =='' || this.flag == 0) {
                this.error="Enter a valid Mobile Number"
                
            }
            else {
                this.loader=true
                this.$store.dispatch("ChangeModule/ForgotPassword",this.mob);  
                
            }
            
        },
        filterNonNumeric() {
					// Replace non-numeric characters with an empty string
					this.mob = this.mob.replace(/[^0-9]/g, "");
				},
        validatePh() {            

            if (!/^(\+\d{1,3}[- ]?)?\d{10}$/i.test(this.mob) && this.mob !='') {
                this.error="Enter a valid mobile number"
                this.flag= 0
            }
            else {
                this.error=""
                this.flag= 1
            }  
        },
        reseterror(){
                    this.error='';
                    // this.errorm=''
                    if(this.errorm) {
                        this.loader=false
                        this.$store.commit('ChangeModule/resetState');
                    } 
                    
                },
        clearmessage(){
            if(this.Forgoterror) {
                        this.loader=false
                        this.$store.commit('ChangeModule/resetState');
                    } 
        }
    //     ...mapMutations(['resetState'])
    }
   
    
}
</script> 