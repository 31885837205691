import SearchService from "@/api-services/services/search.service";
// import ServiceproviderService from "@/api-services/services/serviceprovider.service";
// import router from '@/routers'
export const searchModule = {
    // This makes your getters, mutations, and actions accessed by, eg: 'myModule/myModularizedNumber' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,
    state: {
        cities:[],
        locations:[],
        categories:[],
        specialties:[],
        countries:[],
        bloodgroups:[],
        subcategories:[],
        Nosubcategories:[],
        city:[],
        location:[],
        AllCAtList:[]
        // providers:[],
        // SearchEmpty:[]
    },
    
    mutations: {
      CityList:(state,{data})=> {
          state.cities= data.Data          
          
      },
      LocationList:(state,{data})=> {
          state.locations= data.Data          
          
      },
      CityByid:(state,{data})=> {
        state.city= data.Data          
            
        },
        LocationByid:(state,{data})=> {
            state.location= data.Data          
            
        },
      CategoryList:(state,{data})=> {
          state.categories= data.Data          
          
      },
      specialtyList:(state,{data})=> {
        state.specialties= data.Data         
        
     },
     ListCountry:(state,{data})=> {
        state.countries= data.Data         
        
     },
     bloodList:(state,{data})=> {
        state.bloodgroups= data.Data         
        
     },
     subcat:(state,{data})=> {
        state.subcategories= data.Data         
        
     },
     Nosubcat:(state,{data})=> {
        state.Nosubcategories= data         
        
     },
     AllList:(state,{data})=> {
        state.AllCAtList= data         
        
     },

     clearLoc:(state)=> {
        state.locations= []         
        
     },
     clearsubCat:(state)=> {
        state.subcategories= [] , 
        state.Nosubcategories= []        
        
     },
     clearAll:(state)=> {
        
        state.cities=[],
        state.locations=[],
        state.categories=[],
        state.specialties=[],
        state.subcategories=[],
        state.Nosubcategories=[]
     },
     clearspecialty:(state)=> {
        state.specialties=[]
     },
    
    //  searchList:(state,{data,filter})=> {
    //     state.providers= data.Data 
    //     router.push({ name: "Search", query: { 
    //         category: filter.category ,
    //         subCategory: filter.subcategory ,
    //         specialty: filter.specialty ,
    //         serviceProviderName: filter.name ,
    //         city: filter.city ,
    //         location: filter.location ,
    //         appointmentDate: filter.date ,

    //      }} );      
        
    // },
    // noresult:(state,{data})=> {
    //     state.SearchEmpty= data       
        
    // },

    },

    actions: {
        async CountryList({commit}) {
            //alert("test")
             const response = await SearchService.getCountryList();
            
            if(response.data.Status == 1) {
             
                const data = response.data
                //console.log(data);
                commit("ListCountry",{data} ); 
                
            }
            
        },
      async getCityList({commit}) {
         //alert("test")
          const response = await SearchService.getCityList();
         
         if(response.data.Status == 1) {
          
             const data = response.data
             //console.log(data);
             commit("CityList",{data} ); 
             
         }
         
     },
     async getLocationList({commit},cityId) {
        //alert("test")
        const response = await SearchService.getLocationList(cityId);
        
        if(response.data.Status == 1) {
        
            const data = response.data
            //console.log(data);
            commit("LocationList",{data} ); 
            
        }
        
    },
    async getLocationById({commit},id) {
        //alert("test")
        const response = await SearchService.getLocationByid(id);
        
        if(response.data.Status == 1) {        
            const data = response.data
            //console.log(data);
            commit("LocationByid",{data} ); 
            
        }
        
    },
    async getCItyById({commit},id) {
        //alert("test")
        const response = await SearchService.getCityById(id);
        if(response.data.Status == 1) {
          
            const data = response.data
            //console.log(data);
            commit("CityByid",{data} ); 
            
        }
        
    },
    async getCategories({commit}) {
        //alert("test")
        const response = await SearchService.getCategories();
        
        if(response.data.Status == 1) {
        
            const data = response.data
            //console.log(data);
            commit("CategoryList",{data} ); 
            
        }
        
    },
    async getSpecialities({commit},specId) {
      //alert("test")
       const response = await SearchService.getSpecialities(specId);
      
          const data = response.data
          //console.log(data);
          commit("specialtyList",{data} ); 
      
    },
    async getsubCats({commit},catId) {
        //alert("test")
         const response = await SearchService.subcategories(catId);
        
        if(response.data.Status == 1) {        
            const data = response.data
            commit("subcat",{data} );             
        }
        else {
            const data = response.data
            commit("Nosubcat",{data} );  
        }
        
      },
    async getBloodgroups({commit}) {
        //alert("test")
        const response = await SearchService.getBloodgroups();
        
            const data = response.data
            //console.log(data);
            commit("bloodList",{data} ); 
        
    },
    // async searchProviders({commit}, filter) {
    //     //alert("test")
    //      const response = await ServiceproviderService.getproviders(filter);
        
    //     if(response.data.Status == 1) {         
    //         const data = response.data
    //         // console.log(data.Data.data);
    //         commit("searchList",{data,filter} );             
    //     }
    //     else {
    //         const data = response.data
    //         commit("noresult",{data} ); 
    //     }
        
    // },
    async AllFilterCat({commit}) {
        //alert("test")
        const response = await SearchService.allLoad();
        
            const data = response.data
            commit("AllList",{data} ); 
         
        
    },
     

    },
    getters: {
      citylist: (state) => state.cities,
      locationlist: (state) => state.locations,
      categories: (state) => state.categories, 
      specialties: (state) => state.specialties,
      countrylist: (state) => state.countries,
      Bloodlist: (state) => state.bloodgroups,
      subcatList: (state) => state.subcategories,
      nosubCat: (state) => state.Nosubcategories,
      cityId: (state) => state.city,
      locationId: (state) => state.location,
      AllL: (state) => state.AllCAtList
    }


  };
  
//   export default userModule