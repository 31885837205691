<template>
    <div class="detaile_booking">
      <div class="bk_bt">
          <router-link  :to="{name:'mybookings'}">
            <button> Back</button>
          </router-link>
        </div>
      <div class="div_flex_bk">
        <div class="d_details">
          <img :src="details.service_provider_image_url">
          <h3>{{details.service_provider_name}}</h3>
          <div class="desi_d">{{details.service_provider_designation}}</div>
          <!-- <span class="q1">BDS, MDS  & Maxillofacial  Surgery </span> -->
          <div class="loc">
              <font-awesome-icon icon="fa-solid fa-location-dot" />
              <p>{{details.address}}</p>
          </div>
        </div>
        <div class="b_det">
          <button  :class="getStatusClass(details.booking_status_id)">{{ details.booking_status_name }}</button>
          <div class="details_b">
            <p>
              <b>Date & Time :</b>
              <span>{{details.date}}, <i style="font-size: 80%;">{{details.time_from}}-{{ details.time_to }}</i> </span>
            </p>
            <p>
              <b>Booked For :</b>
              <span>{{details.booked_for}}</span>
            </p>
            <p>
              <b>Booking Charge :</b>
              <span><b>₹{{details.amount}}</b></span>
              
            </p>
            <p>
              <b>Booking ID :</b>
              <span><b>{{details.booking_number}}</b></span>
            </p>
          </div>
          <div class="btns">
            <!-- <router-link  :to="{name:'booknow', params: { PId: details.service_provider_id } }" class="fif edit_b" @click="saveDetails"
            :disabled="!details">
               <button class="fif edit_b"  >
                Edit Booking
              </button>
            </router-link> -->

           
            <button class="fif cancel_b "  @click="alertLog"  :disabled="!details">
              Cancel Booking
              
            </button>
            <button class="full_w direc_b" :class = "getStatusClass1(details.booking_status_id)">
              <a :href="`https://www.google.com/maps/search/?api=1&query=${details.service_provider_latitude},${details.service_provider_longitude}`" target="_blank">
                    Get Directions
              </a>
            </button>
          </div>
        </div>
      </div>
      <div class="banner_det">
        <b>{{details.booking_number}}</b>
        <p>{{details.date}}</p>
        <span>{{details.day}} , {{ details.time_from }} - {{ details.time_to }}</span>
      </div>


      <div class="updateSuccess  popup-login " v-if="showAlert" >
                        <div>
                            <p>Are you sure you want to cancel this booking?</p>
                            <div class="yN" >
                                <button @click="CloseAlert">Cancel</button>
                                <button @click="cancleBooking(bookingid)" class="pos-loadr Cancel-bkng-ldr">
                                  Confirm                                  
                                  <span class="loader" v-if="loader"></span>
                                </button>
                            </div>
                        </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "BookingDetail",
    data(){
      return{
        save:true,
        loader:false,
        showAlert:false
      }
    },
    props:{
      bookingid:String
    },
    
    computed:{
        ...mapGetters({
        details:"bookingModule/detailsBooking",
        
        }),
    
    },
    watch: {
      details(newValue) {
        setTimeout(() => {
        sessionStorage.setItem('slotId', newValue.timeslot.id);
        const inputDate = newValue.date;

        const parts = inputDate.split(' ');

        const monthNames = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const monthIndex = monthNames.indexOf(parts[1]);
        const month = monthIndex !== -1 ? (monthIndex + 1).toString().padStart(2, '0') : '';
        const formattedDate = `${parts[0]}-${month}-${parts[2]}`;
        sessionStorage.setItem('slotDate', formattedDate);

        sessionStorage.setItem('pid',newValue.service_provider_id);
        sessionStorage.setItem('BookingID',this.bookingid)
      }, 2000);
      },
    },
    created() {
      this.getDetails(this.bookingid)
      //this.saveDetails()
      
    },
    methods: {
      cancleBooking(id){
        this.loader=true

        this.$store.dispatch("bookingModule/cancelBooking", id)
      },
      getDetails(id){
        this.$store.dispatch("bookingModule/bookingDetails", id)
      },
      // saveDetails(){
      //   sessionStorage.setItem('slotId', this.details.timeslot.id);
      //   const inputDate = this.details.date;

      //   const parts = inputDate.split(' ');

      //   const monthNames = [
      //     "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      //     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      //   ];
      //   const monthIndex = monthNames.indexOf(parts[1]);
      //   const month = monthIndex !== -1 ? (monthIndex + 1).toString().padStart(2, '0') : '';
      //   const formattedDate = `${parts[0]}-${month}-${parts[2]}`;
      //   sessionStorage.setItem('slotDate', formattedDate);

      //   sessionStorage.setItem('pid',this.details.service_provider_id);
      // }
      getStatusClass(id) {
        if (id === 3 || id === 4) {
          return "redCancel";
        } else if (id === 5 || id === 2) {
          return "Confm";
        } else {
          return "pndng";
        }
      },
      getStatusClass1(id) {
        if (id === 3 || id === 4) {
          return "pndngD";
        } else if (id === 5 || id === 2) {
          return "ConfmD";
        } else {
          return "pndngD";
        }
      },

      alertLog(){
                // this.selectedLink=''
               
                this.showAlert=true
            },
            CloseAlert(){
                //alert("t")
                this.showAlert=false
            },
    }
    
    
}
</script>