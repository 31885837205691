<template>
    <div class="single_profile top-profile"> 
          <div class="profile_img">
            <router-link
            :to="{ name: 'single_provider', params: { providername: profile.service_provider_name  }, query: { Id: profile.service_provider_id } }"
                        class="position-route"></router-link>
            <img :src="profile.service_provider_image">
          </div>
          <div class="profile_det">
            <router-link
            :to="{ name: 'single_provider', params: { providername: profile.service_provider_name  }, query: { Id: profile.service_provider_id } }"
                        class="position-route"></router-link>
            <h3>{{ profile.service_provider_name }}</h3>
            <span class="dept">{{ profile.designation }}</span>
            <p class="exp_adrsp">{{ profile.address }}</p>
            <p class="shrt-des">{{ profile.organisation}}</p>
            <p class="shrt-des">Per person ₹{{ profile.consultation_fees }}</p>
          </div>
    </div>
</template>

<script>
export default {
    name: "SingleProfile",
    props: {
        profile:{
            type: Object,
            required: true
        }
    }
    
}
</script>
<style>
p.exp_adrsp {
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    min-height: 50px !important;

}
</style>