<template>
    <div class="bookings_top">        
        <div class="wrapper">
            <div class="title-sec-s">
                <h2 class="tile-h">{{ title2 }}</h2>
            </div>

            <div class="booking_tab">
                <div class="tab_head">
                    <!-- <span  @click="filterItems(category.type)">{{ category.label }}</span>                         -->
                    <ul>
                        <li :class="{active: selectedLink === 'all'}">
                            <span @click="filterItems('all')" >All</span>
                        </li>
                        <!-- <li v-for="category in uniqueCategories" :key="category" >
                            <span @click="filterItems(category.category)">{{ category.category }}</span>
                        </li> -->
                        <li v-for="category in uniqueCategories" :key="category" :class="{active: category === selectedLink}">
                          <span @click="filterItems(category)">{{ category }}</span>
                        </li>
                    </ul>
                </div>

                <div class="tab-co">
                    <SingleProfile v-for="(profile,index) in filteredItems" :key="index" :profile="profile">
                    </SingleProfile>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import SingleProfile from './SingleProfile.vue';
// import {mapGetters} from "vuex";
export default {
    name: "TopBooking",
    components: { 
        SingleProfile
    
    },
   
    props: {
        
        title2: {
            type: String,
            required: true,
            default: 'Top Bookings'
        },
        TopBookings:{
            type: Array
        }
    },
    data() {
		return {
            newprofiles:[],
            selectedLink: "all",
            
           
            }
        },
        created() {
            this.filterItems('all');
            // this.loadcat()
        },
        computed: {
            // ...mapGetters({
            //     categoryList:"searchModule/categories"
            // }),
            filteredItems() {
                return this.newprofiles;
            },
            uniqueCategories() {
                const uniqueCategories = this.TopBookings.filter((booking, index, self) =>
                    self.findIndex((b) => b.category == booking.category) ==  index
                    )
                    .map((booking) => booking.category);

                return uniqueCategories;
                },
            },
        watch: {
            TopBookings(newValue) {
                this.newprofiles=newValue
                //this.newprofiles=this.TopBookings
                // this.filterCat = newValue.filter((booking, index, self) =>
                // self.findIndex((b) => b.category === booking.category) === index
                // );
                
                
            }
            
        },
        methods: {
            filterItems(type) {
               //alert(type);
            //this.newprofiles = this.profiles;
                //alert(this.newprofiles);
              // console.log(this.newprofiles);
              
              this.selectedLink = type;
                if(type != 'all') {
                   // alert("t")
                    this.newprofiles = this.TopBookings.filter(m => m.category == type );  
                    //alert("t")
                   console.log(this.newprofiles);
                }         

                if(type == 'all') {
                    this.newprofiles = this.TopBookings ;
                }
               // return this.newprofiles;
                
            },
            // loadcat(){
            //     this.$store.dispatch("searchModule/getCategories")
            // }
		
	}

    
        
    
}
</script>