import ApiService from '../api.service'
import axios from 'axios';

class PasswordService {
    constructor() {
        this.request = ApiService;
    }
 
    
    ResetPassword=(bearerToken,cred) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.post('/change-password?customer_id='+ cred.uid +'&old_password='+ cred.oldpass +'&password='+ cred.newpass +'&password_confirmation='+ cred.confirmnew).then((response) => {
                resolve(response)
               // console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    ForgotPassword=(mob) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.post('forgot-password?customer_mobile='+ mob).then((response) => {
                resolve(response)
               // console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    ForgotReset=(creden) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        //alert(id);
        return new Promise((resolve, reject) => {
            return this.request.post('/reset-password?customer_id='+ creden.uid +'&password='+ creden.newpass+'&password_confirmation='+ creden.confirmnew ).then((response) => {
                resolve(response)
               // console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };

    ForgotOTP=(otp) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        //alert(id);
        return new Promise((resolve, reject) => {
            return this.request.post('/reset-password-otp-verify?customer_id='+ otp.id +'&otp='+ otp.code).then((response) => {
                resolve(response)
               // console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    ResendOTP=(otp) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        //alert(id);
        return new Promise((resolve, reject) => {
            return this.request.post('/resend-password-otp?customer_id='+ otp).then((response) => {
                resolve(response)
               // console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
   
}

export default new PasswordService();
