<template>
    <div class="signin signup"> 
        
        <div class="authform">
            
            <div>
                <AuthTitle :title="title"></AuthTitle>
                
                <div class="form error-pos">
                    <label> Name<b style="color:red;">*</b>
                        <input v-model="register.name" @blur="validateName()" @focus="reseterror('name')" @keyup.enter="registerUser">
                        <span v-if="errors.name">{{ errors.name }}</span>
                    </label>
                    <label>Email<b style="color:red;">*</b>
                        <input v-model="register.email" @blur="validateEmail()" @focus="reseterror('mail')" @keyup.enter="registerUser">
                        <span v-if="errors.mail">{{ errors.mail }}</span>
                        <span v-for="(error,i) in fieldError.customer_email" :key="i">{{ error }}</span>
                    </label>
                    
                    <label>Mobile Number<b style="color:red;">*</b>
                        <!-- <div class="mob_f">
                            <select disabled>
                                <option>+91</option>
                            </select>
                            <input v-model="register.mobilenumber" @blur="validatePh()"  @input="filterNonNumeric()">
                        </div> -->
                        <div class="prefix signinPrefix " :class="{hideOnlod: onld == 'hideOnlod'}">
                            <span >+91</span>
                            <input v-model="register.mobilenumber" @blur="validatePh()"  @input="filterNonNumeric()" @focus="reseterror('ph')" @keyup.enter="registerUser"
                            autocomplete="new-mobilenumber" maxlength="10">
                        </div>
                        
                        <span v-if="errors.ph">{{ errors.ph }}</span>
                        <span v-for="(error,i) in fieldError.customer_mobile" :key="i">{{ error }}</span>
                    </label>
                    <label>Password<b style="color:red;">*</b>
                        <span style="position: relative;" class="eye">
                             <input :type="type" v-model="register.password" @focus="reseterror('ps')" @blur="validatepass()" @keyup.enter="registerUser"
                             autocomplete="new-password">
                            <button class="tg-ps" @click="showPassword()" ><font-awesome-icon :icon="'fa-solid ' + btnText "/></button>
                        </span>
                        <span v-if="errors.ps">{{ errors.ps }}</span>
                    </label>
                    <label>Confirm Password<b style="color:red;">*</b>
                        <span style="position: relative;" class="eye">
                             <input :type="type1" v-model="register.confmpswd" @blur="validatecp()" @focus="reseterror('cp')" @keyup.enter="registerUser"
                             >
                            <button class="tg-ps" @click="showPassword1()" ><font-awesome-icon :icon="'fa-solid ' + btnText1 "/></button>
                        </span>
                        <span v-if="errors.cp">{{ errors.cp }}</span>
                        <span v-for="(error,i) in fieldError.password" :key="i">{{ error }}</span>
                    </label>
                    <button @click="registerUser"  >Register </button>
                    <span class="loader" v-if="loader && fieldError ==''"></span>
                    
                    <!-- <div class="error" v-if="errors.main">{{ errors.main }}</div> -->
                </div>
            </div>
            
        </div>
        <img src="@/assets/images/pswd.png" class="pos-image-auth">
        <div class="auth-right">
            <RedirectSection :message="authmessage"></RedirectSection>
        </div>

    </div>
</template>

<script>
import RedirectSection from "@/components/Authentication/RedirectSection.vue";
import AuthTitle from '@/components/Authentication/AuthTitle.vue';
//  import userRegister from "@/api-services/services/user.service";
import {mapGetters} from "vuex";
export default {
    name: "SignUp",
    components: {
        RedirectSection,
        AuthTitle
    },
    data(){
        return {
            title:'Register',
            authmessage:{

                title1: "Already have an account ?",
                Para: "Sign In and discover more",
                Link: 'signin',
                button: 'Sign In'

            },
            register: {
                name:'',
                email:'',
                mobilenumber:'',
                password:'',
                confmpswd: ''
            },
            errors: {
                mail:'',
                name:'',
                ph:'',
                ps:'',
                cp:'',
                // main:''
            },
            flags: {
                a:'',
                b:'',
                c:'',
                e:'',
                d:''

            },
            type: 'password',
            type1: 'password',
            btnText: 'fa-eye-slash',
            btnText1: 'fa-eye-slash',
            loader:false,
            onld: 'hideOnlod'
            

        }
    },
    computed:{
    ...mapGetters({
        added:'userRegister/register',
        fieldError:'userRegister/fieldError',
        
        
        
        }),
        
        
    },
    // created(){
    //     if(this.loader){
    //         this.loader=false
    //     }
        
    // },
    
    methods: {
        registerUser(){
            
            if( this.flags.b == 1 && this.flags.c == 1 && this.flags.d == 1) {
                //alert('t');
                this.loader = true;
                this.$store.dispatch("userRegister/RegisterUser",this.register);
            }
            else {
                // this.errors.main="Please fill all fields"
                if(this.register.name==''){
                    this.errors.name="Name is mandatory"
                }
                // if(this.register.mobilenumber==''){
                //     this.errors.name="Name is mandatory"
                // }
                if(this.register.email==''){
                    this.errors.mail="Email is mandatory"
                }
                if(this.register.mobilenumber==''){
                    this.errors.ph="Mobile number is mandatory"
                }
                if(this.register.password==''){
                    this.errors.ps="Password is mandatory"
                }
                if(this.register.confmpswd==''){
                    this.errors.cp="Confirm Password is mandatory"
                }
            }
        },
        validateEmail() {
            if(this.register.email == ''){
                this.errors.mail="Please enter a valid email"
            this.flags.a='';
            }
            // eslint-disable-next-line
            else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/i.test(this.register.email) && this.register.email != '') {
            
            this.errors.mail="Please enter a valid email"
            this.flags.a='';
            }
            else {
                this.errors.mail=""
                this.flags.a=1;

            }     
        },
        validateName() {
            if(this.register.name == ''){
                this.errors.name="Name is required"
                this.flags.b='';
            }
            else {
                this.errors.name=""
                this.flags.b=1;
            }  
        },
        validatePh() {

            if(this.register.mobilenumber == ''){
                this.errors.ph="Please enter valid mobile number"
                this.flags.c=''
                this.onld= 'hideOnlod'
            }

            else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/i.test(this.register.mobilenumber) && this.register.mobilenumber !='') {
                this.errors.ph="Please enter valid mobile number"
                this.flags.c=''
                this.onld= ''
            }
            else {
                this.errors.ph=""
                this.flags.c=1
                this.onld= ''
            }  
        },
        validatepass() {
            // eslint-disable-next-line
            // if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i.test(this.register.password) && this.register.password !='') {
            
            
            // }
            // else {
            //     this.errors.ps=""

            // }   
            const passwordPattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(.{8,})$/;
            this.isValid = passwordPattern.test(this.register.password);
            if(this.isValid) {
                this.errors.ps=''
            }else {
                this.errors.ps="Password must contain at least one number, one uppercase letter, one lowercase letter, one special character, and minimum 8 characters"
             }  
        },
        validatecp(){
            if(this.register.password === this.register.confmpswd){
                this.errors.cp=""
                this.flags.d=1
            }
            else{
                this.errors.cp="Password does not match"
                this.flags.d=''
            }
        },
        filterNonNumeric() {
					// Replace non-numeric characters with an empty string
					this.register.mobilenumber = this.register.mobilenumber.replace(/[^0-9]/g, "");
                    
				},
                showPassword() {
                        if(this.type === 'password') {
                            this.type = 'text'
                            this.btnText = 'fa-eye'
                        } else {
                            this.type = 'password'
                            this.btnText = 'fa-eye-slash'
                        }
                },
                showPassword1() {
                        if(this.type1 === 'password') {
                            this.type1 = 'text'
                            this.btnText1 = 'fa-eye'
                        } else {
                            this.type1 = 'password'
                            this.btnText1 = 'fa-eye-slash'
                        }
                },
                reseterror(e){
                    // this.errors.main=''
                       this.errors[e] = "";
                    if(this.$store.getters['userRegister/fieldError']) {
                        //alert("test1")
                        this.$store.commit('userRegister/RegisterErrorRemove');
                    }
                    this.loader=false
                    if(e=='ph'){
                        this.onld=''
                    }
                }
        
        
    
    }
}
</script>