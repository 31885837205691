import ApiService from '../api.service'
class UserService {
    constructor() {
        this.request = ApiService;
    }


    //     LoginUser=(x,y) => {
    //     return new Promise((resolve, reject) => {
    //         return this.request.post('/customer/login?customer_mobile='+ x +'&password='+ y).then((response) => {
    //             resolve(response)
    //         }).catch((err) => {
    //             reject(err)
    //         })
    //     });
    // };
    LoginUser=(x,y) => {
        return new Promise((resolve, reject) => {
            return this.request.post('/login?customer_mobile=' + x + '&password=' + y  ).then((response) => {
                resolve(response)
                
            }).catch((err) => {
                reject(err)
            })
        });
    };
    RegisterUser=(reg) => {
        return new Promise((resolve, reject) => {
            
            return this.request.post('/register?customer_name=' + reg.name +'&customer_mobile='+ reg.mobilenumber + '&customer_email='+ reg.email +'&password='+ reg.password +'&password_confirmation='+ reg.confmpswd).then((response) => {
                
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    VerifyOTP=(otp) => {
        return new Promise((resolve, reject) => {
            return this.request.post('/verify-otp?customer_id='+ otp.id +'&otp=' + otp.code).then((response) => {
               resolve(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    ResendOTP=(resend) => {
        return new Promise((resolve, reject) => {
            return this.request.post('/resend-otp?customer_id='+ resend).then((response) => {
               resolve(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    getUser = (id) => {
		return new Promise((resolve, reject) => {
			return this.request.get('/customer/{id}/view?id='+ id).then((response) => {
				resolve(response)
			}).catch((err) => {
				reject(err)
			})
		});
		// return this.request.get('/users')
	};

    
}

export default new UserService();