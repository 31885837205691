import VueRouter from 'vue-router';

//Compponents
import HomePage from "@/pages/HomePage"
import AuthLayout from "@/layout/AuthLayout"
import SignIn from "@/pages/SignIn"
import SignUp from "@/pages/SignUp"
// import ForgotPassword from "@/pages/ForgotPassword"
import ForgotPassword from "@/components/Authentication/ForgotPassword"
import ForgotpasswordOtpverify from "@/components/Authentication/ForgotpasswordOtpverify"
// import OtpVerification from "@/pages/OtpVerification"
import OtpVerification from "@/components/Authentication/OtpVerification"
import ListLayout from "@/layout/ListinLayout"
import ServiceProviders from "@/pages/ServiceProviders"
import ProviderDetail from "@/pages/ProviderDetail"
import MyAccount from "@/layout/MyAccount"
import AccountDetails from "@/components/MyAccount/AccountDetails"
import MyBookings from "@/components/MyAccount/MyBookings"
import BookingDetail from "@/components/MyAccount/BookingDetail"
import AboutUs from "@/pages/AboutUs"
import ContactUs from "@/pages/ContactUs"
import CareerList from "@/pages/CareerList"
import BookingInfo from "@/components/booking/BookingInfo"
import BookingConfirmation from "@/components/booking/BookingConfirmation"
import PrivacyPolicy from "@/pages/PrivacyPolicy"
import ChangePassword from "@/components/MyAccount/ChangePassword"
import BookingHistory from "@/components/MyAccount/BookingHistory"
import FavList from "@/components/MyAccount/FavList"
import KycDoc from "@/components/MyAccount/KycDoc"
import MyFamily from "@/components/MyAccount/MyFamily"
import AddnewFam from "@/components/MyAccount/AddnewFam"
import EditFam from "@/components/MyAccount/EditFamily"
import NewsnEvents from "@/pages/NewsnEvents"
import NewsDetail from "@/pages/NewsDetail"
import BookingSummary from "@/components/booking/BookingSummary"
// import ForgotReset from "@/pages/ForgotReset"
import ForgotReset from "@/components/Authentication/ForgotReset"
import SearchResult from "@/components/Services/SearchResult"
import PrivcyPolicy from "@/pages/PrivcyPolicy"
import Error from "@/pages/ErroNull"
import otpFamily  from "@/components/MyAccount/FamilyOtp"
import MyNotifications from "@/components/MyAccount/MyNotifications"

const router = new VueRouter({
        mode: 'history',
        
        routes: [
            {
                path: '/',
                name: 'Home',
                
                component: HomePage

            },
            {
                path: '/user',
                component: AuthLayout,
                beforeEnter: (to, from, next) => {
                    if(sessionStorage.getItem('token')){
                        next('/myaccount')
                    }
                    else {
                     next();
                    }
                  },
                children: [
                    {
                        path: 'signin',
                        name: 'signin',
                        component: SignIn
                    },
                    {
                        path: 'signup',
                        name: 'signup',
                        component: SignUp,
                        
                    },
                    {
                        path: '/forgotpassword',
                        name: 'forgot',
                        component: ForgotPassword,
                        beforeEnter: (to, from, next) => {
                            // Check if the referrer matches the expected route name
                            if (from.name === 'signin') {
                              // Allow access to the route
                              next();
                            } else {
                              // Redirect to another route or display an error message
                               next('/user/signin')
                            }
                          },
                    },
                    
                    {
                        path: '/forgototp',
                        name: 'forgotOTP',                        
                        component: ForgotpasswordOtpverify,
                        beforeEnter: (to, from, next) => {
                            // Check if the referrer matches the expected route name
                            if (from.name === 'forgot') {
                              // Allow access to the route
                              next();
                            } else {
                              // Redirect to another route or display an error message
                               next('/user/signin')
                            }
                          },
                        
                    },
                    {
                        path: 'signup/resetpassword',
                        name: 'resetforgot',
                        component: ForgotReset,
                        beforeEnter: (to, from, next) => {
                            // Check if the referrer matches the expected route name
                            if (from.name === 'forgotOTP') {
                              // Allow access to the route
                              next();
                            } else {
                              // Redirect to another route or display an error message
                               next('/user/signin')
                            }
                          },
                    },
                    {
                        path: 'signup/verify',
                        name: 'otp',
                        component: OtpVerification,
                        beforeEnter: (to, from, next) => {
                            // Check if the referrer matches the expected route name
                            if (from.name === 'signup' || from.name === 'signin') {
                              // Allow access to the route
                              next();
                            } else {
                              // Redirect to another route or display an error message
                               next('/user/signin')
                            }
                          },
                    }
                ]
                
            },
            {
                path: '/services',
                component: ListLayout,
                children: [
                    {
                        path: '/',
                        name: 'services',
                        component: ServiceProviders
                    },
                    
                ]
                
            },
            {
                path: '/search',
                name: 'Search',
                component: SearchResult,
                props: route => ({
                  category: route.query.category || '',
                  subCategory: route.query.sub_category || '',
                  specialty: route.query.specialty || '',
                  serviceProviderName: route.query.service_provider_name || '',
                  city: route.query.city || '',
                  location: route.query.location || '',
                  appointmentDate: route.query.appointment_date || '',
                }),
              },
            {
                path: '/services/:providername',
                name: 'single_provider',
                component: ProviderDetail,
                props: route => ({
                    providername: route.params.providername,
                    Id: route.query.prId
                  }),
                  beforeEnter: (to, from, next) => {
                    var item = localStorage.getItem('token');
                    if(!item){
                        next('/user/signin')
                    }
                    else {
                     next();
                    }
                  },
                  
            },
            {   
                path: '/myaccount',
                    component: MyAccount,
                    beforeEnter: (to, from, next) => {
                        var item = localStorage.getItem('token');
                        if(!item){
                            next('/user/signin')
                        }
                        else {
                         next();
                        }
                      },
                    children: [
                        {
                            path: '/',
                            name: 'myaccount',
                            component: AccountDetails
                        },
                        {
                            path: '/bookings',
                            name: 'mybookings',
                            component: MyBookings
                        },
                        {
                            path: '/bookings/:bookingid',
                            name: 'BookingDetail',
                            props: true,
                            component: BookingDetail
                        },
                        {
                            path: '/changepassword',
                            name: 'ChangePassword',
                            component: ChangePassword
                        },
                        {
                            path: '/history',
                            name: 'BookingHistory',
                            component: BookingHistory
                        },
                        {
                            path: '/favorites',
                            name: 'favorites',
                            component: FavList            
                            
                        },
                        {
                            path: '/kyc',
                            name: 'kyc',
                            component: KycDoc            
                            
                        },
                        {
                            path: '/family',
                            name: 'family',
                            component: MyFamily            
                            
                        },
                        {
                            path: '/newfamily',
                            name: 'newfamily',
                            component: AddnewFam            
                            
                        },
                        {
                            path: '/editfamily/:famID',
                            name: 'editFam',
                            props: true,
                            component: EditFam            
                            
                        },
                        {
                            path: '/verify-family',
                            name: 'familyOtp',
                            component: otpFamily
                        },
                        {
                            path: '/notification',
                            name: 'Notification',
                            component: MyNotifications
                        },
                        
                        
                    ]
                
            },
            {
                path: '/about',
                name: 'about',
                component: AboutUs
             
                
            },
            {
                path: '/contact',
                name: 'contact',
                component: ContactUs
             
                
            },
            {
                path: '/careers',
                name: 'careers',
                component: CareerList
             
                
            },
            {
                path: '/booknow/:PId',
                name: 'booknow',
                props: true,
                component: BookingInfo            
                
            },
            {
                path: '/bookingsummary',
                name: 'booksummary',
                component: BookingSummary            
                
            },
            {
                path: '/bookingsuccess',
                name: 'confirmed',
                props: true,
                component: BookingConfirmation            
                
            },
            {
                path: '/privacypolicy',
                name: 'privacypolicy',
                component: PrivacyPolicy            
                
            },
            {
                path: '/news/:newstitle',
                name: 'singlenews',
                props: route => ({
                    newstitle: route.params.providername,
                    Id: route.query.newsid
                  }),
                component: NewsDetail
            },
            {
                path: '/newsandevents',
                name: 'newslist',
                component: NewsnEvents
            },
            {
                path: '/privcypolicy',
                name: 'privcypolicy',
                component: PrivcyPolicy
            },
            

            {
                path: '*',
                name: 'Error',
                component: Error
            },
            
            
            
        ]
    }
);



export default router