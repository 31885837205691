import ApiService from '../api.service'

class pageService {
    constructor() {
        this.request = ApiService;
    }

    
    getHomePage=() => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/home').then((response) => {
                resolve(response)
            //    console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    testimonialsHome=() => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/testimonials').then((response) => {
                resolve(response)
            //    console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    getAboutPage=() => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/about-us').then((response) => {
                resolve(response)
            //    console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    getNewsPage=(num) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/news-and-events?latest='+num).then((response) => {
                resolve(response)
            //    console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    getNewsDetail=(id) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/news-and-events/'+id+'/view').then((response) => {
                resolve(response)
            //    console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    getContactDetail=() => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/contact-us').then((response) => {
                resolve(response)
            //    console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    enquiryFormSubmit=(formInput) =>  {
        const formData = new FormData();
        formData.append('name', formInput.name);
        formData.append('email', formInput.email);
        formData.append('subject', formInput.subject);
        formData.append('message', formInput.Message);
     
        return new Promise((resolve, reject) => {
            return this.request.post('/contact-us/send-mail', {}, formData, {
                'Content-Type': 'multipart/form-data'
              })
                .then((response) => {
                  resolve(response);
                 // console.log(response);
                })
                .catch((err) => {
                  reject(err);
                });
            });
    };

    getCareerpage=() => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/careers').then((response) => {
                resolve(response)
            //    console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    careerFormSubmit=(formInput) =>  {
        const formData = new FormData();
        formData.append('name', formInput.name);
        formData.append('email', formInput.email);
        formData.append('position', formInput.positionApplied);
        formData.append('experiance', formInput.experience);
        formData.append('file', formInput.file);
        formData.append('message', formInput.message);
     
        return new Promise((resolve, reject) => {
            return this.request. post('/career-form', {}, formData, {
                'Content-Type': 'multipart/form-data'
              })
                .then((response) => {
                  resolve(response);
                 // console.log(response);
                })
                .catch((err) => {
                  reject(err);
                });
            });
    };

    getPrivcypolicypage=() => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/terms-and-condition').then((response) => {
                resolve(response)
            //    console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    subscribeNews=(mail) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.post('/newsletter?email='+mail)
                .then((response) => {
                  resolve(response);
                 // console.log(response);
                })
                .catch((err) => {
                  reject(err);
                });
            });
    };
       
}

export default new pageService();
