<template>
    <div class="notifications">
        <!-- <div class="center-loader" v-if="!List">
            <span class="loader" ></span>
        </div> -->
        <h2 class="my_title_d" style="text-align: left;"> Notification</h2>
        <div class="center-loader" v-if="List.length==0">
            <span class="loader" ></span>
        </div>
        <div class="center-loader" v-else-if="List.data.length==0 " >
                <p>No notifications <br>
                
                </p>
        </div>
        
        <div class="notiList" v-else>
            <div class="notification-block" v-for="(noti,i) in List.data" :key="i" >
                <div><font-awesome-icon :icon="['fas', 'bell']" /></div>
                <div>
                    <h3>{{ noti.title }}</h3>
                    <p>{{ noti.description }}</p>
                </div>
                

            </div>
        </div>
        
        <div class="pagination booking"  v-if="pageValues.length > 3">
                <ul>
                    <li v-for="(pages,i) in pageValues" :key="i"  :class="{ activePage: pages.active, disabled: pages.page==null  } "   @click="getfamilies(pages.page)">
                        <span v-if="pages.label== '&laquo; Previous'">Previous</span> 
                        <span v-else-if="pages.label=='Next &raquo;'">Next</span> 
                        <span v-else>{{ pages.label }}</span> 
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "MyNotifications",
    data() {

    return {

        
        
    }

    },
    computed:{
            ...mapGetters({
            List:"accountModule/notif",
            
            }),
            pageValues() {
            const extractedValues = this.List.links;
            const result = [];
                if(extractedValues){
            for (const link of extractedValues) {
                if (link.url) {
                const url = new URL(link.url);
                const page = url.searchParams.get("page");
                result.push({
                    ...link,
                    page: page
                });
                } else {
                result.push({
                    ...link,
                    page: null
                });
                }
            }
        }
        return result;
        },
    },
    created(){
        this.loadNoti()
    },

    methods: {
        loadNoti(){
            this.$store.dispatch("accountModule/getNotification")
        }


        }
    
   
    
    
}
</script>
