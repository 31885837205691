<template>
    <div class="provider_history">
            <div class="s_img_his">
                <img :src="history.service_provider.service_provider_photo_url">
            </div>
            
            <div class="history_info_b">
                <div class="tp">
                    <h2>{{ history.service_provider.service_provider_name }} </h2>
                    <span>{{ history.service_provider.service_provider_designation }}</span>
                </div>
                <div>
                    <h2 class="st_c">{{ history.status.book_status }}</h2>
                    <span class="st_ts">{{ formattedDate }}, {{ formattedTimeFrom }}-{{ formattedTimeTo }}</span>
                    <router-link
                        :to="{ name: 'single_provider', params: { providername: history.service_provider.service_provider_name }, query: { Id: history.service_provider.id } }"
                        >
                        <button class="view_profile"  >Book Again</button>
                    </router-link>
                </div>
             
            </div>
        </div> 
</template>

<script>
export default {
    name: "HistoryBlock",
    
    props: {
        history:{
            type: Object,
            required: true
        }
    },
    data(){
        return {
            test:''
        }
    },
    computed:{
      
    formattedDate() {
        const date = new Date(this.history.booking_date);
      const day = date.getDate().toString().padStart(2, "0"); // Get the day and pad with leading zeros if needed
      const month = date.toLocaleString("default", { month: "short" }); // Get the abbreviated month name

      return `${day} ${month}`;
    },
    formattedTimeFrom() {
      const timeParts = this.history.booking_time_from.split(":");
      const hours = parseInt(timeParts[0], 10);
      const minutes = timeParts[1];
      let period = "AM";

      if (hours >= 12) {
        period = "PM";
      }

      // Convert to 12-hour format
      const formattedHours = hours % 12 || 12;

      return `${formattedHours}:${minutes}${period}`;
    },
    formattedTimeTo() {
      const timeParts = this.history.booking_time_to.split(":");
      const hours = parseInt(timeParts[0], 10);
      const minutes = timeParts[1];
      let period = "AM";

      if (hours >= 12) {
        period = "PM";
      }

      // Convert to 12-hour format
      const formattedHours = hours % 12 || 12;

      return `${formattedHours}:${minutes}${period}`;
    },
    },

}
</script>