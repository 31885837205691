<template>
    <div class="booking_step"> 
        <h3>STEPS TO GET YOUR SERVICE</h3>
        
        <div class="steps">
            <div class="step">
                <div>
                    <img src="@/assets/images/s1.png">
                    <img src="@/assets/images/ic1.png" class="pos-img">
                </div>
                <p>Find Your  Service</p>
            </div>
            <div class="step">
                <div>
                    <img src="@/assets/images/s1.png">
                    <img src="@/assets/images/ic3.png" class="pos-img">
                </div>
                <p>Choose Your  Location</p>
            </div>
            <div class="step">
                <div>
                    <img src="@/assets/images/s1.png">
                    <img src="@/assets/images/ic2.png" class="pos-img">
                </div>
                <p>Schedule Your  Appointment</p>
            </div>
            <div class="step">
                <div>
                    <img src="@/assets/images/s1.png">
                    <img src="@/assets/images/ic4.png" class="pos-img">
                </div>
                <p>Get Your Appointment</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BookingStep",
    
}
</script>