<template>
    <div class="header-top">
        <div class="wrapper">
            <!-- <div class="lan">
                <select>
                    <option>EN</option>
                </select>
            </div> -->
            <div class="top-box">
                <font-awesome-icon icon="fa-solid fa-phone" />
                <a :href="'tel:' + TopInfo.mobile">{{ TopInfo.mobile }}</a>
            </div>
            <div class="top-box">
                <font-awesome-icon icon="fa-solid fa-envelope" />
                <a :href="'mailto:' + TopInfo.email">{{ TopInfo.email }}</a>
            </div>
            <div class="top-box search-bar hideOnlyMob" >
                <input placeholder="Search by name" v-model="searchq" @input="find()" @keyup.enter="serchbynme">                
                <font-awesome-icon icon="fa-solid fa-magnifying-glass" @click="serchbynme"/>                
            </div>
            <!-- Mobile Only -->
            <div class="top-box search-bar showoNLYmOB" @click="shoWFormS" >              
                <font-awesome-icon icon="fa-solid fa-magnifying-glass" />                
            </div>
            <div class="mobile_top_form" :class="{showForm_b: classaddedd === 'showForm_b'}">
                <div class="close_b" @click="shoWFormS">&#10005;</div>
                <div class="fm_hd">
                    <input placeholder="Search..." v-model="searchq" @input="find()"  @keyup.enter="serchbynme">                
                    <font-awesome-icon icon="fa-solid fa-magnifying-glass" @click="serchbynme"/> 
                </div>
                <div class="sugMob" :class="{showSug: show === 'showSug'}">
                    <ul>
                        <li v-for="(item,i) in filteredList" :key="i">{{ item }}</li>
                    </ul>
                </div>
            </div>
            <!--  -->
            <!-- <div class="sug" :class="{showSug: show === 'showSug'}">
                <ul>
                    <li v-for="(item,i) in filteredList" :key="i">{{ item }}</li>
                </ul>
            </div> -->
        </div>
        
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "HeaderTop",
    data(){
        return {
            searchq:'',
            // search: [
            //     'doctor','advocate','artisan', 'Test', 'Doctor ENT'
            //   ],
              show:'',
              classaddedd:'',
              num:1,
              searchFilters: {
                    city: '',
                    location: '',
                    category: '',
                    subcategory: '',
                    specialty: '',
                    date: '',
                    name: '',
                    sortby:''
                },
        }
    },
    computed:{
        filteredList() {
            if(this.searchq.length >=3){
                return this.search.filter(item => {
                    return item.toLowerCase().includes(this.searchq.toLowerCase());
                });
                }
                return this.search
            },
    ...mapGetters({
        TopInfo:"pagesModule/contact",       
    }),
    
    },
    created(){
        document.body.classList.remove('bg-scroll');
        
        
    },
    methods: {
        find() {
            if(this.searchq.length >=3 && this.filteredList !=''){
                this.show="showSug";
                
            }
            else {
                this.show="";
            }
             
        },
        shoWFormS(){
            

            if(this.num > 1 ){
                document.body.classList.remove('bg-scroll');
                this.classaddedd = ''
                this.num= 1;
            }
            else {
                this.num= this.num+1;
                document.body.classList.add('bg-scroll');
                 this.classaddedd = 'showForm_b'
                
            }
        },
        serchbynme(event){
           // this.$store.commit('searchModule/clearList');
            this.searchFilters.name=event.target.value;
            this.$store.dispatch("providerModule/searchProviders", this.searchFilters)
        }
    }
}
</script>