<template>
    <div class="list_page_layout">
        <AppHeaderVue></AppHeaderVue>

        <div class="listt-sec">
            <div class="wrapper">
                <div class="breadcrumbs">
                    <!-- <div><a>Home</a>/<a>Services</a></div> -->

                    <div class="modify_search desk_top">                        
                        <div class="form-c">
                            <select v-model="selectedCity" @change="loadLocation" :disabled="!cities" @click="getCities">
                                <option disabled  value="option">City</option>
                                <option v-for="(city,j) in cities" :key="j"  :value="city.id">{{ city.city_name }}</option>
                            </select>
                            <select v-model="selectedLoc" @change="assignValLoc" >
                                <option disabled selected  value="option">Location</option>
                                <option v-for="(loc,j) in locations" :key="j"  :value="loc.id">{{ loc.location_name }}</option>
                            </select>
                            <select v-model="selectedcategory" ref="myCat" @change="handleSubcat" :disabled="!categoryList">
                                <option disabled  value="option">Category</option>
                                <option v-for="(item,j) in categoryList" :key="j" :value="item.id" >{{item.category_name}}</option>
                            </select>
                            <select v-model="selectedSubcategory" ref="myCat" @change="handleSpeciality" v-if="subCAtLi.length > 0">
                                <option disabled  value="option">Subcategory</option>
                                <option v-for="(item,j) in subCAtLi" :key="j" :value="item.id" >{{item.category_name}}</option>
                            </select>
                            <select v-model="selectedSpeciality" @change="assignSpeci">
                                <option disabled selected  value="option">Specialty</option>
                                <option v-for="(spec,j) in specialtyList" :key="j"  :value="spec.id">{{ spec.speciality_name }}</option>
                            </select>
                            <input type="text" placeholder="Appointment Date" v-model="AppointmentDate" onfocus="(this.type='date')" @blur="assignDate" :min="currentDate">
                            <!-- <button @click="search">Search</button> -->
                        </div>                       
                        <button @click="search" class="pos-but-loader">
                            Search
                            <span class="loader" v-if="loader"></span>
                        </button>
                    </div>
                    
                </div>
                <div class="box_listing_main">
                    <div class="filter_button icon-only" @click="sort_bt()"  :class="{activeSort: selectedLink === 'activesort'}">
                        <font-awesome-icon :icon="['fas', 'filter']" />
                    </div>
                    <div class="filter_button icon-only" @click="sort_bt1()"  :class="{activeSort: selectedLink === 'activesort'}">
                        <font-awesome-icon :icon="['fas', 'arrow-up-wide-short']" />
                    </div>
                    <div class="left-sidebar  " :class="{changesortBox: selectedLink === 'activesort',  activeSortBox: sorto === 'active', hideSrchFld: selectedLink === 'newCls', }">
                        <h2>Sorting & Filters</h2>

                        <div class="modify_search mobile_srch_s">   
                            <h2 style="text-align: center;">Filter By</h2>                    
                            <div class="form-c">
                                <select v-model="selectedCity" @change="loadLocation" :disabled="!cities" @click="getCities">
                                    <option disabled  value="option">City</option>
                                    <option v-for="(city,j) in cities" :key="j"  :value="city.id">{{ city.city_name }}</option>
                                </select>
                                <select v-model="selectedLoc" @change="assignValLoc" >
                                    <option disabled selected  value="option">Location</option>
                                    <option v-for="(loc,j) in locations" :key="j"  :value="loc.id">{{ loc.location_name }}</option>
                                </select>
                                <select v-model="selectedcategory" ref="myCat" @change="handleSubcat" :disabled="!categoryList">
                                    <option disabled  value="option">Category</option>
                                    <option v-for="(item,j) in categoryList" :key="j" :value="item.id" >{{item.category_name}}</option>
                                </select>
                                <select v-model="selectedSubcategory" ref="myCat" @change="handleSpeciality" v-if="subCAtLi.length > 0">
                                    <option disabled  value="option">Sub Category</option>
                                    <option v-for="(item,j) in subCAtLi" :key="j" :value="item.id" >{{item.category_name}}</option>
                                </select>
                                <select v-model="selectedSpeciality" @change="assignSpeci">
                                    <option disabled selected  value="option">Specialty</option>
                                    <option v-for="(spec,j) in specialtyList" :key="j"  :value="spec.id">{{ spec.speciality_name }}</option>
                                </select>
                                <input type="text" placeholder="Appointment Date" v-model="AppointmentDate" onfocus="(this.type='date')" @blur="assignDate" :min="currentDate">
                                <!-- <button @click="search">Search</button> -->
                            </div>                       
                            <button @click="search">Search</button>
                        </div>
                        <!-- <div class="filter-box">
                            <input type="date">
                        </div> -->
                        <div class="filter-box">
                            <h2 class="mobile_srch_s" style="text-align: left: ;;">Sort By</h2>
                            <ul>
                                <!-- <li>
                                    <label><input type="radio" name="sort"   @change="changeSort">Popular</label>
                                </li> -->
                                <li>                                    
                                    <label><input type="radio" name="sort"  value="0" @change="changeSort">Old - New</label>
                                </li>
                                <li>                                    
                                    <label><input type="radio" name="sort"  value="1" @change="changeSort">New - Old</label>
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="filter-box">
                            <h3>Recent Bookings</h3>
                            
                        </div> -->
                        
                    </div>
                    <div class="list_view">
                        <!-- <h2 v-if="List.data.length==0">No Result Found</h2> -->
                        <h2 v-if="List.data.length==0" class="soryText">Sorry we couldn't find any matching results for your serach at the moment.!</h2>
                        <ServiceProviders :serviceproviderList="List.data" ></ServiceProviders>

                        <div class="pagination" v-if="pageValues.length > 3">
                            <ul>
                                <li v-for="(pages,i) in pageValues" :key="i"  :class="{ activePage: pages.active, disabled: pages.page==null  } "   @click="search(pages.page)">
                                   <span v-if="pages.label== '&laquo; Previous'">Previous</span> 
                                   <span v-else-if="pages.label=='Next &raquo;'">Next</span> 
                                   <span v-else>{{ pages.label }}</span> 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AppFooter></AppFooter>
        
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
// import FilterForm from "@/components/Services/FilterForm.vue";
import ServiceProviders from "@/pages/ServiceProviders.vue"
// import router from '@/routers'

// import { RouterView } from 'vue-router';
export default {
    name: "SearchResult",
        components: {
        AppHeaderVue,
        AppFooter,
        // RouterView  ,
        // FilterForm  ,
       ServiceProviders,
    },
    data() {
        return {
            check:'',
            // serviceproviders:[],
                num:1,
                selectedLink:'',
                submenu:'',
                slectedFilters:[],
                searchFilters: {
                    city: this.$route.query.city,
                    location: this.$route.query.location,
                    category: this.$route.query.category,
                    subcategory: this.$route.query.subCategory,
                    specialty: this.$route.query.specialty,
                    date: this.$route.query.appointmentDate,
                    name: this.$route.query.serviceProviderName,
                    sortby:'',
                    pageid:''
                },
                //cities:[]
                selectedCity: 'option',
                selectedLoc:'option',      
                selectedcategory: 'option',
                selectedSubcategory: 'option',
                selectedSpeciality: 'option',
                specialty:{
                    cat:'',
                    sub:''

                },
                AppointmentDate:'',
                loader:false,
                sorto:''
        }
        
    },
    computed:{
    ...mapGetters({
        List:"providerModule/List",
        Noresult:"providerModule/Emptyresult",
        categories:"searchModule/categories",
        cities:"searchModule/citylist",
        locations:"searchModule/locationlist",
        categoryList:"searchModule/categories",
        specialtyList:"searchModule/specialties",
        subCAtLi:"searchModule/subcatList",
        nosub:"searchModule/nosubCat"
      
    }),
    

    pageValues() {
    const extractedValues = this.List.links;
    const result = [];
    if(extractedValues){
        for (const link of extractedValues) {
            if (link.url) {
            const url = new URL(link.url);
            const page = url.searchParams.get("page");
            result.push({
                ...link,
                page: page
            });
            } else {
            result.push({
                ...link,
                page: null
            });
            }
        }
    }

  return result;
}

    },

    watch: {
        List(newValue) {
            // Update the timeslot prop passed to the child component
            // this.loader=false
            document.body.classList.remove('bg-scroll');
            setTimeout(() => {
                this.loader=false

            }, 3000);
            this.serviceproviders = newValue.Data;

            },
            nosub(newValue) {
                if(newValue.length > 0){
                this.getSpecialities(this.specialty)
                }
            },
            subCAtLi() {
                // if(newValue.length == 0){
                // this.getSpecialities(this.specialty)
                // }
                this.getSpecialities(this.specialty)
            },
            // specialtyList(newValue) {
            //     if(!newValue){
            //     this.specialty.sub=''
            //     this.getSpecialities(this.specialty)
            //     }
            // }
            

        },
    created() {
        // this.getCategories()
        this.getCities(),
        this.getCategories(),
        this.setDefault()
        if(this.searchFilters.city !=''){
            this.getLocations(this.searchFilters.city)
        }
        if(this.searchFilters.category !=''){
            this.getsubCat(this.searchFilters.category)
        }
        if(this.searchFilters.specialty !='' && this.searchFilters.subcategory !=''){
           this.specialty.sub= this.searchFilters.subcategory
           this.specialty.cat=''
            this.getSpecialities(this.specialty)
        }
        else if(this.searchFilters.specialty !='' && this.searchFilters.subcategory ==''){
           this.specialty.sub= ''
           this.specialty.cat=this.searchFilters.category
            this.getSpecialities(this.specialty)
        }
        else {
            this.specialty.cat=this.searchFilters.category
        }
        if(this.$route.query.appointmentDate){
            this.type='date'
            // if (/^\d{4}-\d{2}-\d{2}$/.test(this.$route.query.appointmentDate)) {
            //     const [year, month, day] = this.$route.query.appointmentDate.split("-");
            //     this.searchFilters.date = `${day}-${month}-${year}`;
            // }
        }
        

    },
    mounted(){
        if(this.List.length == 0) {
            this.getproviders(this.searchFilters)
        }
        this.getCities(),
         this.getCategories()
         document.addEventListener('click', this.handleDocumentClick);
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('searchModule/clearLoc');
        this.$store.commit('searchModule/clearsubCat');
        this.$store.commit('searchModule/clearspecialty');
        this.$store.commit('searchModule/clearAll');
        
        // this.getCities(),
        // this.getCategories()
        next(); 
    },
  
    methods: {
        getproviders(filter){
            this.$store.dispatch("providerModule/getproviders",filter)
        },
        sort_bt() {
            
            this.sorto=''
            if(this.selectedLink==''){
                this.selectedLink = 'activesort';
                document.body.classList.add('bg-scroll');
            }
            else {
                this.selectedLink = '';
                document.body.classList.remove('bg-scroll');
            }
            
        },
        sort_bt1() {
            if( this.sorto==''){
                this.selectedLink = 'newCls';
                this.sorto='active'
                document.body.classList.add('bg-scroll');
            }
            else {
                this.selectedLink = '';
                this.sorto=''
                document.body.classList.remove('bg-scroll');
            }
            
        },
        pagination(num){
            if(num){
             this.$store.dispatch("providerModule/getpagination", num)
            }
        },


        // getCategories() {
        //     this.$store.dispatch("searchModule/getCategories")
        // },
        getCities() {
            this.$store.dispatch("searchModule/getCityList")
        },
        
        getLocations(id) {
            this.$store.dispatch("searchModule/getLocationList", id)
        },
        getCategories() {
            this.$store.dispatch("searchModule/getCategories")
        },
        getSpecialities(ids) {
            //alert(ids)
            this.$store.dispatch("searchModule/getSpecialities", ids)
        },
        getsubCat(id){
            
            this.$store.dispatch("searchModule/getsubCats", id)
        },
        // showSubmenu(id) {
        //    // this.submenu = id;
        //     const index = this.slectedFilters.findIndex(item => item.categoryid === id);

        //     if (index === -1) {
        //         this.slectedFilters.push({ categoryid: id});
                
        //     } else {
        //         this.slectedFilters.splice(index, 1);
        //     }

        //     this.$store.dispatch("providerModule/getproviders", this.slectedFilters);
        // },
        // isOpen(id) {
        //     return this.slectedFilters.some(item => item.categoryid === id);
        // },
        // SearchParam(val) {
        //         if(val.City !='option'){
        //             this.searchFilters.city =val.City
        //         }
        //         if(val.Location != 'option'){
        //             this.searchFilters.location =val.Location
        //         }
        //         if(val.Cat != 'option'){
        //             this.searchFilters.category = val.Cat
        //         }
        //         if(val.sub != 'option'){
        //             this.searchFilters.subcategory = val.sub
        //         }
        //         if(val.spe !='option'){                    
        //             this.searchFilters.specialty = val.spe
        //         }
        //         if(val.date != 'option'){
        //             if (/^\d{4}-\d{2}-\d{2}$/.test(val.date)) {
        //                 const [year, month, day] = val.date.split("-");
        //                 this.searchFilters.date = `${day}-${month}-${year}`;
        //             }
        //             else {
        //             this.searchFilters.date = val.date
        //             }
        //         }
                
        // },

    //     search(){
        
    //     this.$store.commit('searchModule/clearAll');
    //     this.$store.dispatch("providerModule/searchProviders", this.searchFilters)

    //         //   router.push({ name: "Search", query: { 
    //         //     category: this.searchFilters.category,
    //         //     subCategory: this.searchFilters.subcategory ,
    //         //     specialty: this.searchFilters.specialty ,
    //         //     serviceProviderName: this.searchFilters.name ,
    //         //     city: this.searchFilters.city ,
    //         //     location: this.searchFilters.location ,
    //         //     appointmentDate: this.searchFilters.date ,
    
    //         //  }} );   

        
    //   }

    loadLocation(event) {
      //  this.selectedCity = event.target.value;
      this.searchFilters.location=''
      this.selectedLoc='option'
       this.searchFilters.city = event.target.value;
        //alert(this.selectedCity)
        this.$store.commit('searchModule/clearLoc');
        this.getLocations(this.selectedCity)

      },
      handleSubcat(event){
        //alert("test");
        this.searchFilters.subcategory=''
        this.searchFilters.specialty=''
        this.selectedSubcategory='option'
        this.selectedSpeciality='option'
        this.searchFilters.category = event.target.value;
        this.specialty.cat= event.target.value;
        // this.$store.commit('searchModule/clearsubCat');
        this.getsubCat(this.selectedcategory)    
        
      },
      handleSpeciality(event){
        this.selectedSpeciality='option'
        this.specialty.sub= event.target.value;
        this.specialty.cat=''
        this.$store.commit('searchModule/clearspecialty');
        this.getSpecialities(this.specialty)
        this.searchFilters.subcategory = event.target.value;

      },
      assignValLoc(event){
        this.searchFilters.location = event.target.value;
      },
      assignSpeci(event){
        this.searchFilters.specialty = event.target.value;
      },
      assignDate(event){
        this.searchFilters.date = event.target.value;
      },
      search(pageid){
        document.body.classList.remove('bg-scroll');
        this.selectedLink = '';
        this.loader=true
        if(pageid){
            this.searchFilters.pageid=pageid
        }
        this.searchFilters.name=''
        if (/^\d{4}-\d{2}-\d{2}$/.test(this.AppointmentDate)) {
          const [year, month, day] = this.searchFilters.date.split("-");
          this.searchFilters.date = `${day}-${month}-${year}`;
        }
        // this.$store.commit('searchModule/clearAll');
        // this.$store.commit('providerModule/clearList');
        this.$store.dispatch("providerModule/searchProviders", this.searchFilters)

        
      },
      changeSort(event){
        //alert(event.target.value)
            this.selectedLink = '';
            this.sorto=''
            document.body.classList.remove('bg-scroll');
           // this.$store.commit('searchModule/clearList');
            this.searchFilters.sortby=event.target.value;
            
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.AppointmentDate)) {
          const [year, month, day] = this.searchFilters.date.split("-");
          this.searchFilters.date = `${day}-${month}-${year}`;
        }
        // this.$store.commit('searchModule/clearAll');
        this.$store.dispatch("providerModule/searchProviders", this.searchFilters)
      },
      setDefault(){
                if(this.searchFilters.city !='') {
                this.selectedCity = this.searchFilters.city ;
                }
                if(this.searchFilters.location !='') {
                this.selectedLoc = this.searchFilters.location ;
                }
                if(this.searchFilters.category !='') {
                this.selectedcategory = this.searchFilters.category ;
                this.specialty.cat= this.searchFilters.category
                }
                if(this.searchFilters.subcategory !='') {
                this.selectedSubcategory = this.searchFilters.subcategory ;
                this.specialty.sub = this.searchFilters.subcategory ;
                }
                if(this.searchFilters.specialty !='') {
                this.selectedSpeciality = this.searchFilters.specialty ;
                } 
                if(this.searchFilters.date !='') {
                this.AppointmentDate = this.searchFilters.date ;
                } 
      },
      handleDocumentClick(event) {
            if (!event.target.closest('.filter_button') ) {
                if (!event.target.closest('.modify_search') ) {
                    document.body.classList.remove('bg-scroll');
                    this.selectedLink = '';
                    this.sorto=''
                }
            }
        },
      
}

    
}
</script>
<style>
.modify_search.desk_top {
    margin-bottom: 30px;
}
</style>