<template>
    <div class="inner_page_layout common_page">
        <AppHeaderVue></AppHeaderVue>

        <div class="layout-pg">
             <!-- <div class="cp_banner">
                <img src="@/assets/images/colorbg.jpg">
                <div class="cover_bg">
                    <h2>About Us</h2>
                </div>
             </div> -->

             <div class="content_pg">
                <div class="wrapper">
                    <div class="abt_flex">
                        <div class="co_abt">
                            <h2>Work With Us</h2>
                            <p>
                                {{ info.description }}
                            </p>
                        </div>
                        <img :src="info.image_url" class="careerimg">
                    </div>

                    <div class="openings">
                        <h2>Current Openings</h2>
                        <SingleCareer v-for="(career,i) in info.openings" :key="i" :career="career" @passId="setId"></SingleCareer>
                        <div class="applyForm" :class="{ 'show_form': isActive }">
                            
                            <form  @submit.prevent="handleSubmit">
                                <button id="close_cf" @click="closeFm" type="button">X</button>
                                <div class="field_career">
                                    <label>Name</label>
                                    <input  @keyup.enter="handleSubmit" v-model="careerForm.name" @focus="clerError('name')">
                                    <span class="error" v-if="errorMain.name">{{ errorMain.name }}</span>
                                </div>
                                <div class="field_career">
                                    <label>Mail</label>
                                    <input  @keyup.enter="handleSubmit" v-model="careerForm.email" @blur="validateEmail" @focus="clerError('email')" >
                                    <span class="error" v-if="error">{{ error }}</span>
                                    <span class="error" v-if="errorMain.email">{{ errorMain.email }}</span>
                                </div>
                                <div class="field_career">
                                    <label>Position Applied For</label>
                                    <select v-model="careerForm.positionApplied" @change="clerError('positionApplied')">
                                        <option  v-for="(career) in info.openings" :key="career.id" :value="career.id">{{ career.title }}</option>
                                    </select>
                                    <span class="error" v-if="errorMain.positionApplied">{{ errorMain.positionApplied }}</span>
                                </div>
                                <div class="field_career">
                                    <label>Years of Experience</label>
                                    <input  @keyup.enter="handleSubmit" v-model="careerForm.experience" @focus="clerError('experience')">
                                    <span class="error" v-if="errorMain.experience">{{ errorMain.experience }}</span>
                                </div>
                                <div class="field_career">
                                    <label>Upload Resume</label>
                                    <input type="file"  @keyup.enter="handleSubmit" @change="resume"  accept=".pdf, application/pdf">
                                    <span class="error" v-if="errorMain.file">{{ errorMain.file }}</span>
                                </div>
                                <div class="field_career">
                                    <label>Message</label>
                                    <textarea  @keyup.enter="handleSubmit" v-model="careerForm.message" @focus="clerError('message')"></textarea>
                                    <span class="error" v-if="errorMain.message">{{ errorMain.message }}</span>
                                </div>
                                <div class="field_career">
                                    <button @submit="handleSubmit">Submit</button>
                                    <span class="loader" v-if="loader && error=='' && message==''"></span>
                                    
                                    <span class="enq-msg">{{ message.Message }}</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                
                
             </div>
        </div>

        <AppFooter></AppFooter>
        
    </div>
</template>

<script>
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
import SingleCareer from '@/components/Common/SingleCareer.vue';
import {mapGetters} from "vuex";
export default {
    name: "CareerList",
    components: {
    AppHeaderVue,
    AppFooter,
    SingleCareer
    },

    data() {
        return {
            careerForm:{
                name:'',
                email:'',
                positionApplied:'',
                experience:'',
                file:'',
                message:''
            },
            error:'',
            errorMain:{
                name:'',
                email:'',
                positionApplied:'',
                experience:'',
                file:'',
                message:''
            },
            flag:'',
            isActive: false,
            loader:false
        }
    },
    computed:{
        ...mapGetters({
            info:"pagesModule/career",
            message:"pagesModule/careermessage",
            
            
        }),   

    },
    watch: {
       
       message(newvalue){
           this.careerForm.name=''
           this.careerForm.email=''
           this.careerForm.positionApplied=''
           this.careerForm.file=''
           this.careerForm.experience='',
           this.careerForm.message=''
           if(newvalue.Status==1){
            this.isActive= false
           }
       }
   },
    created(){
        this.loadInfo()
    },
    methods:{
        loadInfo() {
            this.$store.dispatch("pagesModule/getCareerInfo");
        },
        resume(event) {
        const selectedFile = event.target.files[0];
        
        if (selectedFile && (selectedFile.type === "application/pdf" || selectedFile.name.endsWith(".pdf") || selectedFile.name.endsWith(".PDF"))) {
            this.errorMain.file = ''; // Clear the error message
            this.careerForm.file = selectedFile; // Set the file in your data
        } else {
            this.errorMain.file = "Please select a PDF file.";
            this.careerForm.file = null; // Clear the file value
        }
        },

        validateEmail() {
            // eslint-disable-next-line
            if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/i.test(this.careerForm.email) && this.careerForm.email != '') {
            
            this.error="Please enter a valid email"
            }
            else {
                this.error=""
                this.flag=1;

            }     
        },
        handleSubmit(){
            if (Object.values(this.careerForm).some(field => field !== '') && this.flag==1) {
                this.loader=true
                this.errorMain=''
                this.$store.dispatch("pagesModule/careerForm",this.careerForm);
            }
            else{
                for (const key in this.careerForm) {
               if (!this.careerForm[key]) {
                   this.errorMain[key] = "This field is required.";
               }
            }
            }
        },
        clerError(e){
            this.errorMain[e] =''
        },
        setId(ev){
           //alert(ev.ID)
           
           this.careerForm.positionApplied=ev.ID
            this.isActive = !this.isActive;
            this.$store.commit('pagesModule/clearM');
            document.body.classList.add('bg-scroll');
         
        },
        closeFm(){
            this.isActive = !this.isActive;
            
            this.loader=false
            this.$store.commit('pagesModule/clearM');
            for (const key in this.errorMain) {
                   this.errorMain[key] = "";
            }
            for (const key in this.careerForm) {
                   this.careerForm[key] = "";
            }
            document.body.classList.remove('bg-scroll');
        },
    }
}
</script>
<style>
/* .counter{
    background-image: url(@/assets/images/colorbg.jpg);
} */
</style>