<template>
    <div class="inner_page_layout common_page">
        <AppHeaderVue></AppHeaderVue>

        <div class="d-sec">
            <div class="wrapper">
                <img :src="info.image_url" class="newsIm">
                <div class="news_d single-news">
                    <h3>{{ info.title }}</h3>
                    <div class="auth-d"><span>{{info.date}}</span>
                        <!-- <span>Test User</span> -->
                    </div>
                    <div class="co-news single-det-news">
                        {{info.description}}
                    </div>
                </div>
            </div>
        </div>

        <AppFooter></AppFooter>
        
    </div>
</template>

<script>

import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
import {mapGetters} from "vuex";
export default {
    name: "NewsDetail",
    components: {
        AppHeaderVue,
        AppFooter
    },
    // props: {
    //     newstitle: {
    //         type: String,
    //         required: true
    //     },
    // },
    data(){
        return{
            title:'',
            Id:''
        }
    },
    
    computed:{
    ...mapGetters({
        info:"pagesModule/Detailnews",
        
        
    }),
    

    },
    created(){
        this.title = this.$route.params.newstitle;
        this.Id = this.$route.query.Id;
        this.loadInfo(this.Id)
        
    },
    methods:{
        loadInfo(id) {
            this.$store.dispatch("pagesModule/NewsDetail",id);
        }
    }

    
}
</script>