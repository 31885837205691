<template>
    <div class="SearchForm" > 
        
        <div class="form-c">
            <select v-model="selectedCity" @change="loadLocation" :disabled="!cities" @click="getCities">
                <option disabled  value="option">City</option>
                <option v-for="(city,j) in cities" :key="j"  :value="city.id">{{ city.city_name }}</option>
            </select>
            <select v-model="selectedLoc" @change="assignValLoc" >
                <option disabled selected  value="option">Location</option>
                <option v-for="(loc,j) in locations" :key="j"  :value="loc.id">{{ loc.location_name }}</option>
            </select>
            <select v-model="selectedcategory" ref="myCat" @change="handleSubcat" :disabled="!categoryList">
                <option disabled  value="option">Category</option>
                <option v-for="(item,j) in categoryList" :key="j" :value="item.id" >{{item.category_name}}</option>
            </select>
            <select v-model="selectedSubcategory" ref="myCat" @change="handleSpeciality" v-if="subCAtLi.length > 0">
                <option disabled  value="option">Subcategory</option>
                <option v-for="(item,j) in subCAtLi" :key="j" :value="item.id" >{{item.category_name}}</option>
            </select>
            <select v-model="selectedSpeciality" @change="assignSpeci">
                <option disabled selected  value="option">Specialty</option>
                <option v-for="(spec,j) in specialtyList" :key="j"  :value="spec.id">{{ spec.speciality_name }}</option>
            </select>
            <input type="text" placeholder="Appointment Date" v-model="AppointmentDate" onfocus="(this.type='date')" @blur="assignDate" :min="currentDate">
            <button @click="search" class="pos-but-loader">Search
            <span class="loader" v-if="loader"></span>
            </button>
        </div>
        
        
    </div>
</template>

<script>
// import Search from "@/api-services/services/search.service"
import {mapGetters} from "vuex";
export default {
    name: "SearchForm",
    data(){
    return{
      //cities:[]
      selectedCity: 'option',
      selectedLoc:'option',      
      selectedcategory: 'option',
      selectedSubcategory: 'option',
      selectedSpeciality: 'option',
      specialty:{
        cat:'',
        sub:''

      },
      AppointmentDate:'',
      default: null,
      searchFilters:{
        city:'',
        location:'',
        category:'',
        subcategory:'',
        specialty:'',
        date:'',
        name:'',
        pageid:'',
        sortby:''
      },
      currentDate: new Date().toISOString().split('T')[0],
      loader:false
    }
  },
  mounted() {
    this.getCities(),
    this.getCategories()
    // this.getSpecialities(),
    // this.getSpecialities()
  },
  created() {
    this.getCities(),
    this.getCategories()
    // this.getSpecialities(),
    // this.getSpecialities()
  },
  

  computed:{
    ...mapGetters({
      cities:"searchModule/citylist",
      locations:"searchModule/locationlist",
      categoryList:"searchModule/categories",
      specialtyList:"searchModule/specialties",
      subCAtLi:"searchModule/subcatList",
      nosub:"searchModule/nosubCat"
    }),
    // filteredItems() {
    //   if (this.selectedCity !== null) {
    //     return this.locations.filter(loc => loc.city_id == this.selectedCity);
    //   } else {
    //     return [];
    //   }
    //   },
    //   filteredspecialities() {
    //     const condition = this.selectedv; 
    //     return Object.values(this.specialtyList).filter(item => item.category_id == condition);
    //   }
    

    },

    watch: {
      nosub(newValue) {
        if(newValue.length > 0){
          this.getSpecialities(this.specialty)
        }
      },
      subCAtLi(newValue) {
        if(newValue.length == 0){
          // this.specialty.sub=''
          this.getSpecialities(this.specialty)
        }
        
      },
      specialtyList(newValue) {
        if(!newValue){
          this.specialty.sub=''
          this.getSpecialities(this.specialty)
        }
      },
      
    },
 
    methods: {
      getCities() {
        this.$store.dispatch("searchModule/getCityList")
      },
      
      getLocations(id) {
        this.$store.dispatch("searchModule/getLocationList", id)
      },
      getCategories() {
        this.$store.dispatch("searchModule/getCategories")
      },
      getSpecialities(ids) {
        //alert(ids)
        this.$store.dispatch("searchModule/getSpecialities", ids)
      },
      getsubCat(id){
        this.$store.dispatch("searchModule/getsubCats", id)
      },
      loadLocation(event) {
      //  this.selectedCity = event.target.value;
       this.searchFilters.city = event.target.value;
        //alert(this.selectedCity)
        this.$store.commit('searchModule/clearLoc');
        this.getLocations(this.selectedCity)

      },
      handleSubcat(event){
        //alert("test");
        this.selectedSubcategory='option'
        this.selectedSpeciality='option'
        this.searchFilters.category = event.target.value;
        // alert(event.target.value)
        // this.specialty.cat= event.target.value;
        this.specialty.cat=event.target.value
        this.$store.commit('searchModule/clearsubCat');
        this.getsubCat(this.selectedcategory)    
        
      },
      handleSpeciality(event){
        // alert("t")
        this.selectedSpeciality='option'
        this.specialty.cat=''
        //alert(event.target.value)
        this.specialty.sub= event.target.value;
        this.$store.commit('searchModule/clearspecialty');
        
        this.getSpecialities(this.specialty)
        this.searchFilters.subcategory = event.target.value;

      },
      assignValLoc(event){
        this.searchFilters.location = event.target.value;
      },
      assignSpeci(event){
        this.searchFilters.specialty = event.target.value;
      },
      assignDate(event){
        this.searchFilters.date = event.target.value;
      },
      search(){
        this.loader=true
        if (/^\d{4}-\d{2}-\d{2}$/.test(this.AppointmentDate)) {
          const [year, month, day] = this.searchFilters.date.split("-");
          this.searchFilters.date = `${day}-${month}-${year}`;
        }
        // this.$store.commit('searchModule/clearAll');
        this.$store.dispatch("providerModule/searchProviders", this.searchFilters)

        
      },
     

      
     
    
  }
}
</script>