<template>
    <div class="booking_info_1 inner_page_layout">
        <AppHeaderVue></AppHeaderVue>
        <div class="wrapper">
            <router-link :to="{name:'Home'}" class="bcktoHome">Back to home</router-link>
            <div class="booking_confirm_info">
                <div class="left_info">
                    <h2><font-awesome-icon icon="fa-solid fa-circle-check" /> Booking Confirmed</h2>
                    <span>Congrats {{confirmation.customer_name}}, Your booking has been confirmed !</span>

                    <div class="info_d_c">
                        <h3>{{confirmation.service_provider_name}}</h3>
                        <span>{{confirmation.service_provider_designation}}</span>

                        <p>
                            <span>Booked For <i>:</i> </span>
                            {{confirmation.booked_for}}
                        </p>
                        <p>
                            <span>Mobile Number <i>:</i> </span>
                            {{ confirmation.mobile_number }}
                        </p>
                        <p>
                            <span>Email <i>:</i> </span>
                            {{confirmation.email}}
                        </p>
                    </div>
                </div>
                    <div class="bk_id">
                        <span class="star-pos"><font-awesome-icon icon="fa-solid fa-star" /></span>
                        <p>{{formattedDate}} </p>
                        <p class="psd">{{confirmation.time}}</p>
                        <div>
                            <span>Booking ID</span>
                            <b>{{confirmation.booking_number}}</b>
                        </div>
                    </div>
            </div>
        </div>
        <AppFooter></AppFooter>
    </div>
</template>

<script>
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
// import Datepicker from 'vuejs-datepicker';
import {mapGetters} from "vuex";
import router from '@/routers'

export default {
    name: "BookingConfirmation",
    components: {
    AppHeaderVue,
    AppFooter,
    // Datepicker
    
    },
 
    computed:{
        ...mapGetters({
            confirmation:"bookingModule/ConfmMessage",
        
        }),
        formattedDate() {
      const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

      const dateParts = this.confirmation.date.split(" ");
      const day = parseInt(dateParts[0]);
      const monthIndex = months.findIndex(month => month.startsWith(dateParts[1]));
      const formattedMonth = months[monthIndex];

      return day + " " + formattedMonth;
    },
    },
    created(){
        if(this.confirmation==''){
            router.push({ name: 'Home' });
        }
        sessionStorage.removeItem('slotId');
                    sessionStorage.removeItem('slotDate');
                    sessionStorage.removeItem('pid');
                    sessionStorage.removeItem('famId');
                    sessionStorage.removeItem('edit');
                    sessionStorage.removeItem('BookingID');
        
    }
    
    
    
}
</script>