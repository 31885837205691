import ApiService from '../api.service'
// import axios from 'axios';

class kycService {
    constructor() {
        this.request = ApiService;
    }
    
    loadKYC=(bearerToken) => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/kyc/list',{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
               //console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    
    
    uploadKYC = (bearerToken, kyc) => {
        //console.log(adhar.dc);
        const formData = new FormData()
        formData.append('bank_account_document',kyc.acFile)
        formData.append('aadar_dcoument',kyc.adarFile)
        formData.append('pan_document',kyc.panFile)
        formData.append('passport_document',kyc.passportFile)

        formData.append('account_holder_name',kyc.acHolder)
        formData.append('account_number',kyc.ac)
        formData.append('bank_name',kyc.bname)
        formData.append('branch',kyc.branch)
        formData.append('ifsc_code',kyc.acIFSC)
        formData.append('aadar_number',kyc.adarNo)
        formData.append('pan_number',kyc.panNo)
        formData.append('passport_number',kyc.passportNo)
      
        
        return new Promise((resolve, reject) => {
            return this.request. post('/update/kyc', {}, formData, {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + bearerToken,
              })
                .then((response) => {
                  resolve(response);
                  //console.log(response);
                })
                .catch((err) => {
                  reject(err);
                });
            });
    };
  
       
}

export default new kycService();
