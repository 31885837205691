<template>
    <div class="News_Events"> 
        <div class="wrapper">
            <h2 class="tile-h">News & Events</h2>
            <!-- <p class="sub_p">Lorem Ipsum is simply dummy text of the printing and typesetting</p> -->
            <div class="flex_news">
                <DetailNews v-for="(news,i) in info" :key="i" :news="news"></DetailNews>
            </div>
            <button class="va"><router-link :to="{name:'newslist'}">View All</router-link></button>
        </div>
    </div>
</template>

<script>
import DetailNews from './DetailNews.vue';
import {mapGetters} from "vuex";
export default {
    name: "NewsandEvents",
    components: {
        DetailNews
    },
    data(){
        return {
            
        }
    },
    computed:{
    ...mapGetters({
        info:"pagesModule/news",
        
        
    }),
    

    },
    created(){
        this.loadInfo()
    },
    methods:{
        loadInfo() {
            this.$store.dispatch("pagesModule/getNewsInfo",1);
        }
    }
}
</script>
<style>
.tile-h {
    text-align: center;
    margin-bottom: 40px !important;
}
</style>