import familyService from "@/api-services/services/family.service";
import router from '@/routers'
export const familyModule = {
    // This makes your getters, mutations, and actions accessed by, eg: 'myModule/myModularizedNumber' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,
    state: {
        familyList:[],
        updateFam:'',
        deleteM:'',
        singleFamily:[],
        changeMessage:'',
        errorAdd:[],
        relations:[],
        errorUpdate:[],
        otpSuccess:[],
        otpError:[],
        verifySuccess:[]
    },
    mutations: {
        
        familyList:(state,{data})=> {
            state.familyList= data          
            
        },
        FamilyUpdate:(state,{data})=> {
            state.updateFam= data.Message        
            router.push({ name: "family"});
        },
        errorAdding:(state,{data})=> {
            state.errorAdd= data.Message    
        },
        changeSingle:(state,{data,ur})=> {
            state.changeMessage= data.Message  
            //alert(ur)  
            if(ur=="booknow")    {
                router.push({ name: "booknow"});
            }
            else {
            router.push({ name: "family"});
            }
        },
        singleUpdate:(state,{data})=> {
            state.singleFamily= data        
            
        },
        errorFamupdate:(state,{data})=> {
            state.errorUpdate= data        
            
        },
        relationD:(state,{data})=> {
            state.relations= data       
            
        },
        DeleteUpdate:(state,{data})=> {
            state.deleteM= data.Message        
            // router.push({ name: "family"});
        },
        clearM:(state)=> {
            state.updateFam= ''  
            state.deleteM=''   
            state.changeMessage=''  
            state.errorAdd='' 
            state.verifySuccess=''
             
        },
        otpVerify:(state,{data})=> {
            state.otpSuccess= data    
            router.push({ name: "familyOtp"});
        },
        resendOtpVerify:(state,{data})=> {
            state.otpSuccess= data   
            // alert("test") 
        },
        ErrorOtp:(state,{data})=> {
            state.otpError= data    
        },
        verify:(state,{data})=> {
            state.verifySuccess= data   
            router.push({ name: "family"});  

        },
        Error:(state,{data})=> {
            state.otpError= data  
        },
        clearErrorOtp:(state)=> {
            state.otpError= ''  
        },
        resetOtp:(state)=> {
            state.otpSuccess= ''  
        },
    },
    actions: {
        async getFamily({commit},page) {
            //alert("test")
            let bearerToken = localStorage.getItem('token');
            const response = await familyService.getFamilyList(page,bearerToken);
            
            if(response.data.Status == 1) {
            
                const data = response.data.Data
                console.log(data);
                commit("familyList",{data} ); 
                
            }
            
        },
        async addFamily({commit},familyDetails) {
            //alert(profile)
            let bearerToken = localStorage.getItem('token');
            const response = await familyService.addFam(bearerToken,familyDetails);
            
            if(response.data.Status == 1) {
                
                const data = response.data
                console.log(data);
                commit("FamilyUpdate",{data} ); 
                
            }
            else {
                const data = response.data
                commit("errorAdding",{data} ); 
            }
            
        },
        async deleteFamily({commit},famId) {
            //alert(profile)
            let bearerToken = localStorage.getItem('token');
            const response = await familyService.deletFamily(bearerToken,famId);
            
            if(response.data.Status == 1) {                
                const data = response.data
                // console.log(data);
                commit("DeleteUpdate",{data} );                 
            }
            
            
        },
        async getRelations({commit}) {
            //alert(profile)
            let bearerToken = localStorage.getItem('token');
            const response = await familyService.getRelations(bearerToken);
            
            if(response.data.Status == 1) {
                
                const data = response.data.Data
                // console.log(data);
                commit("relationD",{data} ); 
                
            }
            
            
        },
        
        
        async singleMember({commit},famId) {
            //alert(profile)
            let bearerToken = localStorage.getItem('token');
            const response = await familyService.singleMember(bearerToken,famId);
            
                const data = response.data.Data
                console.log(data)
                commit("singleUpdate",{data} ); 
        },
        async updateFamily({commit},famDet) {
            //alert(profile)
            let bearerToken = localStorage.getItem('token');
            const response = await familyService.UpdateMember(bearerToken,famDet);
            
                const data = response.data
                const ur = famDet.prevUrl
                if(response.data.Status == 1) {
                 commit("changeSingle",{data,ur} ); 
                }
                else {
                    commit("errorFamupdate",{data} ); 
                }
        },
        async vefifyFamily({commit},mob) {
            //alert(profile)
            let bearerToken = localStorage.getItem('token');
            const response = await familyService.sentOTP(bearerToken,mob);
            
                const data = response.data
                // console.log(data)
                if(response.data.Status == 1) {
                 commit("otpVerify",{data} ); 
                }
                else {
                    commit("ErrorOtp",{data} ); 
                }
        },
        async verifyOtp({commit},otp) {
            //alert(profile)
            let bearerToken = localStorage.getItem('token');
            const response = await familyService.verifyOTP(bearerToken,otp);
            
                const data = response.data
                // console.log(data)
                if(response.data.Status == 1) {
                 commit("verify",{data} ); 
                }
                else {
                    commit("Error",{data} ); 
                }
        },
        async ResendOTP({commit},otp) {
            //alert(profile)
            let bearerToken = localStorage.getItem('token');
            const response = await familyService.resendOtp(bearerToken,otp);
            
                const data = response.data
                // console.log(data)
                if(response.data.Status == 1) {
                 commit("resendOtpVerify",{data} ); 
                }
                else {
                    commit("ErrorOtp",{data} ); 
                }
        },

    },
    getters: {
        families: (state) => state.familyList,
        successMessage: (state) => state.updateFam,
        deleteMessage: (state) =>state.deleteM,
        singleItem: (state) =>state.singleFamily,
        singleSuccessM: (state) =>state.changeMessage,
        ErrorMessage: (state) =>state.errorAdd,
        ListRelation: (state) =>state.relations,
        errorUpdt: (state) =>state.errorUpdate,
        getotp: (state) =>state.otpSuccess,
        errorverify: (state) =>state.otpError,
        verified: (state) =>state.verifySuccess,
        // verified: (state) =>state.otpError
    }
};
  
//   export default userModule