<template>
    <div class="DetailPage_"> 
        <AppHeaderVue></AppHeaderVue>
        <div class="detailpage_contents">
            <div class="detail_banner" >
                <div class="banner-co-det">
                    <BannerDetail :name="singleDet.service_provider.service_provider_name" :img="singleDet.service_provider.service_provider_photo_url" 
                    :designation="singleDet.service_provider.service_provider_designation"></BannerDetail>
                </div>
            </div>
            <div class="box_area_d">
                <div class="wrapper">
                    <div class="box_flex">
                        <div class="box_1_d ">
                            <PersonalInfo :detail="singleDet.service_provider"></PersonalInfo>
                            <LocationMap :lat="singleDet.service_provider.service_provider_latitude" :long="singleDet.service_provider.service_provider_longitude"></LocationMap>
                            <!-- <ReviewList  :review="review"></ReviewList > -->
                        </div>
                        <div class="box_2_d ">                            
                            <CalenderBook :fees="singleDet.service_provider.booking_charge" :id="singleDet.service_provider.id" ></CalenderBook>                         
                        </div>
                    </div>
                    <div class="inf-_box_det">
                        <ExperienceEducation :detils="singleDet.service_provider" ></ExperienceEducation>
                    </div>

                    
                </div>
            </div>
        </div>
        <div class="white_d">
            <div class="wrapper">
                <RelatedProfile :related="singleDet.related_profiles" v-if="singleDet.related_profiles"></RelatedProfile>
            </div>
            
            <AppFooter></AppFooter>
        </div>
        
    </div>
</template>

<script>
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
// import ReviewList from '@/components/ProviderDetails/ReviewList.vue';
import PersonalInfo from '@/components/ProviderDetails/PersonalInfo.vue';
import BannerDetail from '@/components/ProviderDetails/BannerDetail.vue';
import LocationMap from '@/components/ProviderDetails/LocationMap.vue';
import RelatedProfile from '@/components/ProviderDetails/RelatedProfile';
import ExperienceEducation from '@/components/ProviderDetails/ExperienceEducation.vue';
import CalenderBook from '@/components/ProviderDetails/CalenderBook.vue';
import {mapGetters} from "vuex";
export default {
    name: "ProviderDetail",
    components: {
        AppHeaderVue,
        AppFooter  ,
        PersonalInfo ,
        // ReviewList,
        BannerDetail,
        LocationMap,
        RelatedProfile,
        ExperienceEducation,
        CalenderBook
        
    },
    data(){
        return{
            // banner: {
            //         Name:'',
            //         Designation: '',
            //         StarRating:'',
            //     },

        
      
        providername: '', 
        Id: ''
    }
},
props: {
    // providername:{
    //         type: String,
    //         required: true
    //     },
    // prId:{
    //      type: Number,
    //     required: true
    // }
    
},

computed:{
        ...mapGetters({
        singleDet:"providerModule/detail",
        
        }),
        
        
},

beforeRouteLeave(to, from, next) {
        this.$store.commit('providerModule/ClearDetail');
        
        // this.getCities(),
        // this.getCategories()
        next(); 
    },
created(){
    this.providername = this.$route.params.providername;
    this.Id = this.$route.query.Id;
    this.loadInfo(this.Id);

    this.$store.commit('bookingModule/clearError');

    
},
methods: {
    loadInfo(id){
        this.$store.dispatch("providerModule/loadDetails", id)
    }
}
    
}
</script>

<style>
.detail_banner{
    background-image: url(../assets/images/colorbg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
</style>