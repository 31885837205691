import pageService from "@/api-services/services/mypage.service";
export const pagesModule = {
    // This makes your getters, mutations, and actions accessed by, eg: 'myModule/myModularizedNumber' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,
    state: {
        aboutUs:[],
        newsEvents:[],
        newsSingle:[],
        contacts:[],
        Message:[],
        careers:[],
        resumeMessage:[],
        Home:[],
        testimonials:[],
        privcypge:[],
        subscribeMessge:[]
    },
    mutations: {
        HomeInfo:(state,{data})=> {
            state.Home= data          
            
        },
        AboutInfo:(state,{data})=> {
            state.aboutUs= data          
            
        },
        NewsInfo:(state,{data})=> {
            state.newsEvents= data          
            
        },
        NewsDetail:(state,{data})=> {
            state.newsSingle= data          
            
        },
        ContactInfo:(state,{data})=> {
            state.contacts= data          
            
        },
        enquirySubmit:(state,{data})=> {
            state.Message= data          
            
        },
        CareertInfo:(state,{data})=> {
            state.careers= data          
            
        },
        resumeSubmit:(state,{data})=> {
            state.resumeMessage= data          
            
        },
        testimonialsList:(state,{data})=> {
            state.testimonials= data          
            
        },
        PrivcyInfo:(state,{data})=> {
            state.privcypge= data          
            
        },
        newssubscribe:(state,{data})=> {
            state.subscribeMessge= data          
            
        },
        clearM:(state) =>{
            state.resumeMessage= ''
        }
        
    },
    actions: {
        async getHomeInfo({commit}) {
            const response = await pageService.getHomePage();
                const data = response.data.Data
                //console.log(data);
                commit("HomeInfo",{data} ); 
                            
        },
        async getTestinonials({commit}) {
            const response = await pageService.testimonialsHome();
                const data = response.data.Data
                //console.log(data);
                commit("testimonialsList",{data} ); 
                            
        },
        async getAboutInfo({commit}) {
            const response = await pageService.getAboutPage();
                const data = response.data.Data
                //console.log(data);
                commit("AboutInfo",{data} ); 
                            
        },
        async getNewsInfo({commit},num) {
            const response = await pageService.getNewsPage(num);
                const data = response.data.Data
                //console.log(data);
                commit("NewsInfo",{data} ); 
                            
        },
        async NewsDetail({commit},id) {
            const response = await pageService.getNewsDetail(id);
                const data = response.data.Data
                //console.log(data);
                commit("NewsDetail",{data} ); 
                            
        },
        async getContactInfo({commit},id) {
            const response = await pageService.getContactDetail(id);
                const data = response.data.Data
                //console.log(data);
                commit("ContactInfo",{data} ); 
                            
        },
        async enquiryForm({commit},formInputs) {
            const response = await pageService.enquiryFormSubmit(formInputs);
                const data = response.data
                //console.log(data);
                commit("enquirySubmit",{data} ); 
                            
        },
        async getCareerInfo({commit}) {
            const response = await pageService.getCareerpage();
                const data = response.data.Data
                //console.log(data);
                commit("CareertInfo",{data} ); 
                            
        },
        async careerForm({commit},formInputs) {
            const response = await pageService.careerFormSubmit(formInputs);
                const data = response.data
                //console.log(data);
                commit("resumeSubmit",{data} ); 
                            
        },
        async getPolicyInfo({commit}) {
            const response = await pageService.getPrivcypolicypage();
                const data = response.data.Data
                //console.log(data);
                commit("PrivcyInfo",{data} ); 
                            
        },
        async NewsLetter({commit},mail) {
            const response = await pageService.subscribeNews(mail);
                const data = response.data
                //console.log(data);
                commit("newssubscribe",{data} ); 
                            
        },
        

    },
    getters: {
        about: (state) => state.aboutUs,
        news: (state) => state.newsEvents,
        Detailnews: (state) => state.newsSingle,
        contact: (state) => state.contacts,
        SuccessEnquiry: (state) => state.Message,
        career: (state) => state.careers,
        careermessage: (state) => state.resumeMessage,
        homeData: (state) => state.Home,
        testimonial: (state) => state.testimonials,
        policy: (state) => state.privcypge,
        newsletter: (state) => state.subscribeMessge
        

    }
};
  
//   export default userModule