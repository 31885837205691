<template>


            <div class="fam-l">
                <h2>Book An Appointment </h2>

                <div class="show_date_i">
                    <div>
                        <span>{{ formattedDate }}</span>
                        <span>{{formattedDay}}, {{ formattedListSlot.time_start }}-{{ formattedListSlot.time_end }}</span>
                    </div>
                    <p>
                        <font-awesome-icon icon="fa-solid fa-pen" @click="popup" />
                    </p>
                </div>
                <div class="popup_date insideAD" :class="{showpopup: showpopup== true }"  >
                        <CalenderPopup ref="calendarPopup"  :id="prID" :show="showpopup" @clear="close" :isEdit="editF" @refreshData="refreshInfo"></CalenderPopup>
                </div>
                <div class="fam_book">
                    <div class="checkb">
                        <input type="radio" name="r" v-model="selected" :value="idM" />
                        <div class="family_Block">
                            <div class="fm_t">
                                <div class="name_fm">
                                    <h2>{{ details.customer_name }}</h2>
                                    <!-- <div class="rel">
                                        <span>{{ family.relation }}</span>
                                        <span>{{ age }}</span>
                                    </div> -->
                                </div>
                                <!-- <div class="fm_actions">
                                    <button>
                                        <router-link :to="{name:'editFam',params:{famID:family.id }}"  >
                                            <font-awesome-icon icon="fa-solid fa-pen" /> Edit
                                        </router-link>
                                    </button>
                                    <button @click="deleteFam(family.id)"><font-awesome-icon :icon="['fas', 'trash-can']" /> Delete</button>
                                </div> -->
                            </div>
                            <div class="fm_det_c">
                                <div class="bf">
                                    <div>
                                        <span>DOB:</span>
                                        <span>{{ details.dob }}</span>
                                    </div>
                                    <div>
                                        <span>Gender:</span>
                                        <span v-if="details.customer_gender.id==2">Female</span>
                                        <span v-if="details.customer_gender.id==1">Male</span>
                                        <span v-if="details.customer_gender.id==3">Others</span>
                                    </div>
                                </div>
                                <div class="bf">
                                    <div>
                                        <span>Email:</span>
                                        <span>{{ details.customer_email }}</span>
                                    </div>
                                    <div>
                                        <span>Phone:</span>
                                        <span>{{ details.customer_mobile }}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="checkb" v-for="(family,index) in List.data" :key="index">
                        <input type="radio" name="r" v-model="selected" :value="family.id" @change="selectedVal(family.id)" />
                        <FamilyBlock  :family="family">                          
                        </FamilyBlock>
                        
                    </div>
                </div>
                <div class="pagination booking"   v-if="List.links.length > 3">
                            <ul>
                                <li v-for="(pages,i) in pageValues" :key="i"  :class="{ activePage: pages.active, disabled: pages.page==null  } "   @click="loadMyFam(pages.page)">
                                    <span v-if="pages.label== '&laquo; Previous'">Previous</span> 
                                    <span v-else-if="pages.label=='Next &raquo;'">Next</span> 
                                    <span v-else>{{ pages.label }}</span> 
                                </li>
                            </ul>
                        </div>
            </div>
                <!-- <div class="ap_form"> -->
                    <!-- <div class="ap_field">
                        <label>Date</label>
                        <input type="date"   >
                        <datepicker    ></datepicker>
                    </div>                    -->

                    <!-- <div class="ap_field">
                        <label>Name</label>
                        <input v-model="name"   @blur="validate()" @focus="clear"> -->
                        <!-- <datepicker    ></datepicker> -->
                    <!-- </div> -->

                    <!-- <div class="ap_field">
                        <label>Mobile Number</label>
                        <input v-model="ph"   @blur="validate()" @input="filterNonNumeric()" @focus="clear"> -->
                        <!-- <datepicker    ></datepicker> -->
                        <!-- <span class="error" v-if="errors.ph">{{ this.errors.ph }}</span>
                    </div>

                    <div class="ap_field">
                        <label>Email</label>
                        <input v-model="mail"   @blur="validateMail()" @focus="clear"> -->
                        <!-- <span class="error" v-if="errors.mail">{{ this.errors.mail }}</span> -->
                        <!-- <datepicker    ></datepicker> -->
                    <!-- </div>

                    <div class="full_button">
                        <button @click="submit">Book Now</button>
                        <span class="error" v-if="errorEmpty">{{ this.errorEmpty }}</span>
                    </div>
                </div>
            </div> -->

</template>

<script>
import CalenderPopup from './CalenderPopup.vue';
import FamilyBlock from '../MyAccount/FamilyBlock.vue';
import {mapGetters} from "vuex";
export default {
    name: "BookingAddress",
   components: {
    FamilyBlock,
    CalenderPopup
   }, 
   
    props: {
        // PId: {
        //     type: String,
        //     required: true
        // },
        timeslot:Object,
        
        
    },
    data(){
        return {
            Date: sessionStorage.getItem('slotDate'),
            prID: sessionStorage.getItem('pid'),
            day:'',
            // TimeS: sessionStorage.getItem('slotId'),
            editF:true,
            passBookingDetails:{
                timeslotID:'',
                date:'',
                familySelected:''
            },
            selected: localStorage.getItem('userid') ,
            idM: localStorage.getItem('userid')  ,
            showpopup:false
        }
    },
    computed:{
        ...mapGetters({
        details:"accountModule/profile",
        List:"familyModule/families",
        }),
        pageValues() {
            const extractedValues = this.List.links;
            const result = [];
                if(extractedValues){
            for (const link of extractedValues) {
                if (link.url) {
                const url = new URL(link.url);
                const page = url.searchParams.get("page");
                result.push({
                    ...link,
                    page: page
                });
                } else {
                result.push({
                    ...link,
                    page: null
                });
                }
            }
        }

        return result;
        },
        formattedDay() {

            let dateObject; 

            if (this.day === '') {
            dateObject = new Date(this.timeslot.day);
            } else {
            dateObject = new Date(this.day);
            }
            const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            return dayNames[Math.max(0, Math.min(dateObject - 1, 6))];
                },
                formattedListSlot() {
                    const formattedTime = { ...this.timeslot };

                    formattedTime.time_start = this.formatTime12Hr(this.timeslot.time_start);
                    formattedTime.time_end = this.formatTime12Hr(this.timeslot.time_end);

                    return formattedTime;
                 },
                 formattedDate() {
                   // const options = { year: 'numeric', month: 'short', day: '2-digit' };
                    const [day, month, year] = this.Date.split('-');
                    const dateObject = new Date(`${year}-${month}-${day}`);
                    const monthAbbreviation = dateObject.toLocaleDateString('en-US', {
                        month: 'short',
                    });
                    return `${day} ${monthAbbreviation.toUpperCase()} ${year}`;
                    },
    },

    // watch: {
    //     formattedListSlot: {
    //     deep: true,
    //     handler() {
    //         this.changeVal();
    //     },
    //     },
        
    // },
    created(){
        this.loadMyinfo()
        this.loadMyFam()
        const famId = sessionStorage.getItem('famId');
        if (famId != null && famId != 'undefined') {
            this.selected = famId;
        }
    },
    methods: {
        loadMyinfo(){
            this.$store.dispatch("accountModule/getProfile")
            
        },
        loadMyFam(pageid){
            this.$store.dispatch("familyModule/getFamily",pageid)
            
        },
        selectedVal(id){
            sessionStorage.setItem('famId', id);
            //alert(id);
            this.$emit('passID',{
                Val: this.selected,
            });
        },
        formatTime12Hr(timeString) {
            const [hours, minutes] = timeString.split(':');
            const parsedHours = parseInt(hours, 10);
            const period = parsedHours >= 12 ? 'PM' : 'AM';
            const formattedHours = parsedHours % 12 === 0 ? 12 : parsedHours % 12;

            return `${formattedHours}:${minutes} ${period}`;
        },
        popup() {
            
            this.showpopup =true;
            this.$refs.calendarPopup.tabs('more',this.Date);
            this.$store.commit('bookingModule/clearError');
            this.$emit('noLoader',false);
            
        },
        close() {
            
            this.showpopup =false
        },
        refreshInfo(ev){
            this.showpopup =false
            this.Date= ev.date;
            this.day = ev.day;
            // this.bookingData.date= ev.date;
            // this.bookingData.timeslotID = ev.time;

            this.$emit('refreshData',ev);
        }

        
    }
    
    
}
</script>