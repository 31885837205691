<template>
    <div class="inner_page_layout">
        <AppHeaderVue :SaveDeta="save"></AppHeaderVue>

        <div class="layout-sec">
            <div class="wrapper">
                <div class="box_listing_main rm" :class="{activeSortm: selectedLink === 'activesort'}">
                    <div class="filter_button rm" @click="ac_bt()"  :class="{activeSort: selectedLink === 'activesort'}">
                        Profile
                    </div>
                    <div class="accout_sidebar left-sidebar" :class="{activeSortBox: selectedLink === 'activesort'}">
                        <ul>
                            <li >
                                <router-link :to="{name:'myaccount'}">
                                    <font-awesome-icon icon="fa-solid fa-house-user" />
                                    My Account
                                </router-link>
                            </li>
                            <li>
                                <router-link  :to="{name:'family'}">
                                    <font-awesome-icon :icon="['fas', 'people-roof']" />
                                    My Family
                                </router-link>
                                
                            </li>
                            <li>
                                <router-link  :to="{name:'mybookings'}">
                                    <font-awesome-icon icon="fa-solid fa-rectangle-list" />
                                     Bookings
                                </router-link>
                                
                            </li>
                            <li>
                                <router-link  :to="{name:'BookingHistory'}">
                                    <font-awesome-icon icon="fa-solid fa-clock-rotate-left" />
                                    History
                                </router-link>
                                
                            </li>
                            <li>
                                <router-link  :to="{name:'kyc'}">
                                    <font-awesome-icon :icon="['fas', 'file-arrow-up']" />
                                    Upload KYC
                                </router-link>
                                
                            </li>
                            <li>
                                <router-link  :to="{name:'favorites'}">
                                    <font-awesome-icon icon="fa-solid fa-heart" />
                                    Favourites
                                </router-link>
                                
                            </li>
                            <li>
                                <router-link  :to="{name:'Notification'}">
                                    <font-awesome-icon :icon="['fas', 'bell']" />
                                    Notifications
                                </router-link>
                                
                            </li>
                            <li>
                                <router-link  :to="{name:'ChangePassword'}">
                                    <font-awesome-icon icon="fa-solid fa-pen" />
                                    Change Password
                                </router-link>
                                
                            </li>
                            <li @click="showAlertfn()">
                                <a>
                                    <font-awesome-icon icon="fa-solid fa-power-off" />
                                    Logout
                                </a>
                                
                            </li>
                        </ul>
                    </div>
                    <div class="updateSuccess  popup-login " v-if="showAlert" >
                        <div>
                            <p>Are you sure you want to logout?</p>
                            <div class="yN" >
                                <button @click="CloseAlert">Cancel</button>
                                <button @click="logout">Confirm</button>
                            </div>
                        </div>
                    </div>
                    <div class="account-info">
                        
                        <router-view></router-view>
                    </div>
                </div>
                
            </div>
        </div>
        <AppFooter></AppFooter>
        
    </div>
</template>

<script>
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';

import { RouterView } from 'vue-router';
export default {
    name: "MyAccount",
    components: {
    AppHeaderVue,
    AppFooter,
    RouterView
    
},
data(){
    return {
        num:1,
        selectedLink:'',
        showAlert:false,
        prevRoute: null,
        save:true,
    }
},
mounted(){
    //alert("test")
    if(this.prevRoute.name=='booknow') {
        this.save=true
        //alert(this.prevRoute.name)
    }
    else {
        this.save=false
    }

},
beforeRouteUpdate(to, from, next) {
    
     this.num=1
     this.selectedLink=''
     
     next()
  },
  beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
updated(){
    window.scrollTo({
                top: 0
            });
},
methods: {
        showAlertfn(){
            this.showAlert=true
        },
        CloseAlert(){
            this.showAlert=false
        },
        ac_bt() {
         
        if(this.num > 1 ){
            this.selectedLink = '';
            this.num= 1;
        }
        else {
            this.selectedLink = 'activesort';
            this.num= this.num+1;
        }
          
    
        
    },
    logout(){
        // window.localStorage.setItem("userid", ""  );
        // window.sessionStorage.setItem("logstatus", "");
        // window.sessionStorage.setItem("logstatus", "");
        
        this.$store.dispatch("accountModule/LogoutUser")
        window.localStorage.removeItem('userid');
        window.localStorage.removeItem('logstatus');
        window.localStorage.removeItem('token');
        this.$router.push({
        name: "signin",
      });
    }
}

    
}
</script>