import ApiService from '../api.service'
import axios from 'axios';

class ServiceproviderService {
    constructor() {
        this.request = ApiService;
    }

    
    
    getproviders = (filters) => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;\
        //console.log(filters)
        return new Promise((resolve, reject) => {
            //alert(bearerToken);
            
    
            this.request.get('/service-provider/list?category='+ filters.category +'&sub_category='+ filters.subcategory +'&specialty='+ filters.specialty +'&service_provider_name='+ filters.name +'&city='+ filters.city +'&location='+ filters.location +'&appointment_date='+ filters.date +'&sort_by='+filters.sortby+'&page='+filters.pageid
            )
                .then((response) => {
                    resolve(response);
                    //console.log(response.Data.data);
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
        });
    };
    getpagination = (num) => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            //alert(bearerToken);
            
    
            this.request.get('/service-provider/list?page='+ num)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    
    loadProvider=(bearerToken,id) => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/service-provider/'+ id +'/view',{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
               console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    
    AddToFavList=(bearerToken,id) => {
        //  axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
          return new Promise((resolve, reject) => {
              return this.request.post('/store/favorite?service_provider_id='+id,{},{},{
                  Authorization: 'Bearer ' + bearerToken,
                }).then((response) => {
                  resolve(response)
              // console.log(response)
              }).catch((err) => {
                  reject(err)
              })
          });
      };
      loadFavList=(bearerToken,id) => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
            return new Promise((resolve, reject) => {
                return this.request.get('/favorites/list?page='+id,{},{
                    Authorization: 'Bearer ' + bearerToken,
                }).then((response) => {
                    resolve(response)
                // console.log(response)
                }).catch((err) => {
                    reject(err)
                })
            });
        };
    
        filteredList(filter) {
            const formData = new FormData();
            for (let i = 0; i < filter.categories.length; i++) {
              const category = filter.categories[i];
             // alert(`categories ${i}: ${category}`);
              formData.append(`categories[${i}]`, category);
            }
            for (let i = 0; i < filter.subcat.length; i++) {
                const sub = filter.subcat[i];
               // alert(`categories ${i}: ${category}`);
                formData.append(`sub_categories[${i}]`, sub);
              }
              for (let i = 0; i < filter.speciality.length; i++) {
                const sp = filter.speciality[i];
               // alert(`categories ${i}: ${category}`);
                formData.append(`specialties[${i}]`, sp);
              }
              for (let i = 0; i < filter.gender.length; i++) {
                const sp = filter.gender[i];
               // alert(`categories ${i}: ${category}`);
                formData.append(`genders[${i}]`, sp);
              }
          
            formData.append('sort_by', filter.sortby);
          
            return new Promise((resolve, reject) => {
              return this.request.post('/service-providers/filter', {}, formData, {
                'Content-Type': 'multipart/form-data',
              })
              .then((response) => {
                resolve(response);
                console.log(response);
              })
              .catch((err) => {
                reject(err);
              });
            });
          }
          
    
    removeFavList=(bearerToken,id) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
            return this.request.delete('/delete/favorite?id='+id)
           
      };
    
}

export default new ServiceproviderService();
