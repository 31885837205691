<template>
    <div class="booking_info_1 inner_page_layout">
        <AppHeaderVue :SaveDeta="save"></AppHeaderVue>
        <div class="wrapper" >
            
            <div class="loader-placeholder center-loader-booking" v-if="singleProvider.length==0">
                <div class="activity"></div>
            </div>
            <BookingTopbar :timeslot="singleTime" :details="singleProvider" ref="BookingTopbar"></BookingTopbar>
            <div class="loader-placeholder center-loader-address" v-if="singleTime.length==0">
                <div class="activity"></div>
            </div>
            <div class="bg-white Appointment_Form" else>
                <BookingAddress @passID="getID" :timeslot="singleTime" @refreshData="refreshTimeslot" @noLoader="hideLoader"></BookingAddress>
                <button class="now_book" @click="submitTobook">Book Now </button>
                <span class="loader" v-if="loader && errorBooking.length==0"></span>
                <span class="red" v-if="errorBooking">{{ errorBooking.Message }}</span>
            </div>
        </div>
        
        <AppFooter></AppFooter>
    </div>
</template>

<script>
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
import BookingTopbar from './BookingTopbar.vue';
import BookingAddress from './BookingAddress.vue';
// import BookingSummary from './BookingSummary.vue';
// import Datepicker from 'vuejs-datepicker';
import {mapGetters} from "vuex";

export default {
    name: "BookingInfo",
    components: {
    AppHeaderVue,
    AppFooter,
    BookingAddress,
    BookingTopbar,
    // BookingSummary
    // Datepicker
    
    },
    data(){
        return {
            // Fam:'',
            save:true,
            passToTimeslot: {
                Date: sessionStorage.getItem('slotDate'),
                TimeS: sessionStorage.getItem('slotId'),
            },
            bookingData: {
                id: localStorage.getItem('userid'),
                date: sessionStorage.getItem('slotDate'),
                serviceProvider: sessionStorage.getItem('pid'),
                timeslotID: sessionStorage.getItem('slotId'),
                familyID: sessionStorage.getItem('famId'),
                // editID:sessionStorage.getItem('BookingID')

                // city: this.singleProvider.service_provider.city_id,
                // location: this.singleProvider.service_provider.location_id,
            },
            timeslot: '',
            previousRouteName: null,
            loader:false
        }
    },
    props: {
        PId: {
            type: String,
        },
        
    },
    // beforeRouteEnter(to, from, next) {
    // next((vm) => {
    //     vm.previousRouteName = from.name;
    // });
    // },
    // created() {
    //     if(this.previousRouteName == 'booksummary' || this.previousRouteName == 'BookingDetail') {
    //     sessionStorage.setItem('edit', 'true');
    // }
    // },
    mounted(){
        this.loadTimeslot(this.passToTimeslot);
        this.loadProvider(this.bookingData.serviceProvider)
        this.ManageEdit()
       
    },
    
    computed:{
        ...mapGetters({
            singleTime:"bookingModule/slotDet",
            singleProvider:"providerModule/detail",
            errorBooking:"bookingModule/error",
        
        }),
        successD(){
            return this.singleProvider
        },
        // successT(){
        //     return this.singleTime
        // }
        
        
        
    },
    watch: {
    singleTime(newValue) {
      // Update the timeslot prop passed to the child component
      this.timeslot = newValue;
    },
    singleProvider(newValue) {
      // Update the details prop passed to the child component
      this.details = newValue;
    //   this.bookingData.city = newValue.service_provider.city_id;
    //   this.bookingData.location= newValue.service_provider.location_id;
      
    },
   
    successD: {
        deep: true,
        handler() {
            this.changeVal();
        },
        },
  },
    methods: {               
        
        getID(ev){
            if(this.bookingData.id != ev.val) {
             this.bookingData.familyID=ev.Val
            //  sessionStorage.setItem('famId', ev.val);
            }
        },
        loadTimeslot(passToTimeslot){
            
            this.$store.dispatch("bookingModule/loadTimeByID", passToTimeslot)
        },
        loadProvider(id){
            this.$store.dispatch("providerModule/loadDetails", id)
        },
        assignVal(){
             this.bookingData.city= this.singleProvider.service_provider.city_id
             this.bookingData.location= this.singleProvider.service_provider.location_id
        },
        refreshTimeslot(ev){
            //alert(ev.date)
            this.passToTimeslot.Date= ev.date;
            this.passToTimeslot.TimeS = ev.time;
            this.bookingData.date= ev.date;
            this.bookingData.timeslotID = ev.time;

            this.loadTimeslot(this.passToTimeslot);
            this.loadProvider(this.bookingData.serviceProvider)

            this.$refs.BookingTopbar.dateChan(ev);
        },
        // changeVal(){            
        //     alert("t")
        //     this.bookingData.city = this.singleProvider.service_provider.city_id;
        //     this.bookingData.location= this.singleProvider.singleProvider.service_provider.location_id;
        // }
        submitTobook(){
            this.loader=true
            if(this.bookingData.familyID==null  || this.bookingData.familyID=='undefined'){
                this.bookingData.familyID=''
            }
            // const x = sessionStorage.getItem('BookingID')
            // //alert(x)
            // if( x == null || x== 'undefined' || x== '') {
            //    this.$store.dispatch("bookingModule/createBooking", this.bookingData)
            //     //alert("t")
            // }
            // else {
               
            //     this.$store.dispatch("bookingModule/UpdateBooking", this.bookingData)
            //     sessionStorage.removeItem('BookingID');
            // }
            this.$store.dispatch("bookingModule/createBooking", this.bookingData)
        },
        ManageEdit(){
            // if(this.previousRouteName != 'booksummary') {
            //     sessionStorage.removeItem('BookingID');
            // }
        },
        hideLoader(ev){
            this.loader=ev
        }
    }
    
    
}
</script>