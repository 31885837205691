<template>
    <div class="familyOtp"> 
        
        <div class="familyOtp-block">
            
            <div>
                <div class="vrfy-otp" >
                    <span  class="otpnot" v-if="resend">{{ otpMessage.Message }}</span>                     
                </div>
                <h2>Enter Your OTP</h2>
                <span v-if="otp" style="font-size: 10px;text-align: center;width: 100%;display: block;">OTP : {{otpMessage.Data.otp }}</span>
                <div class="form">
                    <label>OTP
                        <input v-model="otp.code" @focus="activer">
                    </label>
                    <button @click="verifyOTP">Proceed</button>
                    <span class="loader" v-if="loader && !Error "></span>
                    <span class="error" v-if="Error.length!=0 && Error.Message.otp">{{ Error.Message.otp[0] }}</span>
                    <span class="error" v-if="Error.length!=0 && !Error.Message.otp">{{ Error.Message }}</span>
                    <button   class="resend"  @click="resendOtp()" :class="{noResend: selectedLink === 'noResend'}">Resend OTP</button>
                    <span class="loader" v-if="loader1 && !Error "></span>
                    
                </div>
            </div>
            
        </div>
        
        

    </div>
</template>

<script>

import {mapGetters} from "vuex";
export default {
    name: "FamilyOtp",
    
   
    data(){
        return {
            otp: {
                code:'',
                id:''
            }  ,
            loader:false,
            selectedLink:'',
            loader1:false,
            resend:false

        }
    },

    computed:{
    ...mapGetters({
        otpMessage: "familyModule/getotp",
        Error: "familyModule/errorverify",
        
        }),
    },
    watch: {
        otpMessage(newvalue){
            if(newvalue.length > 0 && this.resend) {
                this.loader1=false
            }
            
        },
        
        
    },
    mounted() {
        this.$nextTick(() => {
        const targetDiv = document.querySelector('.accout_sidebar ');

        if (targetDiv) {
            // Add a class to the found div
            targetDiv.classList.add('hidehere');
        }
        });
    },
    beforeRouteLeave(to, from, next) {
        const targetDiv = document.querySelector('.accout_sidebar ');

        if (targetDiv) {
            targetDiv.classList.remove('hidehere');
        }

        next();
    },
    methods: {
        verifyOTP() {            
            if(this.otp.code !='') {
                this.otp.id = this.otpMessage.Data.family_member_id
             this.$store.dispatch("familyModule/verifyOtp",this.otp);
             this.loader=true
            }
        
        },
        resendOtp() {
            this.loader=false
            this.loader1=true
            this.otp.id = this.otpMessage.Data.family_member_id
            this.selectedLink="noResend"
            this.$store.commit("familyModule/clearErrorOtp");
            this.$store.commit('familyModule/resetOtp');
            this.resend=true
            this.$store.dispatch("familyModule/ResendOTP",this.otp.id);
            setTimeout(() => {
                this.selectedLink=""
                this.resend=false

            }, 5000);
            setTimeout(() => {
                this.loader1=false

            }, 2000);
        },
        activer(){
            this.selectedLink=""
            this.loader=false
            this.loader1=false
            this.$store.commit("familyModule/clearErrorOtp");

        }
    }
    
}
</script>
<style>

.familyOtp {
    background: white;
    padding: 25px;
    box-sizing: border-box;
    margin: 0 auto;
    display: block;
    width: 50%;
}
.familyOtp-block h2 {
    text-align: center;
    margin: 10px -1px;
}
</style>