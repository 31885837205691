<template>
    <div class="main_cm">
                <div class="testi_monial">
                    <img src="@/assets/images/lady.png">
                    <div class="comment-sec">
                        <h2>Our Customers <b>About Us</b></h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                            Lorem  Ipsum has been the industry's standard dummy text ever since the 1500s
                        </p>

                        <div class="comments owl-wrapper">                            
                            <Carousel v-if="info.length > 0"
                                :responsive="{0:{items:1,nav:false,dots:false,autoplay:true},600:{items:2,nav:false,dots:true,loop:false,margin:10}}"
                            >
                                
                                    <SingleComment v-for="(comment,index) in info" :key="index" :comment="comment"></SingleComment>
                        </Carousel>
                            
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
import SingleComment from './SingleComment.vue';
import Carousel from 'vue-owl-carousel';
import {mapGetters} from "vuex";

export default {
    name: "TestiMonials",
    components: {
        SingleComment,
        Carousel,
    },

    data(){
        return {
           
        }
    },
    computed:{
    ...mapGetters({
        info:"pagesModule/testimonial",
        
        
    }),
    

    },
    created(){
        this.loadInfo()
    },
    methods:{
        loadInfo() {
            this.$store.dispatch("pagesModule/getTestinonials");
        }
    }
    
}
</script>