import accountService from "@/api-services/services/account.service";
export const accountModule = {
    // This makes your getters, mutations, and actions accessed by, eg: 'myModule/myModularizedNumber' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,
    state: {
        details:[],
        update:'',
        failedFetch:[],
        logoutM:[],
        notis:[]
    },
    mutations: {
        
        ProfileDetails:(state,{data})=> {
            state.details= data          
            
        },
        ProfileUpdate:(state,{data})=> {
            state.update= data.Message        
            
        },
        ProfileDetailsFaild:(state,{data})=> {
            state.failedFetch= data        
            
        },
        logoutD:(state,{data})=> {
            state.logoutM= data        
            
        },
        notificationList:(state,{data})=> {
            state.notis= data.Data    
            
        },
        clearM:(state)=> {
            state.update= ''        
             
        },
    },
    actions: {
        async getProfile({commit}) {
            //alert("test")
            let bearerToken = localStorage.getItem('token');
            const response = await accountService.getDetails(bearerToken);
            
            if(response.data.Status == 1) {            
                const data = response.data.Data
                console.log(data);
                commit("ProfileDetails",{data} ); 
                
            }
            else {
                const data = response.data
                commit("ProfileDetailsFaild",{data} ); 
            }
            
        },
        async setProfile({commit},profile) {
            //alert(profile)
            let bearerToken = localStorage.getItem('token');
            
            const response = await accountService.setDetails(bearerToken,profile);
            
            if(response.data.Status == 1) {
                
                const data = response.data
                console.log(data);
                commit("ProfileUpdate",{data} ); 
                
            }
            
        },
        async LogoutUser({commit}) {
            //alert(profile)
            let bearerToken = localStorage.getItem('token');
            // console.log(profile);
            const response = await accountService.logout(bearerToken);
                const data = response.data
                // console.log(data);
                commit("logoutD",{data} ); 
                
        },
        async getNotification({commit},id) {
            //alert(profile)
            let bearerToken = localStorage.getItem('token');
            const response = await accountService.notifications(bearerToken,id);
                const data = response.data
                // console.log(data);
                commit("notificationList",{data} ); 
                
        },

    },
    getters: {
        profile: (state) => state.details,
        successMessage: (state) => state.update,
        failMessage: (state) =>state.failedFetch,
        log: (state) =>state.logoutM,
        notif: (state) =>state.notis
        
    }
};
  
//   export default userModule