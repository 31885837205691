import kycService from "@/api-services/services/kyc.service";
// import router from '@/routers'
export const kycModule = {
    // This makes your getters, mutations, and actions accessed by, eg: 'myModule/myModularizedNumber' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,
    state: {
        upload:'',
        kycs:[]
    },
    mutations: {
        
        uploadSuccess:(state,{data})=> {
            state.upload= data.Message         
            
        },
        kycDetails:(state,{data})=> {
            state.kycs= data         
            
        },
        clearM:(state)=> {
            state.upload= '' 
            
        },
        clearList:(state)=> {
            state.kycs= '' 
            
        },
        
    },
    actions: {
        async uploadKYC({commit}, kyc) {
            //alert("test")
            //console.log(adhar.dc);
            let bearerToken = localStorage.getItem('token');
            const response = await kycService.uploadKYC(bearerToken,kyc);
            
            if(response.data.Status == 1) {
            
                const data = response.data
               // console.log(data);
                commit("uploadSuccess",{data} ); 
                
            }
            
        },
        
        async getKYC({commit}) {
            //alert("test")
            let bearerToken = localStorage.getItem('token');
            const response = await kycService.loadKYC(bearerToken);
            
                const data = response.data.Data
                //console.log(data);
                commit("kycDetails",{data} ); 
                
            
        },
       
     

    },
    getters: {
        upld: (state) => state.upload,
        kyc: (state) => state.kycs
    }
};
  
//   export default userModule