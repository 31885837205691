<template>
    <div class="bgw">
        <div class="KYC_area">
            <div class="updateSuccess  popup-login" v-if="this.message">
            <div>
            <div class="checkmark-circle">
                  <div class="background"></div>
                  <div class="checkmark draw"></div>
                </div>
                <h3 style="display: block;    width: 100%;"> {{ this.message }}</h3>
            </div>
        </div>
            <div class="kyc-box">
                <h2 class="verified_kyc">Aadhaar Details
                     <font-awesome-icon icon="fa-solid fa-circle-check" v-if="kyc.adarNo"/>
                     <font-awesome-icon :icon="['fas', 'circle-exclamation']" v-else/>
                    </h2>
                <div class="kyc-row">
                    <label>Aadhar Number</label>
                    <input v-model="kyc.adarNo" @focus="clear('ad')" @input="numOnly" maxlength="16">
                </div>
                <div class="kyc-row">
                    <label>Upload Document</label>
                    <div class="file-ip">
                        <p v-if="kyc.adarFile ">
                            {{ kyc.adarFile.name }}
                        </p>
                        <p v-else-if="kyc.adarFile=='' && kycList.aadar_document !='' && kycList.aadar_number != null">
                            {{ kycList.aadar_document }}
                        </p>
                        <p v-else>
                           {{ name }}
                        </p>
                        <font-awesome-icon :icon="['fas', 'file-arrow-up']" />
                        <input type="file" accept=".pdf, application/pdf" name="attachment[]"  @change="onFileChange"  />
                    </div>
                    <span class="file-error" v-if="error.ad">{{ error.ad }}</span>
                
                </div>
                
            </div>

            <div class="kyc-box">
                <h2 class="pending_kyc">PAN Details 
                    <font-awesome-icon icon="fa-solid fa-circle-check" v-if="kyc.panNo"/>
                     <font-awesome-icon :icon="['fas', 'circle-exclamation']" v-else/>
                </h2>
                <div class="kyc-row">
                    <label>PAN Number</label>
                    <input v-model="kyc.panNo" @focus="clear('pn')" @input="validateInput('pan')">
                </div>
                <div class="kyc-row">
                    <label>Upload Document</label>
                    <div class="file-ip">
                        <p v-if="kyc.panFile">
                           {{ kyc.panFile.name }}
                        </p>
                        <p v-else-if="kyc.panFile=='' && kycList.pan_documentt !='' && kycList.pan_number != null">
                            {{ kycList.pan_document }}
                        </p>
                        <p v-else>
                           {{ name }}
                        </p>
                        <font-awesome-icon :icon="['fas', 'file-arrow-up']" />
                        <input type="file" accept=".pdf, application/pdf" name="attachment[]" @change="onFileChange1"  />
                    </div>
                    <span class="file-error" v-if="error.pn">{{ error.pn }}</span>
                
                </div>
                
            </div>

            <div class="kyc-box">
                <h2 class="pending_kyc">Bank Details 
                    <font-awesome-icon icon="fa-solid fa-circle-check" v-if="kyc.ac"/>
                     <font-awesome-icon :icon="['fas', 'circle-exclamation']" v-else/>
                </h2>
                <div class="kyc-row">
                    <label>Account Holder Name</label>
                    <input v-model="kyc.acHolder" @focus="clear('bn')" @input="letterOnly">
                </div>
                <div class="kyc-row">
                    <label>Account Number</label>
                    <input v-model="kyc.ac"  @focus="clear('bn')" @input="numOnly" maxlength="16">
                </div>
                <div class="kyc-row">
                    <label>Bank Name</label>
                    <input v-model="kyc.bname"  @focus="clear('bn')">
                </div>
                <div class="kyc-row">
                    <label>Branch</label>
                    <input v-model="kyc.branch"  @focus="clear('bn')">
                </div>
                <div class="kyc-row">
                    <label>IFSC</label>
                    <input v-model="kyc.acIFSC"   @focus="clear('bn')" @blur="validateIFSC"  maxlength="11">
                    <span class="error">{{error.ifsc}}</span>
                </div>
                <div class="kyc-row">
                    <label>Upload Document</label>
                    <div class="file-ip">
                        <p v-if="kyc.acFile">
                           {{ kyc.acFile.name }}
                        </p>
                        <p v-else-if="kyc.acFile == '' && kycList.bank_document!='' && kycList.account_number != null" >
                           {{ kycList.bank_document }}
                        </p>
                        <p v-else>
                           {{ name }}
                        </p>
                        <font-awesome-icon :icon="['fas', 'file-arrow-up']" />
                        <input type="file" accept=".pdf, application/pdf" name="attachment[]" @change="onFileChange2"  />
                    </div>
                    <span class="file-error" v-if="error.bn">{{ error.bn }}</span>
                
                </div>
                
            </div>


            <div class="kyc-box">
                <h2 class="pending_kyc">Passport Details 
                    <font-awesome-icon icon="fa-solid fa-circle-check" v-if="kyc.passportNo"/>
                     <font-awesome-icon :icon="['fas', 'circle-exclamation']" v-else/>
                </h2>
                
                <div class="kyc-row">
                    <label>Passport Number</label>
                    <input v-model="kyc.passportNo" @focus="clear('ps')" @input="validateInput('pass')" >
                </div>
                <div class="kyc-row">
                    <label>Upload Document</label>
                    <div class="file-ip">
                        <p v-if="kyc.passportFile">
                           {{ kyc.passportFile.name }}
                        </p>
                        <p v-else-if="kyc.passportFile == '' && kycList.passport_document !='' && kycList.passport != null">
                           {{ kycList.passport_document }}
                        </p>
                        <p v-else>
                           {{ name }}
                        </p>
                        <font-awesome-icon :icon="['fas', 'file-arrow-up']" />
                        <input type="file" accept=".pdf, application/pdf" name="attachment[]" @change="onFileChange3"  />
                    </div>
                    <span class="file-error" v-if="error.ps">{{ error.ps }}</span>
                
                </div>
                
            </div>
            <div class="kyc-box">
                <button @click="updateKyc">Submit</button>
                <span class="loader" v-if="loader && message ==''"></span>
                
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "KycDoc",
    data() {
        return {
            name:'No file selected',
            kyc:{
                adarNo:'',
                adarFile:'',
                panNo:'',
                panFile:'',
                ac:'',
                acHolder:'',
                acIFSC:'',
                branch:'',
                bname:'',
                acFile:'',
                passportNo:'',
                passportFile:''
            },
            error:{
                ad:'',
                pn:'',
                bn:'',
                ps:'',
                ifsc:''
            },
            flag:1,
            flag1:1,
            flag2:1,
            flag3:1,
            loader:false

        };
    },
    created(){
        this.loadKYC()
        
    },
    computed:{
        ...mapGetters({
        message:"kycModule/upld",
        kycList:"kycModule/kyc"
        
        }),
        
        loadk() {
        return this.kycList;
        },
        successS() {
        return this.message;
        },

        
    },
    watch: {
        loadk: {
        deep: true,
        handler() {
            this.loadVal();
           
        },
        },
        successS: {
        deep: true,
        handler() {
            this.messageHide();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.loader=false
        },
        },
        
    },

    methods: {
        onFileChange(e) {
        this.kyc.adarFile = e.target.files[0];
        // console.log(this.adhar.file);
        this.error.ad=''
        },
        onFileChange1(e) {
        this.kyc.panFile = e.target.files[0];
        this.error.pn=''
        },
        onFileChange2(e) {
        this.kyc.acFile = e.target.files[0];
        this.error.bn=''
        },
        onFileChange3(e) {
        this.kyc.passportFile = e.target.files[0];
        this.error.ps=''
        },
        updateKyc(){
            
            if(this.kyc.adarNo!='' && this.kyc.adarFile=='' && this.kycList.aadar_document==null || this.kyc.adarNo=='' && this.kyc.adarFile!='' && this.kycList.aadar_document==null ){
                this.error.ad="Please fill out all fields in this section.";
                this.flag=1
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            else {
                this.flag=0
               
            }
           if(this.kyc.panNo!='' && this.kyc.panFile==''  && this.kycList.pan_document==null || this.kyc.panNo=='' && this.kyc.panFile!='' && this.kycList.pan_document==null){
                this.error.pn="Please fill out all fields in this section.";
                this.flag1=1
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            else {
                this.flag1=0
            }
            if(this.kyc.ac!='' && this.kyc.acFile==''  && this.kycList.bank_document==null || this.kyc.ac=='' && this.kyc.acFile!=''  && this.kycList.bank_document==null ||
            this.kyc.acHolder!='' && this.kyc.acFile==''  && this.kycList.bank_document==null || this.kyc.acHolder=='' && this.kyc.acFile!=''  && this.kycList.bank_document==null ||
            this.kyc.acIFSC!='' && this.kyc.acFile==''  && this.kycList.bank_document==null || this.kyc.acIFSC=='' && this.kyc.acFile!='' && this.kycList.bank_document==null
            ){
                this.error.bn="Please fill out all fields in this section.";
                this.flag2=1
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            else {
                this.flag2=0
            }
            if(this.kyc.passportNo!='' && this.kyc.passportFile=='' && this.kycList.passport_document==null || this.kyc.passportNo=='' && this.kyc.passportFile!='' && this.kycList.passport_document==null)
            {
                this.error.ps="Please fill out all fields in this section.";
                this.flag3=1
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            else {
                this.flag3=0
            }

            if(this.flag==0 && this.flag1==0 && this.flag2==0 && this.flag3==0){
               this.loader=true
                if (Object.values(this.kyc).some(field => field !== '')) {
                        
                        // alert(this.PAN);
                        
                        
                        this.$store.dispatch("kycModule/uploadKYC", this.kyc);
                    }
                    else {
                        
                        // this.$store.commit('kycModule/clearList');
                        // this.loadKYC()
                        
                        setTimeout(() => {
                            this.loader=false;
                            window.scrollTo({ top: 0, behavior: 'smooth' });

                    }, 2000);
                        
                    }
            }
            
        },

        clear(e){
            this.error[e]=''
           
                this.error.ifsc=''
            

        },
        loadKYC(){
            this.$store.dispatch("kycModule/getKYC");
        },
        messageHide(){
            this.loader1=false;
            setTimeout(() => {
                //this.delayedValue = this.valueToDelay;
                this.$store.commit('kycModule/clearM');

            }, 2000);

        },
        loadVal(){
            if(this.kycList.aadar_number){
                this.kyc.adarNo =this.kycList.aadar_number
            }
            // if(this.kycList.aadar_document){
            //     this.kyc.adarFile =this.kycList.aadar_document
            // }
            if(this.kycList.pan_number){
                this.kyc.panNo =this.kycList.pan_number
            }
            // if(this.kycList.pan_document){
            //     this.kyc.panFile =this.kycList.pan_document
            // }
            if(this.kycList.passport){
                this.kyc.passportNo =this.kycList.passport
            }
            // if(this.kycList.passport_document){
            //     this.kyc.passportFile =this.kycList.passport_document
            // }
            if(this.kycList.account_holder_name){
                this.kyc.acHolder =this.kycList.account_holder_name
            }
            if(this.kycList.account_number){
                this.kyc.ac =this.kycList.account_number
            }
            if(this.kycList.branch){
                this.kyc.branch =this.kycList.branch
            }
            if(this.kycList.bank_name){
                this.kyc.bname =this.kycList.bank_name
            }
            if(this.kycList.ifsc_code){
                this.kyc.acIFSC =this.kycList.ifsc_code
            }
            // if(this.kycList.bank_document){
            //     this.kyc.acFile =this.kycList.bank_document
            // }
           
        },
        numOnly() {
					this.kyc.adarNo = this.kyc.adarNo.replace(/[^0-9]/g, "");
				},
        letterOnly(){
            this.kyc.acHolder = this.kyc.acHolder.replace(/[^a-zA-Z ]/g, '');
        },
        validateIFSC() {
            const ifscPattern = /^[A-Z]{4}[0][0-9]{6}$/; // IFSC format pattern

            const inputValue = this.kyc.acIFSC;
            if (!ifscPattern.test(inputValue))  {
                this.error.ifsc="enter a valid IFSC"
                this.kyc.acIFSC=''
            }
        
        },
        validateInput(ev) {
            if(ev=='pass'){
                this.kyc.passportNo = this.kyc.passportNo.replace(/[^a-zA-Z0-9]/g, '');
            }
            if(ev=='pan'){
                this.kyc.panNo = this.kyc.panNo.replace(/[^a-zA-Z0-9]/g, '');
            }
            
        }
}
    
    
}
</script>