<template>
    <div class="mainP HomePage">
        <AppHeader></AppHeader>
        <div class="banner">
            <HomeBanner></HomeBanner>
        </div>
        <div class="rec-bookrd" v-if="show">
        <!-- <div class="rec-bookrd" > -->
            <RecentlyBooked></RecentlyBooked>
        </div>
        <div class="featured_speciality">
            <FeaturedSpeciality :title1="title1" :specialities="info.top_specailty" v-if="info.top_specailty" ></FeaturedSpeciality>
        </div>
        <div class="ad-sec">
            <!-- <img class="bg2" src="@/assets/images/bg1.png"> -->
            <svg width="530" height="709" viewBox="0 0 530 709" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M490.083 163.956C644.341 470.311 304.22 347.873 207.576 497.689C121.616 630.941 -94.9492 843.248 -273.966 684.507C-370.904 118.341 -634.038 83.9899 -655.838 -210.498C-675.748 -479.446 -387.709 105.043 -207.651 129.557C-31.8021 153.497 348.106 -118.008 490.083 163.956Z" fill="url(#paint0_linear_282_697)"/>
            <defs>
            <linearGradient id="paint0_linear_282_697" x1="246.5" y1="20" x2="-364.5" y2="510" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FF6D43"/>
            <stop offset="0.979605" stop-color="#FF4525" stop-opacity="0.42"/>
            </linearGradient>
            </defs>
            </svg>





            <div class="wrapper">
                <AdsHome v-if="info.advertisements" :imgs="info.advertisements"></AdsHome>
                <BookingStep></BookingStep>
            </div>
        </div>
        <div class="top_tab">
            <TopBookings :title2="title2" :TopBookings="info.top_bookings" v-if="info.top_bookings"></TopBookings>
        </div>
        <div class="book_now_section">
            <BookNowhome></BookNowhome>
        </div>
        <div class="newsEvents">
            <NewsandEvents></NewsandEvents>
        </div>
        <div class="bg-color-sec">
            <div class="testimonials">
                <TestiMonials></TestiMonials>
            </div>
            <AppFooter></AppFooter>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/shared/AppHeader.vue';
import HomeBanner from '@/components/Home/HomeBanner.vue';
import FeaturedSpeciality from '@/components/Home/FeaturedSpeciality.vue';
import AdsHome from '@/components/Home/AdsHome.vue';
import BookingStep from '@/components/Home/BookingStep.vue';
import TopBookings from '@/components/Home/TopBookings.vue';
import BookNowhome from '@/components/Home/BookNowhome.vue';
import TestiMonials from '@/components/Home/TestiMonials.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
import NewsandEvents from '@/components/Home/NewsandEvents.vue';
import RecentlyBooked from '@/components/Home/RecentlyBooked.vue';
import {mapGetters} from "vuex"
export default {
    name: "HomePage",
    data() {
        return {
            title1: 'Specializations',
            title2: 'Top Bookings',
            show:false
        }
    },
    components: {
        AppHeader,
        HomeBanner,
        FeaturedSpeciality,
        AdsHome,
        BookingStep,
        TopBookings,
        BookNowhome,
        NewsandEvents,
        TestiMonials,
        AppFooter,
        RecentlyBooked,
        
    },
    computed:{
        ...mapGetters({
            specialities:"searchModule/specialties",
            info:"pagesModule/homeData",
        
        }), 
    },
//     beforeRouteEnter(to, from, next) {
//     // Check if the user is coming from a specific route
//     if (from.name === "Search") {
//       alert("t")
//       next(vm => {
//         vm.$store.dispatch("searchModule/getCityList");
//       });
//     } else {
//       // Do something else or just call next() without triggering the action
//       next();
//     }
//   },
    created() {
        if(sessionStorage.getItem('logstatus')==1 && sessionStorage.getItem('userid') != '') {
            this.show=true
        }
        this.loadHomeinfo()
    },
    methods: {
        loadHomeinfo(){
            this.$store.dispatch("pagesModule/getHomeInfo");
        }
    }
}
</script>
<style>
/* .banner{
    background-image: url(../assets/images/colorbg.jpg);
}
.banner {
    background-image: url(http://localhost:8080/img/colorbg.e1e93f80.jpg);
    background-size: cover;
    background-position: center;
} */
</style>