<template>
    <div class="providers" >
            
        <SingleProvider v-for="serviceprovider in serviceproviderList" :key="serviceprovider.id" :serviceprovider="serviceprovider"
                @passDisable="disableButon" :class="{ButtonAction: ClassDis === true && PassId !== serviceprovider.id }">
        </SingleProvider>
            
    </div>
</template>

<script>
// import {mapGetters} from "vuex";
import SingleProvider from "@/components/Services/SingleProvider.vue"
export default {
    name: "ServiceProviders",
    components: {
        SingleProvider
    },
    data() {
        return {
           loader:true,
           ClassDis:'',
           PassId:''
        }
    },
    props: {
        serviceproviderList: {
            type: Array,
        },
       
    },
    computed:{
        // loader() {
        // return this.serviceproviders !== null && this.serviceproviders !== undefined;
        // },
        // ...mapGetters({
        //     loader:"providerModule/List",
        
        // }),
    },
    
    methods:{
        disableButon(v){
            this.ClassDis=v.noClick
            this.PassId=v.id
        }
    }
    
}
</script>