import { render, staticRenderFns } from "./PrivcyPolicy.vue?vue&type=template&id=5d4c14aa&"
import script from "./PrivcyPolicy.vue?vue&type=script&lang=js&"
export * from "./PrivcyPolicy.vue?vue&type=script&lang=js&"
import style0 from "./PrivcyPolicy.vue?vue&type=style&index=0&id=5d4c14aa&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports