<template>
    <div class="signin forgot OTPs"> 
        
        <div class="authform">
            
            <div>
                <div class="vrfy-otp" >
                    <span v-if=" otpMessage" class="alrt_scss">{{ otpMessage }}</span>                      
                </div>
                <h2 style="text-align: center;">Enter Your OTP </h2>
                <span v-if="otp" style="font-size: 10px;text-align: center;width: 100%;display: block;">OTP : {{ user.otp }}</span>
                
               
                <div class="form">
                    <label>OTP
                        <input v-model="otp.code" @focus="activer" maxlength="6" @input="filterNonNumeric">
                        <span class="error" v-if="error" style="text-align: left;">{{ error }}</span>
                    </label>
                    
                    <button @click="verifyOTP">Proceed</button>
                    <span class="error" v-if="errorC">{{ errorC }}</span>
                    <span class="loader" v-if="loader && user =='' && otpMessage =='' && errorC==''"></span>
                    <button class="resend"  @click="resendOtp()" :class="{noResend: selectedLink === 'noResend'}">Resend OTP</button>
                    
                </div>
            </div>
            
        </div>
        <img src="@/assets/images/pswd1.png" class="pos-image-auth">
        

    </div>
</template>

<script>
// import AuthTitle from '@/components/Authentication/AuthTitle.vue';
import {mapGetters} from "vuex";
export default {
    name: "ForgotpasswordOtpverify",
    // components: {
    //     AuthTitle
    // },
   
    data(){
        return {
            title:'Enter Your OTP ',  
            otp: {
                code:'',
                id:''
            }  ,
            loader:false,
            selectedLink:'',
            error:''


        }
    },

    computed:{
    ...mapGetters({
        otpMessage: "ChangeModule/messotp",
        user:"ChangeModule/otpsend", 
        errorC:'ChangeModule/otpError'  
        }),
    },
    watch: {
        user() {
        // Update the timeslot prop passed to the child component
        //this.timeslot = newValue;
        window.sessionStorage.setItem("uid", this.user.customer_id ); 
        },
        
    },
    created() {
        window.sessionStorage.setItem("uid", this.user.customer_id )
    //     if(this.$store.getters['userModule/id']){
    //         this.otp.id =this.$store.getters['userModule/id']
    //         window.sessionStorage.setItem("regid", this.otp.id ); 
    //     }
    //     if(this.otp.id == ''){
    //         this.otp.id= window.sessionStorage.getItem("regid");        
    //     }
        
    },
    methods: {
        verifyOTP() {            
            if(this.otp.code !='') {
             //window.sessionStorage.removeItem("uid");
            //  this.otp.code=this.user.otp
            if(this.otp.code.length==6){
                this.otp.id= this.user.customer_id
                this.loader=true
                this.$store.dispatch("ChangeModule/ForgotOTP",this.otp);
                }
                else {
                    this.error= "The otp field must be 6 digit"
                }
             
            
            }
            else {
                this.error="OTP is mandatory"

            }
        
        },
        resendOtp() {
            this.error=''
            this.selectedLink="noResend"
            this.otp.id= window.sessionStorage.getItem("uid")
            this.$store.commit('ChangeModule/resetMessage');
            this.$store.dispatch("ChangeModule/ResendOTP",this.otp.id);
            setTimeout(() => {
                this.selectedLink=""

            }, 5000);
        },
        activer(){
            this.selectedLink=""
            this.error=''

        },
        filterNonNumeric() {
					// Replace non-numeric characters with an empty string
					this.otp.code = this.otp.code.replace(/[^0-9]/g, "");
				},
    }
    
}
</script>
<style>
.error{
    text-align: center !important;
    display: block;
}
</style>