<template>
    <div class="header-main2"> 
        <router-link to="/">
             <img alt="Vue logo" src="@/assets/images/logo.png">   
        </router-link>
    </div>
</template>

<script>
export default {
    name: "AppLogo",
    
}
</script>