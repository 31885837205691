<template>
    <div class="list_page_layout">
        <AppHeaderVue></AppHeaderVue>
            <div class="wrapper">
                <div class="privcyPolicy">
                    <h2>Privacy Policy </h2>
                    {{ info.description }}
                </div>
            </div>
        <AppFooter></AppFooter>
        
    </div>
</template>

<script>
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
import {mapGetters} from "vuex";
export default {
    name: "PrivcyPolicy",
    components: {
        AppHeaderVue,
        AppFooter
    },
    computed:{
    ...mapGetters({
        info:"pagesModule/policy",
        
        
    }),
    

    },
    created(){
        this.loadInfo()
    },
    methods:{
        loadInfo() {
            this.$store.dispatch("pagesModule/getPolicyInfo");
        }
    }


    
}
</script>
<style>
.privcyPolicy {
    margin: 50px 0;
}
</style>