
<template>
    <div class="change_password"> 
        
          <div class="change_password_fm">
            <h2 class="my_title_d">Change Password</h2>
            <div class="form">
            <div class="lb_d">
                <label>Current Password <b style="color:red;">*</b></label>
                        <span style="position: relative;" class="eye">
                        <input :type="type" v-model="cred.oldpass" autocomplete="new-password" @focus="clerError">
                        <button class="tg-ps" @click="showPassword()" ><font-awesome-icon :icon="'fa-solid ' + btnText "/></button>
                </span>
                <span class="error">{{ this.error3 }}</span>
            </div>
            <div class="lb_d">
                <label >New Password <b style="color:red;">*</b> </label>
                <span style="position: relative;" class="eye">
                    <input :type="type1" v-model="cred.newpass" @focus="reseterror" @blur="validatepass()">
                    <button class="tg-ps" @click="showPassword1()" ><font-awesome-icon :icon="'fa-solid ' + btnText1 "/></button>
                </span>
                <span class="error">{{ this.error1 }}</span>
            </div>
            <div class="lb_d">
                <label>Confirm Password <b style="color:red;">*</b></label>
                        <span style="position: relative;" class="eye">
                        <input :type="type2" v-model="cred.confirmnew" @blur="validatecp()" @focus="clerError1">
                        <button class="tg-ps" @click="showPassword2()" ><font-awesome-icon :icon="'fa-solid ' + btnText2 "/></button>
                </span>
                <span class="error">{{ this.error2 }}</span>
            </div>
            <button @click="resetPassword">Submit</button>
            <div class="error" v-if="ems" style="text-align: center !important;">{{ ems }}</div>
            <div class="lb_d" style="text-align: center;">
                <span class="loader"  v-if="loader && ems==''"></span>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    name: "ChangePassword",
    
    
    data(){
        return {
            cred:{
                oldpass:'',
                newpass:'',
                confirmnew:'',
                uid:sessionStorage.getItem('userid')
            },
            error1:'',
            error2:'',
            error3:'',
            type: 'password',
            btnText: 'fa-eye-slash',
            type1: 'password',
            btnText1: 'fa-eye-slash',
            type2: 'password',
            btnText2: 'fa-eye-slash',
            flag:'',
            loader:false,
        }
    },
    computed:{
        ...mapGetters({
            ems:"ChangeModule/me",
            
        }),
    },
    beforeDestroy() {
        this.clearAPI()
    },
    methods: {
        resetPassword() {
            
            if(this.cred.oldpass != '' && this.cred.newpass !='' && this.cred.confirmnew !='' && this.flag==1) {
                this.error1=''
                this.error2=''
                this.error3=''
                this.loader=true;
                this.$store.dispatch("ChangeModule/ResetPassword",this.cred);            
            }
            else {
                if (!this.cred.oldpass) {
        this.error3 = "Current Password is mandatory.";
      }

      if (!this.cred.newpass) {
        this.error1 = "New Password is mandatory.";
      }

      if (!this.cred.confirmnew) {
        this.error2 = "Confirm Password is mandatory.";
      }


    
            }
           
        },
        validatepass() {
                
                const passwordPattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(.{8,})$/;
                this.isValid = passwordPattern.test(this.cred.newpass);
                if(this.isValid) {
                    this.error1=''
                }else {
                    this.error1="Password must contain at least one number, one uppercase letter, one lowercase letter, one special character and minimum 8 characters"
                }
            },
        showPassword() {
                if(this.type === 'password') {
                    this.type = 'text'
                    this.btnText = 'fa-eye'
                } else {
                    this.type = 'password'
                    this.btnText = 'fa-eye-slash'
                }
        },
        showPassword1() {
                if(this.type1 === 'password') {
                    this.type1 = 'text'
                    this.btnText1 = 'fa-eye'
                } else {
                    this.type1 = 'password'
                    this.btnText1 = 'fa-eye-slash'
                }
        },
        showPassword2() {
                if(this.type2 === 'password') {
                    this.type2 = 'text'
                    this.btnText2 = 'fa-eye'
                } else {
                    this.type2 = 'password'
                    this.btnText2 = 'fa-eye-slash'
                }
        },
        validatecp(){
            if( this.cred.newpass ===  this.cred.confirmnew){
                this.error2=""
                this.flag=1
            }
            else{
                this.error2="Password does not match"
                this.flag=''
            }
        },
        reseterror(){
                    this.error1='';
                    if(this.ems) {
                        this.loader=false
                        this.$store.commit('ChangeModule/resetState');
                    } 
                    
                },
        clearAPI(){
            if(this.ems) {
                        this.loader=false
                        this.$store.commit('ChangeModule/resetState');
                    } 
        },
        clerError(){
            this.loader=false
            this.error3=''
            this.$store.commit('ChangeModule/resetState');
        },
        clerError1(){
            this.loader=false
            this.error2=''
            this.$store.commit('ChangeModule/resetState');
        }
    }
    
}
</script>