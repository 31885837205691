import ServiceproviderService from "@/api-services/services/serviceprovider.service";
import router from '@/routers'
export const providerModule = {
    // This makes your getters, mutations, and actions accessed by, eg: 'myModule/myModularizedNumber' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,
    state: {
        providers:[],
        details:[],
        addedMessage:'',
        removeMessage:'',
        favlist:[],
        SearchEmpty:'',
        filteredProvider:[]
    },
    mutations: {
        serviceList:(state,{data})=> {
          state.providers= data.Data       
          
      },
      searchList:(state,{data,filter})=> {
            state.providers= data.Data ;
            router.push({ name: "Search", query: { 
                category: filter.category ,
                subCategory: filter.subcategory ,
                specialty: filter.specialty ,
                serviceProviderName: filter.name ,
                city: filter.city ,
                location: filter.location ,
                appointmentDate: filter.date ,
    
             }} );        
            
        },
        noresult:(state,{data})=> {
            state.SearchEmpty= data       
            
        },
      serviceDetails:(state,{data})=> {
        state.details= data.Data       
        
    },
    addedtoFav:(state,{data})=> {
            state.addedMessage= data.Message      
            
        },
        // FilterL:(state,{data})=> {
        //     state.filteredProvider= data.Message      
            
        // },
        removeFav:(state,{data})=> {
            state.removeMessage= data.Message      
            
        },
        clearFav:(state) => {
            state.addedMessage=''
            state.removeMessage=''
        },
        ListFav:(state,{data})=> {
            state.favlist= data.Data    
            
        },
        clearList:(state)=> {
            state.providers= ''   
            
        },
        ClearDetail:(state)=> {
            state.details= ''   
            
        },
      

    },

    actions: {
       
      async getproviders({commit}, filter) {
         //alert("test")
          const response = await ServiceproviderService.getproviders(filter);
         
         if(response.data.Status == 1) {
          
             const data = response.data
             //console.log(data);
             commit("serviceList",{data} ); 
             
         }
         
     },
     async searchProviders({commit}, filter) {
        //alert("test")
        
        commit("clearList"); 
         const response = await ServiceproviderService.getproviders(filter);
        
        if(response.data.Status == 1) {         
            const data = response.data
            //console.log(data);
            commit("searchList",{data,filter} );             
        }
        // else {
        //     const data = response.data
        //     commit("noresult",{data} ); 
        // }
        
    },
     async getpagination({commit}, num) {
      //alert("test")
       const response = await ServiceproviderService.getpagination(num);
      
      if(response.data.Status == 1) {
       
          const data = response.data
          //console.log(data);
          commit("serviceList",{data} ); 
          
      }
      
  },
  async loadDetails({commit}, id) {
    //alert("test")
    let bearerToken = localStorage.getItem('token');
     const response = await ServiceproviderService.loadProvider(bearerToken,id);
    
        const data = response.data
        //console.log(data);
        commit("serviceDetails",{data} ); 
    
    },
    async addtoFav({commit}, id) {
        //alert("test")
        let bearerToken = localStorage.getItem('token');
         const response = await ServiceproviderService.AddToFavList(bearerToken,id);
         if(response.data.Status == 1) {
            const data = response.data
            commit("addedtoFav",{data} ); 
         }
        
        },
    async removeFav({commit}, id) {
            //alert("test")
            let bearerToken = localStorage.getItem('token');
             const response = await ServiceproviderService.removeFavList(bearerToken,id);
             if(response.data.Status == 1) {
                const data = response.data
                commit("removeFav",{data} ); 
             }
            
            },
        async loadFav({commit},id) {
                //alert("test")
                let bearerToken = localStorage.getItem('token');
                 const response = await ServiceproviderService.loadFavList(bearerToken,id);
                
                    const data = response.data
                    //console.log(data);
                    commit("ListFav",{data} ); 
                
        },
        async loadFiltered({commit},filter) {
            //alert("test")
            // let bearerToken = localStorage.getItem('token');
            //console.log(filter)
             const response = await ServiceproviderService.filteredList(filter);            
                const data = response.data
                //console.log(data);
                commit("serviceList",{data} ); 
            
        },
        

    },


    getters: {
        List: (state) => state.providers,
        detail: (state) => state.details,
        added: (state) => state.addedMessage,
        removed: (state) => state.removeMessage,
        ListF: (state) => state.favlist,
        // SearchList: (state) => state.providers,
        Emptyresult: (state) => state.SearchEmpty,
        //ListFlter: (state) => state.filteredProvider
    }


  };
  
//   export default userModule