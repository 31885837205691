<template>
    <div class="addNewFam">
        <h2 class="my_title_d" style="text-align: center;"> Edit Family Member</h2>
        <div class="famform">
            
            <div class="fields-fam">
                <div>
                    <label>Name<b>*</b></label>
                    <input v-model="familymember.name" @focus="clearN('name')">
                    <span class="error">{{ errors.name }}</span>
                </div>                
                
                <div class="err_space">
                    <label>Mobile Number<b>*</b></label>
                    <div class="prefix new-fm-prefix " :class="{hideOnlod: onld == 'hideOnlod'}">
                        <span >+91</span>
                        <input v-model="familymember.PH" @input="filterNonNumeric()" @blur="validatePh()" @focus="clearN('ph')">
                    </div>
                    <span v-if="errors.ph">{{ errors.ph }}</span>
                </div>
            </div>
            <div class="fields-fam">
                <div class="err_space">
                    <label>Email<b>*</b></label>
                    <input v-model="familymember.email" @blur="validateEmail()" @focus="clearN('mail')">
                    <span v-if="errors.mail">{{ errors.mail }}</span>
                </div>
                <div>
                    <label >Relation<b>*</b></label>
                    <!-- <input v-model="familymember.relation" > -->
                    <select v-model="familymember.relation" @change="clearN('relation')">
                        <option v-for="(rel,j) in ListRel" :key="j"  :value="rel.id">{{ rel.name}}</option>
                    </select>
                    <span class="error" v-if="errors.relation">{{ errors.relation }}</span>
                </div>
                
            </div>
            <div class="fields-fam">
                <div>
                    <label>Gender<b>*</b></label>
                    <select v-model="familymember.gender" @change="clearN('gender')">
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="3">Others</option>
                    </select>
                    <span class="error" v-if="errors.gender">{{ errors.gender }}</span>
                </div>
                <div>
                    <label>Blood Group<b>*</b></label>
                    <!-- <input placeholder="" v-model="familymember.bloodg"> -->
                    <select  v-model="familymember.bloodg" @change="clearN('blood')">
                        <option v-for="(group,j) in bloodGroup" :key="j"  :value="group.id">{{ group.blood_group_name }}</option>
                    </select>
                    <span class="error" v-if="errors.blood">{{ errors.blood }}</span>
                </div>
                <div>
                    <label>DOB<b>*</b></label>
                    <input type="date" v-model="familymember.dob" :max="currentDate" @blur="checkDate" @focus="clearN('dob')">
                    <span v-if="errors.dob">{{ errors.dob }}</span>
                </div>
            </div>
            <div class="fields-fam no-full_width">
                <div>
                    <label>Address</label>
                    <textarea v-model="familymember.address"></textarea>
                </div>
            </div>
            <div class="fields-fam adrs-fm change_password_fm ">
                <button @click="updateFam()">Update</button>                
                <span class="loader" v-if="loader && Error==''"></span>
                <!-- <div class="error" v-if="errors.all">{{ errors.all }}</div> -->
                <div class="error" v-if="Error">{{ Error.Message }}</div>

            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "EditFamily",
    data() {

return {

    familymember:
        {
            name: '',
            PH:'',
            email:'',
            gender:'',
            bloodg:'',
            address:'',
            relation: '',
            dob:'',
            id:'',
            prevUrl:''
            // image:''
        },
        errors:{
                mail:'',
                ph:'',
                all:'',
                dob:'',
                relation:'',
                gender:'',
                blood:'',
                name:''
            },
        loader:false,
        currentDate: new Date().toISOString().split('T')[0],
        onld:'',
        // otp: {
        //     mob:'',
        //     id:''
        // }
        otpMOB:'',
        prevRoute: null,
        ur:''
       
}

},
props: {
    famID:{
            type: String,
            required: true
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
computed:{
        ...mapGetters({
        singleDet:"familyModule/singleItem",
        bloodGroup:"searchModule/Bloodlist",
        ListRel:"familyModule/ListRelation",
        Error:"familyModule/errorUpdt"
        }),
        update(){
            return this.singleDet;
        }
    },
    watch: {
        update: {
        deep: true,
        handler() {
            this.changeVal();
        },
        },
        
        
    },
    created(){
        this.getSingleFam(),
        this.getBloodgroups();
    

    },
methods: {
    updateFam(){
            this.loader = true;
            

                if (this.familymember.PH  && this.familymember.bloodg && this.familymember.dob && 
                this.familymember.email && this.familymember.gender && this.familymember.name && this.familymember.relation) {
                    this.errors.all = '';
                    this.loader = true;
                    // if (/^\d{4}-\d{2}-\d{2}$/.test(this.familymember.dob)) {
                    //     const [year, month, day] = this.familymember.dob.split("-");
                    //     this.familymember.dob = `${day}-${month}-${year}`;
                    // }
                    // this.ur=this.prevRoute.name
                    if(this.prevRoute.name=='booknow') {
                        this.familymember.prevUrl= this.prevRoute.name
                        this.$store.dispatch('familyModule/updateFamily', this.familymember);
                    }
                    else {
                        this.familymember.prevUrl= '' ;
                        this.$store.dispatch('familyModule/updateFamily', this.familymember);
                    }
                    
                } else {
                    this.loader = false;
                    this.errors.all = 'All fields are mandatory';
                    if (this.familymember.name === '') {
                        this.errors.name = 'Full name required';
                    }
                    if (this.familymember.PH === '') {
                        this.errors.ph = 'Mobile Number required';
                    }
                    if (this.familymember.email === '') {
                        this.errors.mail = 'Email required';
                    }
                    if (this.familymember.gender === '') {
                        this.errors.gender = 'Gender required';
                    }
                    if (this.familymember.bloodg === '') {
                        this.errors.blood = 'Blood Group required';
                    }
                  
                    if (this.familymember.relation === '') {
                        this.errors.relation = 'Relation required';
                    }
                    if (this.familymember.dob === '') {
                        this.errors.dob = 'Date of Birth required';
                    }
                }
        },
        validatePh() {
            if (!/^(\+\d{1,3}[- ]?)?\d{10}$/i.test(this.familymember.PH)) {
                this.errors.ph="Please enter valid mobile number"
                
            }
            else {
                this.errors.ph=""
                
            }  
        },
        filterNonNumeric() {
					this.familymember.PH = this.familymember.PH.replace(/[^0-9]/g, "");
                    this.errors.ph=''
                    this.onld=''
		},
        validateEmail() {
            // eslint-disable-next-line
            if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/i.test(this.familymember.email) && this.familymember.email !='') {
        
                this.errors.mail="Please enter a valid email"
                }
                else {
                    this.errors.mail=""

            }    
        },
        clearM(){
            this.errors.all=''
        },
        getSingleFam(){
            this.$store.dispatch('familyModule/singleMember',this.famID);
        },
        changeVal(){
            this.familymember.PH=this.singleDet.mobile;
            this.familymember.address=this.singleDet.address;
            this.familymember.bloodg=this.singleDet.blood_group.id;
            this.familymember.dob=this.singleDet.dob;
            this.familymember.email=this.singleDet.email;
            
            this.familymember.name=this.singleDet.name;
            this.familymember.relation=this.singleDet.relation.id;
            this.familymember.id=this.singleDet.id;
            if (/^\d{2}-\d{2}-\d{4}$/.test(this.singleDet.dob)) {
                const [day, month, year] = this.singleDet.dob.split("-");
                this.familymember.dob = `${year}-${month}-${day}`;
            }
            this.familymember.gender=this.singleDet.gender.id;

            this.otpMOB=this.singleDet.mobile
            // this.otp.id=this.singleDet.id

        
        },
        getBloodgroups(){
                this.$store.dispatch("searchModule/getBloodgroups")
                this.$store.dispatch('familyModule/getRelations');
            },

        verifyFam(){
            this.$store.dispatch('familyModule/vefifyFamily', this.otpMOB);
            
        },
        checkDate() {
                const inputDate = new Date(this.familymember.dob);
                const today = new Date();
                const day = String(today.getDate()).padStart(2, '0');
                const month = String(today.getMonth() + 1).padStart(2, '0'); // Note that months are zero-based
                const year = today.getFullYear();
                const x = `${day}-${month}-${year}`;

                if (inputDate <= today) {
                    this.errors.dob=''
                } else {
                    this.errors.dob =  'The selected date should be '+ x +' or earlier';
                    this.familymember.dob=''
                }
        },
        clearN(e){
            this.errors[e]=''
        }
    }
    
   
    
    
}
</script>
<style>
/* .no-full_width{
    width: 50% !important;
} */
</style>