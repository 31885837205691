import ApiService from '../api.service'
// import axios from 'axios';

class bookingService {
    constructor() {
        this.request = ApiService;
    }

    
    
    getBookingHistory=(bearerToken,pageid) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/booking/history?page='+pageid,{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
           //console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    getBookings=(bearerToken,pageid) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/bookings/list?page='+pageid,{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
           //console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    getBookingDetails=(bearerToken,id) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/booking/'+ id +'/view',{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
           //console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    getSlots=(bearerToken,dateid) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/available-timeslot?service_provider_id='+ dateid.Sid +'&date='+dateid.date,{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
             //console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    
    getSlotsByid=(bearerToken,passToTimeslot) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/timeslot/'+ passToTimeslot.TimeS +'/view?date='+ passToTimeslot.Date,{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
             //console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    
    CreateBooking=(bearerToken,info) => {
        //  axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
          return new Promise((resolve, reject) => {
              return this.request.post('/create/booking?service_provider_id='+info.serviceProvider+'&family_id='+ info.familyID +'&date='+ info.date+'&time_slot_id='+info.timeslotID,
              {},{},{
                  Authorization: 'Bearer ' + bearerToken,
                }).then((response) => {
                  resolve(response)
               console.log(response)
              }).catch((err) => {
                  reject(err)
              })
          });
      };
      ConfirmBooking=(bearerToken,info) => {
        //  axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
          return new Promise((resolve, reject) => {
              return this.request.post('/confirm/booking?service_provider_id='+info.serviceProvider+'&family_id='+ info.familyID +'&date='+ info.date+'&time_slot_id='+info.timeslotID,
              {},{},{
                  Authorization: 'Bearer ' + bearerToken,
                }).then((response) => {
                  resolve(response)
               console.log(response)
              }).catch((err) => {
                  reject(err)
              })
          });
      };
      cancelBooking=(bearerToken,id) => {
        //  axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        const formData = new FormData()
        formData.append('booking_id',id)

          return new Promise((resolve, reject) => {
              return this.request.post('/booking-cancel' , {}, formData, {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                  resolve(response)
               console.log(response)
              }).catch((err) => {
                  reject(err)
              })
          });
      };
      UpdateBooking=(bearerToken,info) => {
        //  axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;

          return new Promise((resolve, reject) => {
              return this.request.post('/booking/'+info.editID +'/update?family_id='+ info.familyID+'&date='+ info.date +'&city_id&location_id&time_slot_id='+ info.timeslotID,
              {},{},{
                  Authorization: 'Bearer ' + bearerToken,
                }).then((response) => {
                  resolve(response)
               console.log(response)
              }).catch((err) => {
                  reject(err)
              })
          });
      };

    
  
       
}

export default new bookingService();
