<template>
    <div class="booking_date_select" v-show="show">
        <div class="date_tab">
            <div class="tab-head_date">
                <div @click="tabs('today',formattedDate)" :class="{activeTab: selectedLink === 'today' }" >
                    Today
                    <span>{{formattedDate}}</span>
                    
                </div>
                <div @click="tabs('tomorrow',formattedTomorrowDate)" :class="{activeTab: selectedLink === 'tomorrow' }" >
                    Tomorrow
                    <span>{{formattedTomorrowDate}}</span>
                </div>
                <div @click="tabs('more',formattedDate)" :class="{activeTab: selectedLink === 'more' }" >
                    More Date
                </div>
            </div>
            <div class="tab-c_date">
                <div class="lrd" v-if="formattedListSlot.length==0">
                        <span class="loader" ></span>
                    </div>

                <div   :class="{activeTab: selectedLink === 'today'} " v-if="formattedListSlot.Status != 0">
                    

                    <div class="timeblock" v-for="(time,i) in formattedListSlot" :key="i" @click="setVal(time.id,time.day)"
                    :class="{ NoTime: time.remaining_count== '0' || time.is_available != 1, selectedTime: TimeSlot===time.id }" 
                     >

                     
                        <!-- <input type="radio" v-model="TimeSlot" name="Time" :Value="time.slot"> -->
                        <div>
                            {{ time.time_start_12hr }}-{{ time.time_end_12hr }}
                            <span v-if="time.is_available==1">{{ time.remaining_count }} Available</span>
                        </div>
                    </div>
                    

                </div>
                <div  :class="{activeTab: selectedLink === 'today'} " v-else>
                    <p class="No-time"> {{ formattedListSlot.Message }}</p>
                </div>
                <div  :class="{activeTab: selectedLink === 'tomorrow'} " v-if="formattedListSlot.Status != 0">
                    <div class="timeblock" v-for="(time,i) in formattedListSlot" :key="i" @click="setVal(time.id,time.day)"
                    :class="{ NoTime: time.remaining_count== '0' || time.is_available != 1, selectedTime: TimeSlot===time.id }" 
                     >
                        <!-- <input type="radio" v-model="TimeSlot" name="Time" :Value="time.slot"> -->
                        <div>
                            {{ time.time_start_12hr }}-{{ time.time_end_12hr }}
                            <span v-if="time.is_available==1">{{ time.remaining_count }} Available</span>
                        </div>
                    </div>
                </div>
                <div  :class="{activeTab: selectedLink === 'tomorrow'} " v-else>
                    <p class="No-time"> {{ formattedListSlot.Message }}</p>
                </div>

                <div class="moreDate" :class="{activeTab: selectedLink === 'more'} " v-if="!loader">
                    <label>Select Date</label>
                    <input type="date" v-model="Selectdate" @change="loadTime()" :min="minDate">
                    <label>Choose The Time Slot</label>
                    <select v-model="TimeSlot" @change="setVal(TimeSlot)" v-if="formattedListSlot.Status != 0">
                        <option v-for="(time, i) in formattedListSlot" :key="i" :value="time.id" :disabled="time.remaining_count === '0' || time.is_available !== 1">
                            {{ time.time_start_12hr }} - {{ time.time_end_12hr }}
                            <span v-if="time.remaining_count === '0' || time.is_available !== 1">(Not Available)</span>
                            <span v-else> ({{time.remaining_count}} Available)</span>
                        </option>
                    </select>
                    
                    <select v-model="TimeSlot" @change="setVal(TimeSlot,Selectdate)" v-else>
                        <option disabled>
                            {{ formattedListSlot.Message }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="buttons_boo" :class="{ 'nobordr': isClassAdded }">
            <button class="cancel_bkn" @click="close">Cancel</button>
            <button class="schedule_bkng" :class="{ disabled: !TimeSlot }" @click="checkSlt">
                <span  v-if="isEdit" @click="changeData">
                    Book Now
                </span>
                <router-link  :to="{name:'booknow',params:{PId:getslot.Sid }}"  v-else>
                    Book Now
                </router-link>
                
                <!-- <span v-if="TimeSlot=='notime'"></span> -->
            </button>
            
        </div>
        <span class="error ter" v-if="serror">{{ serror }}</span>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "CalenderPopup",
    data(){

        return {
            selectedLink:'',
            TimeSlot:'',
            Selectdate: '',
            minDate:'',
            // tomorrow:'',
            day:'',
            getslot:{
                Sid:'',
                date:''
            },
            serror:'',
            isClassAdded:false
            
            
        }
    },
    props: {
        id: {
            type: String
        },
        show: Boolean,
        isEdit:Boolean
    },
    computed: {
        ...mapGetters({
        ListSlot:"bookingModule/slotList",
        noslotMessage: "bookingModule/noslotMessage",
        }),
        formattedDate() {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
        const year = today.getFullYear();
            // this.today=`${day}-${month}-${year}`;
        return `${day}-${month}-${year}`;
        },
        formattedTomorrowDate() {
        const today = new Date();
        today.setDate(today.getDate() + 1); // Increment the date by 1 to get tomorrow's date

        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
        const year = today.getFullYear();

        return `${day}-${month}-${year}`;
        },

        formattedListSlot() {
          if(this.ListSlot.length != 0 && this.ListSlot)   {
            return this.ListSlot.map(slot => {
                const formattedSlot = { ...slot };

                formattedSlot.time_start_12hr = this.formatTime12Hr(slot.time_start);
                formattedSlot.time_end_12hr = this.formatTime12Hr(slot.time_end);
                
                // Include other properties here, if any
                // formattedSlot.other_property = ...

                return formattedSlot;
            });
        }
        else {
            return this.noslotMessage
        }
        },
    },
    watch: {
        formattedListSlot: {
        deep: true,
        // handler() {
        //     this.getday();
        // },
        },
        
        // Selectdate(newval){

        // }
    },
    created() {
        //alert("test");
        
        // this.tabs('today',this.formattedDate);
        if(this.ListSlot) {
            this.$store.commit('bookingModule/clearSlot');
        }
        if(this.$route.query.Id){
            this.getslot.Sid=this.$route.query.Id
        } 
        if(this.id){
            this.getslot.Sid=this.id
        }
    },
    mounted() {
    // alert("test");
    if(this.ListSlot) {
            this.$store.commit('bookingModule/clearSlot');
        }
    this.tabs('today',this.formattedDate);
    if(this.$route.query.Id){
            this.getslot.Sid=this.$route.query.Id
        } 
        if(this.id){
            this.getslot.Sid=this.id
        }

        // sessionStorage.setItem('slotId', '');
        //     sessionStorage.setItem('slotDate','');
        //     sessionStorage.setItem('pid','');
    },
    methods: {
        tabs(type,date,id) {
            
       this.$store.commit('bookingModule/clearSlot');
        
        this.selectedLink = type;
        // this.$store.commit('bookingModule/clearSlot');
        //   this.selectedLink2 = type;
        //   this.tabc(type);
        if(id){
           this.Sid=id 
        }
        this.getslot.date=date;
        // console.log(this.getslot.date)
        // console.log(this.getslot.Sid)
        if(type == 'more') {
            this.isClassAdded=true
            // this.$store.commit('bookingModule/clearSlot');
            
            if(this.isEdit){
                //alert(sessionStorage.getItem('slotDate'))
                this.Selectdate= sessionStorage.getItem('slotDate')
                const [day, month, year] = this.formattedDate.split("-");
                this.minDate = `${year}-${month}-${day}`;

                const [day1, month1, year1] = this.Selectdate.split("-");
                this.Selectdate = `${year1}-${month1}-${day1}`;
            }
            else {
            this.Selectdate = this.formattedDate
            const [day, month, year] = this.Selectdate.split("-");
            this.Selectdate = `${year}-${month}-${day}`;
            this.minDate = `${year}-${month}-${day}`;
            }
            
        }
        

        this.$store.dispatch("bookingModule/getTimeSlots", this.getslot)
        
            
        },
        loadTime(){
            // alert("tes")
        //     alert(this.getslot.date)
        // alert(this.getslot.Sid)
        this.$store.commit('bookingModule/clearSlot');
        const [year, month, day] = this.Selectdate.split("-");
        this.getslot.date= `${day}-${month}-${year}`;
       // alert(this.getslot.date)
            this.$store.dispatch("bookingModule/getTimeSlots", this.getslot)
        },
        close(){
            this.$emit('clear');
            this.TimeSlot=''
            if(!this.isEdit){
                sessionStorage.removeItem('slotId');
                sessionStorage.removeItem('slotDate');
                sessionStorage.removeItem('pid');
            }
        },
        setVal(val,day){
            this.TimeSlot=val
            this.serror=''
            if (day === undefined) {
                //alert("t")
                const foundSlot = this.formattedListSlot.find(slot => slot.id === val);
                if (foundSlot) {
                    this.day = foundSlot.day;
                }
            } else {
                this.day = day; // Use the provided 'day' argument
            }
                
            sessionStorage.setItem('slotId', val);
            sessionStorage.setItem('slotDate',this.getslot.date);
            sessionStorage.setItem('pid',this.getslot.Sid);
            

        },
        formatTime12Hr(timeStr) {
            const [hour, minute] = timeStr.split(":");
            const hourInt = parseInt(hour, 10);

            const amPm = hourInt >= 12 ? "PM" : "AM";

            let formattedHour = (hourInt % 12).toString();
            if (formattedHour === "0") {
                formattedHour = "12"; // Handle midnight (00:00:00) as 12:00 AM
            }

            return `${formattedHour}:${minute}${amPm}`;
            },
            // getday(){
            //     this.day = this.formattedListSlot.day
            // },
            changeData(){
                if(this.TimeSlot && this.Selectdate){
                    sessionStorage.setItem('slotId', this.TimeSlot);
                    sessionStorage.setItem('slotDate', this.getslot.date);
                    sessionStorage.setItem('pid',this.getslot.Sid);
                    this.$emit('refreshData',{
                    date: this.getslot.date,
                    time:this.TimeSlot,
                    day:this.day
            });
                }
            },
            checkSlt(){
                if(this.TimeSlot==''){
                    this.serror="Kindly choose a timeslot to proceed"
                }
            }

    }
    
}
</script>