<template>
    <div class="list_page_layout">
        <AppHeaderVue></AppHeaderVue>
            <div class="wrapper">
                <h2 class="space-h2">News & Events</h2>
                 <div class="center-loader"   v-if="info.length==0">
                    <span class="loader" ></span>
                </div>
                <div class="newsList" v-else>                       
                    <DetailNews v-for="(news,i) in info" :key="i" :news="news" ></DetailNews>
                </div>
            </div>
        <AppFooter></AppFooter>
        
    </div>
</template>

<script>
import DetailNews from '@/components/Home/DetailNews.vue';
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
import {mapGetters} from "vuex";
export default {
    name: "NewsnEvents",
    components: {
        AppHeaderVue,
        AppFooter,
        DetailNews
    },
    computed:{
    ...mapGetters({
        info:"pagesModule/news",
        
        
    }),
    

    },
    created(){
        this.loadInfo()
    },
    methods:{
        loadInfo() {
            this.$store.dispatch("pagesModule/getNewsInfo");
        }
    }


    
}
</script>
<style>

</style>