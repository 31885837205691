<template>
    <div class="inner_page_layout common_page">
        <AppHeaderVue></AppHeaderVue>

        <div class="layout-pg">
            <div class="wrapper">
                
                <div class="contact_form">
                    <div class="sontact_info">
                        <img src="@/assets/images/contact.png" class="pos-im-co">
                        <div class="box_x">
                            <h3>Contact Us</h3>
                            <div class="cbox_s">
                                <font-awesome-icon icon="fa-solid fa-phone" />
                                <a :href="'tel:' + info.mobile">{{ info.mobile }}</a>
                            </div>
                            <div class="cbox_s">
                                <font-awesome-icon icon="fa-solid fa-envelope" />
                                <a :href="'mailto:' + info.email">{{ info.email }}</a>
                            </div>


                            <div class="social_c">
                                <a :href="info.facebook_link" target="_blank"><font-awesome-icon icon="fa-brands fa-facebook-f" /></a>
                                <a :href="info.instagram_link" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" /></a>
                                <a :href="info.twitter_link" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a>
                                <a :href="'https://wa.me/'+ info.whatsapp_number " target="_blank"><font-awesome-icon icon="fa-brands fa-whatsapp" /></a>
                            </div>
                        </div>
                    </div>
                   <div class="cfo">
                        <label>Name
                            <input v-model="formInputs.name" @focus="clerError('name')">
                            <span class="error">{{ errorMain.name }}</span>
                        </label>
                        <label>Email
                            <input v-model="formInputs.email" @blur="validateEmail" @focus="clerError('email')">
                            <span class="error">{{ error }}</span>
                            <span class="error">{{ errorMain.email }}</span>
                        </label>
                        <label>Subject
                            <input v-model="formInputs.subject" @focus="clerError('subject')">
                            <span class="error">{{ errorMain.subject }}</span>
                        </label>
                        <label>Message
                            <textarea v-model="formInputs.Message" @focus="clerError('Message')"></textarea>
                            <span class="error">{{ errorMain.Message }}</span>
                        </label>
                        <button class="sumbit_c_form" @click="submitQuery">Send</button>
                        <span class="loader" v-if="loader && error=='' && message==''"></span>
                        <span class="enq-msg">{{ message.Message }}</span>
                   </div>
                </div>

            </div>
             
        </div>

        <AppFooter></AppFooter>
        
    </div>
</template>

<script>
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
import {mapGetters} from "vuex";
export default {
    name: "ContactUs",
    components: {
    AppHeaderVue,
    AppFooter,
    
    },
    data(){
        return{
            formInputs:{
                name:'',
                email:'',
                subject:'',
                Message:''
            },
            error:'',
            flag:'',
            loader:false,
            errorMain:{
                name:'',
                email:'',
                subject:'',
                Message:''
            }
        }
    },
    computed:{
    ...mapGetters({
        info:"pagesModule/contact",
        message:"pagesModule/SuccessEnquiry",        
    }),
    },
    watch: {
       
        message(){
            this.formInputs.name=''
            this.formInputs.email=''
            this.formInputs.subject=''
            this.formInputs.Message=''
        }
    },
    created(){
        this.loadInfo()
    },
    methods:{
        loadInfo() {
            this.$store.dispatch("pagesModule/getContactInfo");
        },
        validateEmail() {
            // eslint-disable-next-line
            if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/i.test(this.formInputs.email) && this.formInputs.email != '') {
            
            this.error="Please enter a valid email"
            }
            else {
                this.error=""
                this.flag=1;

            }     
        },
        submitQuery(){
            
            if (Object.values(this.formInputs).some(field => field !== '') && this.flag==1) {
                this.loader=true
                this.errorMain=''
                this.$store.dispatch("pagesModule/enquiryForm",this.formInputs);
            }
            else{
                //this.errorMain="Please fill all the fields"
                for (const key in this.formInputs) {
               if (!this.formInputs[key]) {
                   this.errorMain[key] = "This field is required.";
               }
            }
            }
        },
        clerError(e){
            this.errorMain[e] =''
        }
    }
}
</script>
