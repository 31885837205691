import {get, post, put, patch, deleteApi} from './axios.service'

class ApiService {
    constructor() {
    }

    request = (method, url, data = {}, config = {}) => {
        return ({method, url, data, config});
    };

    get = (url, params, headers = {}) => {
        return get(url, params, headers)
    };

    post = (url, params, data, config = {}) => {
        return post(url, {}, data, config);
    };

    put = (url, data, config = {}) => {
        return put(url, data, config);
    };

    patch = (url, data, config = {}) => {
        return patch(url, data, config);
    };

    delete = (url, data, config = {}) => {
        return deleteApi(url, data, config);
    };

}

export default new ApiService();
