import ApiService from '../api.service'
// import axios from 'axios';

class accountService {
    constructor() {
        this.request = ApiService;
    }

    
    
    getDetails=(bearerToken) => {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/my-account',{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
               // console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };

    setDetails=(bearerToken,profile) => {
        const formData = new FormData();
        formData.append('image', profile.imageFile);

        formData.append('name', profile.name);
        formData.append('mobile', profile.mob);
        formData.append('email', profile.mail);
        formData.append('gender', profile.gender);
        formData.append('country_id', profile.country);
        formData.append('city_id', profile.city);
        formData.append('location_id', profile.location);
        formData.append('house_number', profile.housenumber);
        formData.append('street', profile.street);
        formData.append('pin', profile.pincode);
        formData.append('blood_group', profile.bloodgroup);
        formData.append('dob', profile.dob);
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        
     


        // console.log("fmmm"+formData)
        // if (formData.has('image')) {
        //     const imageFile = formData.get('image');
        //     console.log('Image file:', imageFile);
        //   }
        return new Promise((resolve, reject) => {
            return this.request. post('/update/my-account', {}, formData, {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + bearerToken,
              })
                .then((response) => {
                  resolve(response);
                  // console.log(response);
                })
                .catch((err) => {
                  reject(err);
                });
            });
    };

    logout=(bearerToken) => {
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/logout',{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
              // console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    notifications=(bearerToken,id) => {
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/notification/list?page='+id,{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
  
    
    
}

export default new accountService();
