
<template>
    <div class="box-news"> 
            <router-link
                        :to="{ name: 'singlenews', params: { newstitle: news.title }, query: { Id: news.id } }"
                        >
            <img :src="news.image_url">
            <div class="news_d">
                <h3>{{ news.title }}</h3>
                <div class="auth-d"><span>{{ formattedDate }}</span><span>{{ news.Author }}</span></div>
                <p>{{ news.description }}</p>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "DetailNews",
    props: {
        news: {
        type: Array,
        required: true
        },
    },

computed: {
    formattedDate() {
      // Parse the original date string to a Date object
      const date = new Date(this.news.date);

      // Format the date as DD-MM-YYYY
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
  },
    
}
</script>