<template>
    <div id="Footer">
        <div class="footer-main"> 
            <img alt="Vue logo" src="@/assets/images/footer.png">   
            <!-- <svg width="1440" height="671" viewBox="0 0 1440 671" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M-22.8687 153.369C44.6167 -199.585 493.179 173.775 820.038 144.72C1110.76 118.877 1583.79 271.016 1718.02 536.56C1272.02 1031.9 1810.38 1149.65 1529.52 1383.42C1273.01 1596.91 406.353 983.622 125.353 815.473C-149.079 651.255 -84.9811 478.222 -22.8687 153.369Z" fill="url(#paint0_linear_462_333)"/>
                <defs>
                <linearGradient id="paint0_linear_462_333" x1="1375.33" y1="183.547" x2="1233.45" y2="1084.67" gradientUnits="userSpaceOnUse">
                <stop stop-color="#C70419"/>
                <stop offset="0.734126" stop-color="#C70419" stop-opacity="0.8"/>
                </linearGradient>
                </defs>
            </svg> -->




            <div class="footer-menu wrapper">
                <div class="menu-f ">
                    <FooterMenu :title="Title1" :menu="footermenu1"></FooterMenu>
                    <FooterMenu :title="Title2" :menu="footermenu2"></FooterMenu>
                </div>
                <div class="mail-s">
                    <div class="menu_footer_block"> 
                        <h3>Stay Always In Touch</h3>
                        <div class="sub_sc">
                            <input placeholder=" Enter your email address" v-model="mil">
                            <img src="@/assets/images/sendar.png" @click="subscribe">
                        </div>
                        <div class="social">
                            <a :href="footerI.facebook_link" target="_blank"><font-awesome-icon icon="fa-brands fa-facebook-f" /></a>
                            <a :href="footerI.instagram_link" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" /></a>
                            <a :href="footerI.twitter_link" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a>
                            <a :href="'https://wa.me/'+ footerI.whatsapp_number " target="_blank"><font-awesome-icon icon="fa-brands fa-whatsapp" /></a>
                        </div>
                        
                        <div class="store-ply">
                            <a :href="footerI.play_store" target="_blank">
                                <img src="@/assets/images/googleply.png" class="gplay">
                            </a>
                            <a :href="footerI.app_store" target="_blank">
                                <img src="@/assets/images/store.png" class="gplay"> 
                            </a>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-bt">
            Copyright © 2023 ZimpleQ All rights reserved
        </div>
    </div>
</template>

<script>
import FooterMenu from '@/components/shared/FooterMenu.vue'
import {mapGetters} from "vuex";
export default {
    name: "AppFooter",
    components: {
        FooterMenu
    },

    data() {
        return {
            Title1: 'Company',
            Title2: 'Customer Policies',
            footermenu1: [
                {title: 'About Us', link:'about'},
                {title: 'Careers', link:'careers'},
                {title: 'Book Now', link:'services'},
                {title: 'Contact Us', link:'contact'}
            ],
            footermenu2: [
                {title: 'Privacy Policy', link:'privcypolicy'},
                {title: 'Terms & Conditions', link:'privcypolicy'}
            ],
            mil:''
        }
    },
    computed:{
    ...mapGetters({
        footerI:"pagesModule/contact", 
        newsL:"pagesModule/newsletter",       
    }),
    },
    created() {
        this.loadInfo()            
        },
    methods: {
        loadInfo() {
            this.$store.dispatch("pagesModule/getContactInfo");
        },
        subscribe(){
            this.$store.dispatch("pagesModule/NewsLetter",this.mil);
        }
    }
    
}
</script>