
<template>
    <div class="History"> 
        <h2 class="my_title_d">History</h2>
        <div class="center-loader" v-if="listHistory.length==0">
                <span class="loader" ></span>
            </div>
            <div class="center-loader" v-else-if="listHistory.data.length==0 ">
                <p>No History <br>
                
                </p>
            </div>
        <div class="History_list" v-else>             
            <HistoryBlock v-for="(history,i) in listHistory.data" :key="i" :history="history"></HistoryBlock>            
        </div>
        <div class="pagination booking" v-if="pageValues.length > 3">
                <ul>
                    <li v-for="(pages,i) in pageValues" :key="i"  :class="{ activePage: pages.active, disabled: pages.page==null  } "   @click="getHistory(pages.page)">
                        <span v-if="pages.label== '&laquo; Previous'">Previous</span> 
                        <span v-else-if="pages.label=='Next &raquo;'">Next</span> 
                        <span v-else>{{ pages.label }}</span> 
                    </li>
                </ul>
            </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import HistoryBlock from './HistoryBlock.vue';
export default {
    name: "BookingHistory",
    components: {
        HistoryBlock
    },  
    
    data(){
        return {
            loder1:false
        }
    },
    computed:{
        ...mapGetters({
        listHistory:"bookingModule/histories",
        
        }),
        pageValues() {
            const extractedValues = this.listHistory.links;
            const result = [];
                if(extractedValues){
            for (const link of extractedValues) {
                if (link.url) {
                const url = new URL(link.url);
                const page = url.searchParams.get("page");
                result.push({
                    ...link,
                    page: page
                });
                } else {
                result.push({
                    ...link,
                    page: null
                });
                }
            }
        }

        return result;
        }
    
    },
    // watch: {
    //     listHistory() {
    //         this.loader1=false            
    //     }
    // },
    created() {
        // this.loder1=true
        this.getHistory()
    },
    mounted(){
       
    },
    methods: {
        getHistory(pageid){
            this.$store.dispatch("bookingModule/getHistory",pageid)
        }
    }
}
</script>