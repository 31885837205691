<template>
    <div class="full_fm fam_list">
        <div class="updateSuccess  popup-login" v-if="this.successM">
            <div>
            <div class="checkmark-circle">
                  <div class="background"></div>
                  <div class="checkmark draw"></div>
                </div>
                <h3 style="display: block;    width: 100%;"> {{ this.successM }} </h3>
            </div>
        </div>
        <div class="updateSuccess  popup-login" v-if="this.verifySuccess.length !=0">
            <div>
            <div class="checkmark-circle">
                  <div class="background"></div>
                  <div class="checkmark draw"></div>
                </div>
                <h3 style="display: block;    width: 100%;"> {{ this.verifySuccess.Message }}</h3>
            </div>
        </div>
        
        <div class="updateSuccess popup-login" v-if="this.deleteM" >
            <div>
            <div class="checkmark-circle">
                  <div class="background"></div>
                  <div class="checkmark draw"></div>
                </div>
                <h3 style="display: block;    width: 100%;">{{ this.deleteM }}</h3>
            </div>
        </div>
        <div class="updateSuccess popup-login" v-if="updateM">
            <div>
            <div class="checkmark-circle">
                  <div class="background"></div>
                  <div class="checkmark draw"></div>
            </div>
                <span style="display: block; color:black;   width: 100%;">{{ this.updateM }} </span>
            </div>
        </div>
        <h2 class="my_title_d" style="text-align: left;"> My Family</h2>
        <div class="center-loader" v-if="List.length==0">
            <span class="loader" ></span>
        </div>
        <div class="center-loader" v-else-if="List.data.length==0 " >
                <p>No Family Members Added Yet <br>
                
                </p>
            </div>
        <div class="family_area" v-else>
            <FamilyBlock v-for="(family,index) in List.data" :key="index" :family="family"></FamilyBlock>
        </div>

        <div class="pagination booking"  v-if="pageValues.length > 3">
                <ul>
                    <li v-for="(pages,i) in pageValues" :key="i"  :class="{ activePage: pages.active, disabled: pages.page==null  } "   @click="getfamilies(pages.page)">
                        <span v-if="pages.label== '&laquo; Previous'">Previous</span> 
                        <span v-else-if="pages.label=='Next &raquo;'">Next</span> 
                        <span v-else>{{ pages.label }}</span> 
                    </li>
                </ul>
            </div>
        <button><router-link :to="{name:'newfamily'}">Add Member</router-link></button>


        
    </div>
</template>





<script>
import FamilyBlock from './FamilyBlock.vue';
import {mapGetters} from "vuex";
export default {
    name: "MyFamily",
    components: {
        FamilyBlock
    },
   
   created() {
        this.getfamilies(),
        this.messageHide();
    },
    computed:{
        ...mapGetters({
        List:"familyModule/families",
        successM:"familyModule/successMessage",
        deleteM:"familyModule/deleteMessage",
        updateM:"familyModule/singleSuccessM",
        verifySuccess:"familyModule/verified",
        
        
        }),
        loadAgain() {
        return this.deleteM;
        },
        pageValues() {
            const extractedValues = this.List.links;
            const result = [];
                if(extractedValues){
            for (const link of extractedValues) {
                if (link.url) {
                const url = new URL(link.url);
                const page = url.searchParams.get("page");
                result.push({
                    ...link,
                    page: page
                });
                } else {
                result.push({
                    ...link,
                    page: null
                });
                }
            }
        }

        return result;
        }
    },
    watch: {
        loadAgain: {
        deep: true,
        handler() {
            this.getfamilies();
            this.messageHide()
        },
        },
        List(){
            window.scrollTo({
                top: 0
            });
        }
        
        
        
    },
    
    methods: {
        getfamilies(page){
            this.$store.dispatch("familyModule/getFamily",page)
            
        },
        messageHide(){
            
            setTimeout(() => {
                //this.delayedValue = this.valueToDelay;
                this.$store.commit('familyModule/clearM');

            }, 2000);
        },
    }
}
</script>