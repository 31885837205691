<template>
    <div class="list_page_layout">
        <AppHeaderVue></AppHeaderVue>

        <div class="listt-sec">
            <div class="wrapper">
                <div class="breadcrumbs">
                    <!-- <div><a>Home</a>/<a>Services</a></div> -->

                    <!-- <div class="modify_search desk_top">                        
                        <FilterForm></FilterForm>                        
                        <button>Search</button>
                    </div> -->
                    <!-- <h2>Doctors</h2> -->
                </div>
                <div class="box_listing_main">
                    <div class="filter_button" @click="sort_bt()"  :class="{activeSort: selectedLink === 'activesort'}">
                        Sort & Filter
                    </div>
                    <div class="left-sidebar" :class="{activeSortBox: selectedLink === 'activesort'}" ref="sidebar">
                        <h2>Sorting & Filters</h2>

                        <!-- <div class="modify_search mobile_srch_s">                        
                            <div class="form-c">
                                <select>
                                    <option disabled selected>City</option>
                                    <option v-for="(city,j) in cities" :key="j" >{{ city.city_name }}</option>
                                </select>
                                <select>
                                    <option disabled selected>Location</option>
                                    <option>All</option>
                                    <option v-for="(city,j) in cities" :key="j" >{{ city.city_name }}</option>
                                </select>
                                <select>
                                    <option>Service</option>
                                </select>
                                <select>
                                    <option>Speciality</option>
                                </select>
                                <input type="text" placeholder="Appointment Date" onfocus="(this.type='date')">
                            </div>
                            <button>Search</button>
                        </div> -->
                        <!-- <div class="filter-box">
                            <input type="date">
                        </div> -->
                        <div class="filter-box">
                            <ul>
                                <!-- <li>
                                    <label><input type="radio" name="sort"   @change="changeSort">Popular</label>
                                </li> -->
                                <li>                                    
                                    <label><input type="radio" name="sort"  value="0" @change="changeSort">Old - New</label>
                                </li>
                                <li>                                    
                                    <label><input type="radio" name="sort"  value="1" @change="changeSort">New - Old</label>
                                </li>
                            </ul>
                        </div>
                        <div class="filter-box">
                            <h3>Categories</h3>
                            <ul>
                                <li  v-for="(main,j) in AllF.Data" :key="j" >
                                    <label>
                                        <input type="checkbox"  :value="main.id" name="main" @change="showSubmenu(main.id)">
                                        <span>{{main.category_name}}</span>
                                    </label>
                                    <ul v-if="isOpen(main.id)">
                                        <li v-for="(sub,i) in main.sub_categories" :key="i">
                                            <label>
                                                <input type="checkbox" :value="sub.id" :name="'sub'+ main.id"  @change="saveSub(sub.id)"><span>{{sub.category_name}}</span>
                                            </label>
                                        </li>
                                        <p>specialties</p>
                                        <li v-for="(sp,l) in main.speciality" :key="l">
                                            <label>
                                                <input type="checkbox" :value="sp.id" :name="'sp'+ main.id" @change="saveSpec(sp.id)"><span>{{sp.speciality_name}}</span>
                                            </label>
                                        </li>
                                    </ul>
                                </li>
                               
                            </ul>
                        </div>
                        <div class="filter-box" style="display: none;">
                            <h3>Gender</h3>
                            <ul>
                                <li><input type="checkbox" value="1" @change="saveGen(1)" name="gender"><span>Male</span></li>
                                <li><input type="checkbox" value="2" @change="saveGen(2)"  name="gender"><span>Female</span></li>
                                <li><input type="checkbox" value="3" @change="saveGen(3)"  name="gender"><span>Other</span></li>
                            </ul>
                        </div>
                        
                    </div>
                    <div class="list_view">
                        <router-view v-if="serviceproviders" :serviceproviderList="serviceproviders.data" > </router-view>
                        <h2 v-if="sorry" class="soryText">Sorry we couldn't find any matching results for your serach at the moment.!</h2>

                        <div class="pagination" v-if="serviceproviders.links.length > 3">
                            <ul>
                                <li v-for="(pages,i) in pageValues" :key="i"  :class="{ activePage: pages.active, disabled: pages.page==null  } "   @click="pagination(pages.page)">
                                   <span v-if="pages.label== '&laquo; Previous'">Previous</span> 
                                   <span v-else-if="pages.label=='Next &raquo;'">Next</span> 
                                   <span v-else>{{ pages.label }}</span> 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AppFooter></AppFooter>
        
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
// import FilterForm from "@/components/Services/FilterForm.vue"

import { RouterView } from 'vue-router';
export default {
    name: "ListinLayout",
        components: {
        AppHeaderVue,
        AppFooter,
        RouterView  ,
        // FilterForm  
    },
    data() {
        return {
            check:'',
            // serviceproviders:[],
                num:1,
                selectedLink:'',
                submenu:'',
                slectedFilters:[],
                searchFilters:{
                    city:'',
                    location:'',
                    category:'',
                    subcategory:'',
                    specialty:'',
                    date:'',
                    name:''
                },
                selectedFilters: {
                    categories: [],
                    subcat: [],
                    speciality:[],
                    gender:[],
                    sortby:''
                },
                //cities:[]
                sticky: 160,
                sorry:false
        }
        
    },
    computed:{
    ...mapGetters({
        serviceproviders:"providerModule/List",
        AllF:"searchModule/AllL",
        // subCAtLi:"searchModule/subcatList",
      
    }),
    // loaderfn() {
    //     if(this.serviceproviders) {
    //         return false;
    //     }
    //     return true;
    //   },
    // filteredItems() {
    //     const condition = this.selectedOption; // Change this to your desired condition
    //     return Object.values(this.locations).filter(item => item.city_id == condition);
    //   },
    //   filteredspecialities() {
    //     const condition = this.selectedv; // Change this to your desired condition
    //     return Object.values(this.specialtyList).filter(item => item.category_id == condition);
    //   }
    
    // extractPageValues() {
    //   for (const link of this.serviceproviders.links) {
    //     if (link.url) {
    //       const url = new URL(link.url);
    //       const page = url.searchParams.get("page");
    //       this.pageValues.push(page);
    //     }
    //   }

    pageValues() {
            const extractedValues = this.serviceproviders.links;
            const result = [];
                if(extractedValues){
            for (const link of extractedValues) {
                if (link.url) {
                const url = new URL(link.url);
                const page = url.searchParams.get("page");
                result.push({
                    ...link,
                    page: page
                });
                } else {
                result.push({
                    ...link,
                    page: null
                });
                }
            }
        }

        return result;
        }

    },
    watch: {
        serviceproviders(newValue) {
            // Update the timeslot prop passed to the child component
            //alert("t")
            this.serviceproviderList = newValue.data;
            if(newValue.data.length==0){
                this.sorry=true
            }
            else {
                this.sorry=false
            }
            window.scrollTo({
                top: 0
            });
            },

    },
    
    // beforeRouteLeave(to, from, next) {
    //     this.$store.commit('providerModule/clearList');
    //     next(); 
    // },
    created() {
        // this.$store.commit('providerModule/clearList');
        this.getproviders(this.searchFilters)
        this.getCategories()
        window.addEventListener('scroll', this.handleScroll);
        this.$store.commit('bookingModule/clearError');
    },
   
    methods: {
        getproviders(filters){
            // for (const key in filters) {
            //    if (filters[key]==undefined) {
            //     filters[key] = "";
            //    }
            // }
            
            this.$store.dispatch("providerModule/getproviders",filters)
        },
        sort_bt() {
            
            if(this.num > 1 ){
                this.selectedLink = '';
                this.num= 1;
            }
            else {
                this.selectedLink = 'activesort';
                this.num= this.num+1;
            }
            
        },
        pagination(num){
            if(num){
             this.$store.dispatch("providerModule/getpagination", num)
             
            }
        },


        getCategories() {
            this.$store.dispatch("searchModule/AllFilterCat")
        },
        showSubmenu(id) {
           // this.submenu = id;
            // const index = this.slectedFilters.findIndex(item => item.categoryid === id);
            this.$store.commit('providerModule/clearList');
            const index = this.selectedFilters.categories.findIndex(item => item === id);

            if (index === -1) {
                this.selectedFilters.categories.push(id);
            } else {
                this.selectedFilters.categories.splice(index, 1);
                const checkboxes = document.querySelectorAll('input[type="checkbox"][name*="sub' + id + '"]');

                // Loop through the selected checkboxes
                checkboxes.forEach(checkbox => {
                    const x = checkbox.value;
                    const subcatIndex = this.selectedFilters.subcat.findIndex(item => item == x);
                    if (subcatIndex != -1) {
                        this.selectedFilters.subcat.splice(subcatIndex, 1);
                    }
                });

            }
            //console.log(this.slectedFilters.categories)
            
        if(this.selectedFilters.length !=0){
            this.$store.dispatch("providerModule/loadFiltered", this.selectedFilters);
        }
        else {
            this.$store.dispatch("providerModule/getproviders")
        }
        },
        isOpen(id) {
            return this.selectedFilters.categories.includes(id);
        },
        saveSub(id){
            this.$store.commit('providerModule/clearList');
            const index = this.selectedFilters.subcat.findIndex(item => item == id);

            if (index === -1) {
                this.selectedFilters.subcat.push(id);
            } else {
                this.selectedFilters.subcat.splice(index, 1);
            }

            if(this.selectedFilters.length !=0){
                this.$store.dispatch("providerModule/loadFiltered", this.selectedFilters);
            }
            else {
                this.$store.dispatch("providerModule/getproviders")
            }
        },
        saveSpec(id){
            this.$store.commit('providerModule/clearList');
            const index = this.selectedFilters.speciality.findIndex(item => item == id);

            if (index === -1) {
                this.selectedFilters.speciality.push(id);
            } else {
                this.selectedFilters.speciality.splice(index, 1);
            }

            if(this.selectedFilters.length !=0){
                this.$store.dispatch("providerModule/loadFiltered", this.selectedFilters);
            }
            else {
                this.$store.dispatch("providerModule/getproviders")
            }
        },
        saveGen(id){
           
            this.$store.commit('providerModule/clearList');
            const index = this.selectedFilters.gender.findIndex(item => item == id);
            //alert(index)
            if (index === -1) {
                this.selectedFilters.gender.push(id);
            } else {
                this.selectedFilters.gender.splice(index, 1);
            }
             this.$store.dispatch("providerModule/loadFiltered", this.selectedFilters);
            
        },
        changeSort(event){
            this.$store.commit('providerModule/clearList');
            this.selectedFilters.sortby=event.target.value;
            this.$store.dispatch("providerModule/loadFiltered", this.selectedFilters);
        },
        handleScroll() {
            //alert("t")
            const header = this.$refs.sidebar;
            //alert(header)
            if (window.pageYOffset > this.sticky) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }
        },
      
}

    
}
</script>
<style>
/* .sticky {
  position: fixed;
  /* top: 0;
  left: 0;
  width: 100%; */
  /* top: 5px;
}  */
</style>