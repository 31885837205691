<template>
    <div class="inner_page_layout">
        <AppHeaderVue></AppHeaderVue>

        <div class="layout-sec">
            <div class="wrapper">
             <router-view></router-view>
            </div>
        </div>

        <AppFooter></AppFooter>
        
    </div>
</template>

<script>
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';

import { RouterView } from 'vue-router';
export default {
    name: "AppLayout",
    components: {
    AppHeaderVue,
    AppFooter,
    RouterView
    
}

    
}
</script>