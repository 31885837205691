<template>
    
    <div class="comment-block">
        
        <span class="quote quote-left"><font-awesome-icon icon="fa-solid fa-quote-left" /></span>
        <p>
            <img :src="comment.image_url" v-if="comment.image_url"> 
            {{ comment.description }}
        
        </p>
        <span>{{ comment.title }}</span>
        <!-- <span class="quote quote-right"><font-awesome-icon icon="fa-solid fa-quote-right" /></span> -->
                            
    </div>

    
</template>

<script>

export default {
    name: "SingleComment",
   
    props: {
        comment: {
            type: Object,
            required: true
        },
    },
    
}
</script>