<template>
    <div class="inner_page_layout common_page">
        <AppHeaderVue></AppHeaderVue>

        <div class="layout-pg">
             <!-- <div class="cp_banner">
                <img src="@/assets/images/colorbg.jpg">
                <div class="cover_bg">
                    <h2>About Us</h2>
                </div>
             </div> -->

             <div class="content_pg">
                <div class="wrapper">
                    <div class="abt_flex">
                        <div class="co_abt">
                            <h2>About Us</h2>
                            <p>
                               {{ info.description }}
                            </p>
                        </div>
                        <img :src="info.image_url">
                    </div>
                </div>

                <div class="counter">
                    <div class="wrapper">
                        <div class="flex_counter">
                            <div class="countre_box">
                                <span>{{info.services}} +</span>
                                <p>Services</p>
                            </div>
                            <div class="countre_box">
                                <span>{{info.service_providers}}+</span>
                                <p>Service Providers</p>
                            </div>
                            <div class="countre_box">
                                <span>{{info.customers}}+</span>
                                <p>Happy Customers</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="aims">
                    <div class="wrapper">
                        <div class="flex_counter">
                            <div class="aim_box">
                                <h3>Vision</h3>
                                <p>
                                 {{info.vision}}
                                </p>
                            </div>
                            <div class="aim_box">
                                <h3>Mission</h3>
                                <p>
                                  {{info.mission}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>

        <AppFooter></AppFooter>
        
    </div>
</template>

<script>
import AppHeaderVue from '@/components/shared/AppHeader.vue';
import AppFooter from '@/components/shared/AppFooter.vue';
import {mapGetters} from "vuex";
export default {
    name: "AboutUs",
    components: {
    AppHeaderVue,
    AppFooter,
    
    },
    computed:{
    ...mapGetters({
        info:"pagesModule/about",
        
        
    }),
    

    },
    created(){
        this.loadInfo()
    },
    methods:{
        loadInfo() {
            this.$store.dispatch("pagesModule/getAboutInfo");
        }
    }
}
</script>
<style>
.counter{
    background-image: url(@/assets/images/colorbg.jpg);
}
</style>