import ApiService from '../api.service'
import axios from 'axios';

class familyService {
    constructor() {
        this.request = ApiService;
    }

    
    
    getFamilyList=(page,bearerToken) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/family-members/list?page='+page,{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
            //    console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    getRelations=(bearerToken) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('/relations/list',{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
            //    console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    addFam=(bearerToken,familyDetails) => {
      //  axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.post('/family-member/add?name='+familyDetails.name +'&mobile='+familyDetails.PH +'&email='+familyDetails.email +'&relation='+ familyDetails.relation+'&gender='+ familyDetails.gender+'&blood_group='+familyDetails.bloodg+'&dob='+familyDetails.dob+'&address='+familyDetails.address,{},{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
       console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    deletFamily=(bearerToken,Id) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        
            return this.request.delete('/family-member/delete?id='+Id)
        
    };
    singleMember=(bearerToken,id) => {
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        return new Promise((resolve, reject) => {
            return this.request.get('https://hexprojects.in/Service-Provider-Customer/api/family-member/'+ id +'/view',{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
            //    console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    UpdateMember=(bearerToken,familyDetails) => {
        //  axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
        if (/^\d{4}-\d{2}-\d{2}$/.test(familyDetails.dob)) {
            const [year, month, day] = familyDetails.dob.split("-");
            familyDetails.dob = `${day}-${month}-${year}`;
        }
          return new Promise((resolve, reject) => {
              return this.request.post('/family-member/update?id='+ familyDetails.id +'&name='+familyDetails.name +'&mobile='+familyDetails.PH +'&email='+familyDetails.email +'&relation='+ familyDetails.relation+'&gender='+ familyDetails.gender+'&blood_group='+familyDetails.bloodg+'&dob='+familyDetails.dob+'&address='+familyDetails.address,{},{},{
                  Authorization: 'Bearer ' + bearerToken,
                }).then((response) => {
                  resolve(response)
               console.log(response)
              }).catch((err) => {
                  reject(err)
              })
          });
      };
      sentOTP=(bearerToken,mob) => {
       
          return new Promise((resolve, reject) => {
              return this.request.post('/family-member/send-otp?mobile_number='+mob,{},{},{
                  Authorization: 'Bearer ' + bearerToken,
                }).then((response) => {
                  resolve(response)
               console.log(response)
              }).catch((err) => {
                  reject(err)
              })
          });
      };
      verifyOTP=(bearerToken,otp) => {
       
        return new Promise((resolve, reject) => {
            return this.request.post('/family-member/verify?family_id='+ otp.id +'&otp='+ otp.code,{},{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
             console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
    resendOtp=(bearerToken,otp) => {
       
        return new Promise((resolve, reject) => {
            return this.request.post('/family-member/resend-otp?family_member_id='+ otp,{},{},{
                Authorization: 'Bearer ' + bearerToken,
              }).then((response) => {
                resolve(response)
             console.log(response)
            }).catch((err) => {
                reject(err)
            })
        });
    };
       
}

export default new familyService();
