
<template>
    <div class="fv-cov"> 
        <h2 class="my_title_d">Favourites</h2>
        
        <div class="center-loader" v-if="FavList.length==0">
            <span class="loader" ></span>
        </div>
        <div class="center-loader" v-else-if="FavList.data.length==0 " >
                <p>No Favourites <br>
                
                </p>
            </div>
        <div class="fav_list"> 
            
            <FavSingle v-for="(profile,index) in FavList.data" :key="index" :serviceprovider="profile"
            @passDisable="disableButon" :class="{ButtonAction: ClassDis== true && PassId != profile.service_provider.id }"
            >
                        </FavSingle> 
        </div>
        <div class="pagination booking"  v-if="pageValues.length > 3">
                <ul>
                    <li v-for="(pages,i) in pageValues" :key="i"  :class="{ activePage: pages.active, disabled: pages.page==null  } "   @click="loadFav(pages.page)">
                        <span v-if="pages.label== '&laquo; Previous'">Previous</span> 
                        <span v-else-if="pages.label=='Next &raquo;'">Next</span> 
                        <span v-else>{{ pages.label }}</span> 
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>
import FavSingle from './FavSingle.vue';
import {mapGetters} from "vuex";
export default {
    name: "FavList",
    components:{
        FavSingle
    },
    
    data(){
        return {
            ClassDis:'',
           PassId:''
        }
    },
    computed:{
        ...mapGetters({
            FavList:"providerModule/ListF",        
        }),
        pageValues() {
            const extractedValues = this.FavList.links;
            const result = [];
                if(extractedValues){
            for (const link of extractedValues) {
                if (link.url) {
                const url = new URL(link.url);
                const page = url.searchParams.get("page");
                result.push({
                    ...link,
                    page: page
                });
                } else {
                result.push({
                    ...link,
                    page: null
                });
                }
            }
        }

        return result;
        }
    },
    created(){
        this.loadFav()
    },
    methods:{
        loadFav(id){
            this.$store.dispatch("providerModule/loadFav",id)
        },
        disableButon(v){
            this.ClassDis=v.noClick
            this.PassId=v.id
        }
    }
}
</script>