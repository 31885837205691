<template>
    <div class="service_block"> 
        
        <div class="provider">
            <div class="s_img">
                <img :src="serviceprovider.service_provider_photo_url">
            </div>
            <div class="basic_info">
                <div>
                    <h2>{{ serviceprovider.service_provider_name }} 
                        <!-- <span class="spnsrd"  v-if="serviceprovider.sponsors.length > 0">Sponsored </span> -->
                    </h2>
                    <span  class="bs-in" >
                        {{ serviceprovider.category.category_name }}
                    </span>
                    <span  class="bs-in" >
                        {{ serviceprovider.specialty.speciality_name }}
                    </span>
                    <div class="qualifictn">{{ serviceprovider.service_provider_qualification }}</div>
                
                <!-- <div class="star-rate">
                    <span v-for="(n,index) in 5" :key="index" :class="n <= serviceprovider.StarRating ? 'yellow' : 'gray'">
                        <font-awesome-icon icon="fa-solid fa-star" />
                    </span>
                </div> -->
                </div>
                <div class="top_auto">
                    <div class="loc exp" style="display:flex">
                        <font-awesome-icon icon=" fa-solid fa-address-book" />
                        <p>Total Bookings: <b>{{ serviceprovider.total_bookings_count }}</b></p> 
                    </div>
                    <router-link :to="{name:'single_provider',params:{providername:serviceprovider.service_provider_name,  },query: { Id: serviceprovider.id  }}" >
                        <button class="view_profile">View Profile</button>
                    </router-link>
                </div>
            </div>
            <div class="booking_info_b">
                <div class="loc_info">
                    <div class="loc">
                        <font-awesome-icon icon="fa-solid fa-location-dot" />
                        <p>{{ serviceprovider.service_provider_address }}</p>
                    </div>
                    <!-- <div class="loc">
                        <font-awesome-icon icon="fa-solid fa-clock" />
                        <p>Available Date: {{ serviceprovider.AvailableTime }}</p>
                    </div> -->
                </div>
                <div class="top_auto">
                    <span class="fees_b"><b>Booking Fee : </b>{{ serviceprovider.booking_charge }} / Person</span>
                    
                    <button class="bookNow_b" @click="popup(serviceprovider.id)" >Book Now</button>
                    <div class="popup_date" :class="{showpopup: showpopup== true }"  >
                        <CalenderPopup ref="calendarPopup"  :id="serviceprovider.id" :show="showpopup" @clear="close(serviceprovider.id)"></CalenderPopup>
                    </div>
                </div>

                
                
            </div>
        </div>         
    </div>
</template>

<script>
import CalenderPopup from '../booking/CalenderPopup.vue';
export default {
    name: "FavSingle",
    components: {
        CalenderPopup
    },
    data(){
        return {
            showpopup:false
        }
    },
    props: {
        serviceprovider: {
            type: Object,
            required: true
        },
    },
    computed:{
        
            formattedDate() {
            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
            const year = today.getFullYear();
                // this.today=`${day}-${month}-${year}`;
            return `${day}-${month}-${year}`;
            },
            
            
    },
    methods: {
        popup(id) {
            this.showpopup =true
            this.$emit('passDisable',{
                noClick: true,
                id:id
            });
            this.$refs.calendarPopup.tabs('today',this.formattedDate);
        },
        close(id) {
            this.$emit('passDisable',{
                noClick: false,
                id:id
            });
            this.showpopup =''
        }
    }
    
}
</script>