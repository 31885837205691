<template>
    <div class="signin forgot"> 
        
          <div class=" authform">
            <div>
            <h2 class="my_title_d">Reset Password</h2>
            <div class="form" >
            <div class="lb_d1">
                <label >New Password </label>
                        <span style="position: relative;" class="eye">
                        <input :type="type" v-model="cred.newpass" @focus="reseterror()" @blur="validatepass()"  @keyup.enter="resetPassword" autocomplete="new-password">
                        <button class="tg-ps" @click="showPassword()" ><font-awesome-icon :icon="'fa-solid ' + btnText "/></button>
                </span>
                <span class="error" v-if="errorePH">{{ errorePH }}</span>
            </div>
            <div class="lb_d1">
                <label>Confirm Password <b style="color:red;">*</b></label>
                        <span style="position: relative;" class="eye">
                        <input :type="type1" v-model="cred.confirmnew" @focus="reseterror1()" @blur="validatecp()"  @keyup.enter="resetPassword">
                        <button class="tg-ps" @click="showPassword1()" ><font-awesome-icon :icon="'fa-solid ' + btnText1 "/></button>
                </span>
                <span class="error" v-if="errorsC">{{ errorsC }}</span>
            </div>
           
            <button @click="resetPassword">Submit</button>
            <span class="loader loadder-reset"  v-if="loader"></span>
            <div class="error" v-if="otperror.password" >{{ this.otperror.password }}</div>
            <!-- <div class="error" v-else-if="otperror.password" >{{ this.otperror.password }}</div> -->
            <div class="error" v-else >{{ this.otperror }}</div>
        </div>
          </div>
        
        </div>
        <img src="@/assets/images/pswd1.png" class="pos-image-auth">
    </div>
</template>

<script>
import {mapGetters} from "vuex"
export default {
    name: "ForgotReset",
    
   
    data(){
        return {
            cred:{
                newpass:'',
                confirmnew:'',
                uid: ''
            },
            type: 'password',
            btnText: 'fa-eye-slash',
            type1: 'password',
            btnText1: 'fa-eye-slash',
            error:'',
            errorePH:'',
            errorsC:'',
            flag:'',
            isValid:'',
            loader:false,
            

        }
    },
    computed:{
        ...mapGetters({
            otpdet:"ChangeModule/otpsend",
            respons:"ChangeModule/otpResetPge",
            otperror:"ChangeModule/error1",
            // otpmessage:"ChangeModule/messotp",
            
        }),
    },
    methods: {
        resetPassword(){
            
            if( this.cred.newpass !='' && this.cred.confirmnew !=''  && this.flag ==1) {
                this.loader=true
                this.cred.uid = this.otpdet.customer_id;
                this.$store.dispatch("ChangeModule/ForgotReset",this.cred);
            }
            else {
                
                this.error="All Field is mandatory"  
            }
            
        },
        showPassword() {
           // alert("t");
                if(this.type === 'password') {
                    this.type = 'text'
                    this.btnText = 'fa-eye'
                } else {
                    this.type = 'password'
                    this.btnText = 'fa-eye-slash'
                }
        },
        showPassword1() {
           // alert("t");
                if(this.type1 === 'password') {
                    this.type1 = 'text'
                    this.btnText1 = 'fa-eye'
                } else {
                    this.type1 = 'password'
                    this.btnText1 = 'fa-eye-slash'
                }
        },
        validatepass() {
                
            const passwordPattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(.{8,})$/;
            this.isValid = passwordPattern.test(this.cred.newpass);
            if(this.isValid) {
                this.errorePH=''
            }else {
                this.errorePH="Password must contain at least one number, one uppercase letter, one lowercase letter, one specialcharacter and minimum 8 characters"
            }
        },
        validatecp(){
            if(this.cred.newpass === this.cred.confirmnew){
                this.errorsC=""
                this.flag=1
            }
            else{
                this.errorsC="Password does not match"
                this.flag=''
            }
        },
        reseterror(){
                    this.error='';
                    this.errorePH='';
                    // this.otperror=""
                    
                },
        reseterror1(){
                    this.error='';
                    this.errorsC='';
                    // this.otperror=""
                    
                }
    },
    mounted() {
        this.otperror=""

    },
   
    
}
</script>