<template>
    <div class="signin"> 
        
        <div class="authform signin-form-lert">
            
            <div>
                <div class="vrfy-otp  success-popUpm popup-login" v-if="otpMessage.Status==1">
                    <div>
                    <div class="checkmark-circle">
                  <div class="background"></div>
                  <div class="checkmark draw"></div>
                </div>
                    <h3 class="alrt_scss">{{ otpMessage.Message }}  </h3>   
                </div>
            </div>
                <!-- v-if="this.changeMessage" -->
                <div class="vrfy-otp success-popUpm popup-login" v-if="this.changeMessage" >
                    <div>
                    <div class="checkmark-circle">
                  <div class="background"></div>
                  <div class="checkmark draw"></div>
                </div>
                    <h3 class="alrt_scss">{{ this.changeMessage }}</h3>
                </div>
                </div>
                <div class="vrfy-otp success-popUpm popup-login" v-if="this.successM">
                    <div>
                    <div class="checkmark-circle">
                  <div class="background"></div>
                  <div class="checkmark draw"></div>
                </div>
                    <h3 class="alrt_scss">{{ this.successM }}</h3>
                </div>
            </div>
                
                <AuthTitle :title="title"></AuthTitle>
                <div class="form" >
                    <label>Mobile Number
                        <div class="prefix signinPrefix " :class="{hideOnlod: onld == 'hideOnlod'}">
                            <span >+91</span>
                            <input v-model="login.username" @focus="reset('ph')" @input="filterNonNumeric()" @keyup.enter="loginUser" autocomplete="new-mobilenumber" @blur="checkP" maxlength="10">
                        </div>
                        <div class="error" v-if="ermsg1">{{ermsg1}}</div>
                    </label>
                    <label >Password
                        <span style="position: relative;" class="eye">
                        <input :type="type" v-model="login.password" @focus="reset('ps')" @keyup.enter="loginUser" autocomplete="new-password">
                        <button class="tg-ps" @click="showPassword()" ><font-awesome-icon :icon="'fa-solid ' + btnText "/></button>
                        </span>
                        <div class="error" v-if="ermsg2">{{ermsg2}}</div>
                    </label>
                    <span class="forfgotp">
                        <router-link :to="{name:'forgot'}">
                            Forgot Password?
                        </router-link>
                    </span>
                    <button @click="loginUser">Login</button>
                    <span class="loader" v-if="loader && user ==''"></span>
                    <div class="error" v-if="user.Message" >{{ user.Message }}</div>                    
                    
                </div>

                <div class="noac">
                    <p>Don't have an account? 
                        <router-link :to="{name:'signup'}">
                            Register Now
                        </router-link>
                    </p>
                </div>

            </div>
            
        </div>
        <img src="@/assets/images/pswd.png" class="pos-image-auth">
        <div class="auth-right">
            <RedirectSection :message="authmessage"></RedirectSection>
        </div>

    </div>
</template>

<script>
import RedirectSection from "@/components/Authentication/RedirectSection.vue";
import AuthTitle from '@/components/Authentication/AuthTitle.vue';
import {mapGetters} from "vuex";
// import { mapActions } from "vuex";
export default {
    name: "SignIn",
    components: {
        RedirectSection,
        AuthTitle
    },
    data(){
        return {
            title:'Login To Your Account ',
            authmessage:{

                title1: "New Here?",
                Para: "Sign up and discover more",
                Link: 'signup',
                button: 'Register'

            },
            login: {
                username:'',
                password:'',
                ro:"/"
            },
            error: '',
            type: 'password',
            btnText: 'fa-eye-slash',
            ermsg1:'',
            ermsg2:'',
            numericInput:'',
            loader:false,
            prevRoute: null,
            onld : 'hideOnlod'

        }
    },
    computed:{
    ...mapGetters({
        user:"userModule/getUser",
        Message: "userModule/loginMessage",
        otpMessage: "userRegister/regMessage",
        changeMessage:"ChangeModule/resetSuccess",
        successM: "ChangeModule/ChangeSuccess",
        
        
    }),
    

    },
    created(){
        setTimeout(() => {
                //this.delayedValue = this.valueToDelay;
                this.$store.commit('ChangeModule/resetState');

        }, 2500); 
    },
    beforeDestroy() {
        this.clearmessage()
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    methods: {
        loginUser() {
            if(this.login.username != '' && this.login.password !='') {
                this.loader=true
                if(this.prevRoute.fullPath=='/services/') {
                    this.login.ro = this.prevRoute.fullPath
                }
                
                 this.$store.dispatch("userModule/LoginUser",this.login);
                 this.ermsg=''
            }
             if(this.login.username == '' ) {
                this.ermsg1 = "Mobile number is required"
            }
            if(this.login.password == '' ) {
                this.ermsg2 = "Password is required"
            }
           
        },
        showPassword() {
                if(this.type === 'password') {
                    this.type = 'text'
                    this.btnText = 'fa-eye'
                } else {
                    this.type = 'password'
                    this.btnText = 'fa-eye-slash'
                }
        },
        reset(ev){
            this.ermsg1=""
            this.ermsg2=""
            if(this.$store.getters['userModule/getUser']) {
                            //alert("test1")
                            this.$store.commit('userModule/ClearLogin');
            }
            this.loader=false
            if(this.$store.getters['userRegister/regMessage']) {
                    this.$store.commit('userRegister/emptyMessage');
                
            }
            if(ev=='ph'){
                this.onld=''
            }
            
        },
     checkP(){
        if(this.login.username !=''){
            this.onld=''
        }
        else {
            this.onld= 'hideOnlod'
        }
     },
     filterNonNumeric() {
					// Replace non-numeric characters with an empty string
                    
					this.login.username = this.login.username.replace(/[^0-9]/g, "");
				},
                clearmessage(){
                    this.$store.commit('ChangeModule/resetState');
                }
        
        },
        
    
}
</script>