
<template>
    <div class="ExperienceEducation"> 
      
        <div class="tab-c">
            <div class="thd">
                <span >Experience</span>
            </div>
            <div>
                <!-- <p>Lorem Ipsum is simply dummy text of the printing and 
                    typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p> -->
                <ul>
                    <li>
                        <b>{{detils.service_provider_designation}}( {{detils.service_provider_time_period}})</b>
                        <span>{{detils.service_provider_organisation}}</span>
                    </li>
                    <!-- <li>
                        <b>General Dentistry ( 2016 - 2018 )</b>
                        <span>Smile Dental Clinic</span>
                    </li>
                    <li>
                        <b>General Dentistry ( 2016 - 2018 )</b>
                        <span>Smile Dental Clinic</span>
                    </li> -->
                </ul>
            </div>
          
        </div>
        <div class="tab-c">
            <div class="thd">
                <span >Education</span>
            </div>
            
            <div >
                <!-- <p>Lorem Ipsum is simply dummy text of the printing and 
                    typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p> -->
                <ul>
                    <li>
                        <b>{{detils.education.course}} ( {{detils.education.year}} )</b>
                        <span>{{detils.education.univercity}}</span>
                    </li>
                    <li  v-for="(edu,index) in detils.additional_education" :key="index">
                        <b>{{edu.course}} ( {{edu.year}} )</b>
                        <span>{{edu.univercity}}</span>
                    </li>
                </ul>
            </div>
        </div>
         
    </div>
</template>

<script>
export default {
    name: "ExperienceEducation",
    data(){
        return{
            head:[ 
            'Experience',
                'Education'
                
        ],
        selectedLink:'',
        
        }
    },
    props: {
        detils:{
            type: Object
        }
    },
    created() {

            this.tabs('Experience');
        },
    methods: {
            tabs(type) {
              this.selectedLink = type;
            //   this.selectedLink2 = type;
            //   this.tabc(type);
                
            },
            
        }
    
}
</script>