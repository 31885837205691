<template>
    <div class="signin forgot OTPs"> 
        
        <div class="authform">
            
            <div>
                <div class="vrfy-otp" >
                    <span v-if="user.Status==2 && otpMessage == '' " class="otpnot">{{ user.Message }}</span> 
                    <span v-if="otpMessage && otpMessage.Status==1" class="alrt_scss ">{{ otpMessage.Message }}</span>  
                    <span v-if="otpMessage && otpMessage.Status!=1" class="otpnot">{{ otpMessage.Message }}</span> 
                                        
                </div>
                <AuthTitle :title="title" ></AuthTitle>

                <span  style="font-size: 10px;text-align: center;width: 100%;display: block;" v-if="!otpMessage && otpG">
                    OTP : {{ this.otpG }}
                </span>
                <span style="font-size: 10px;text-align: center;width: 100%;display: block;" v-if="otpMessage">
                    OTP : {{ this.otpMessage.Data.otp }}
                </span>
                <!-- <span style="font-size: 10px;text-align: center;width: 100%;display: block;" v-if="user">
                    OTP : {{ this.user.Data.otp }}
                </span> -->
                
                
                <div class="form" >
                    <label>OTP
                        <input v-model="otp.code" @focus="activer" maxlength="6" @input="filterNonNumeric">
                        <span class="error" v-if="error" style="text-align: left !important;">{{ error }}</span>
                    </label>
                    <button @click="verifyOTP">Proceed</button>
                    <span class="loader" v-if="loader  && otpMessage =='' && ErrorC==''"></span>
                    <span class="error" v-if="ErrorC">{{ ErrorC.Message }}</span>
                    <button  v-if="user.Status !=2 || otpMessage !=''" class="resend"  @click="resendOtp()" :class="{noResend: selectedLink === 'noResend'}">Resend OTP</button>
                    <button v-if="user.Status==2 && otpMessage ==''" class="resend" @click="resendOtp() " :class="{activebox: selectedLink === 'activeResend'}">Resend OTP</button>
                    
                </div>
            </div>
            
        </div>
        <img src="@/assets/images/pswd1.png" class="pos-image-auth">
        

    </div>
</template>

<script>
import AuthTitle from '@/components/Authentication/AuthTitle.vue';
import {mapGetters} from "vuex";
export default {
    name: "OtpVerification",
    components: {
        AuthTitle
    },
   
    data(){
        return {
            title:'Enter Your OTP ',  
            otp: {
                code:'',
                id:''
            }  ,
            loader:false,
            selectedLink:'',
            error:''

        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('userRegister/emptyMessage');
        this.$store.commit('userModule/ClearLogin');
        this.$store.commit('userRegister/resetMessage');
        next(); 
    },
    computed:{
    ...mapGetters({
        otpMessage: "userRegister/regMessage",
        user:"userModule/getUser", 
        otpG:'userRegister/regotp'  ,
        ErrorC: "userRegister/otpError",
        
        }),
    },
    watch: {
        otpMessage(){
            this.loader=false
        },
        otpG(){
            this.loader=false
        }
    },
    created() {
        if(this.$store.getters['userRegister/regid']) {
          this.otp.id = this.$store.getters['userRegister/regid'];
          window.sessionStorage.setItem("regid", this.otp.id );         
        }
        // if(this.$store.getters['userModule/id']){
        //     this.otp.id =this.$store.getters['userModule/id']
        //     window.sessionStorage.setItem("regid", this.otp.id ); 
        // }
        if(this.otp.id == ''){
            this.otp.id= window.sessionStorage.getItem("regid");        
        }
        if(this.user.Status==2){
            this.otp.id=this.user.Data.customer_id
            this.resendOtp()
        }
        
    },
    methods: {
        verifyOTP() {            
            if(this.otp.code !='') {
                if(this.otp.code.length==6){
                    window.sessionStorage.removeItem("regid");
                    this.$store.dispatch("userRegister/VerifyOTP",this.otp);
                    this.loader=true
                }
                else {
                    this.error= "The otp field must be 6 digit"
                }
                
            }
            else {
                this.error="Enter OTP"
            }
        
        },
        resendOtp() {
            this.loader=true
            this.selectedLink="noResend"
            this.$store.commit('userRegister/emptyMessage');
            this.$store.commit('userRegister/resetMessage');
            this.$store.dispatch("userRegister/ResendOTP",this.otp.id);
            setTimeout(() => {
                this.selectedLink=""

            }, 5000);
        },
        activer(){
            this.selectedLink=""
            this.error=''
            this.loader=false
            this.$store.commit('userRegister/resetMessage');

        },
        filterNonNumeric() {
					// Replace non-numeric characters with an empty string
					this.otp.code = this.otp.code.replace(/[^0-9]/g, "");
				},
    }
    
}
</script>