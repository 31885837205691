<template>
    <div class="specialities">        
        <div class="wrapper">
            <div class="title-sec-s">
                <h2 class="tile-h sp-h">{{title1}}</h2>
                <router-link :to="{name:'services'}" class="ssd">View All Specializations</router-link>
            </div>
            <div >
                <carousel  v-if="specialities.length > 0" 
                    :responsive="{0:{items:2,nav:false,dots:false},960:{items:5,nav:false,dots:true},600:{items:4,nav:false,dots:true},600:{items:3,nav:false,dots:true}}"
                >
                    <SingleSpeciality v-for="(item,j) in specialities" :key="j" :item="item" >
                    </SingleSpeciality>
                </carousel>
            </div>
            
        </div>
    </div>
</template>

<script>
import SingleSpeciality from '@/components/Home/SingleSpeciality.vue';
import carousel from 'vue-owl-carousel';
// import {mapGetters} from "vuex"
export default {
    name: "FeaturedSpeciality",
    components: { 
        SingleSpeciality,
        carousel
    
    },
    props: {
        
        title1: {
            type: String,
            required: true,
            default: 'Specialities'
        },
        specialities: {
            type: Array,
            required: true,
        },
    },
    data() {
		return {
			v1:[]
            }
    },
    created() {
        
        // this.getSpecialities()
        // this.startDelayedGet()
    },
    computed:{
        // ...mapGetters({
        //     specialities:"searchModule/specialties",
            
        // }), 
     

    },
    
    methods: {
    //     getSpecialities() {
    //     this.$store.dispatch("searchModule/getSpecialities")
    //   },
    //   startDelayedGet() {
    //     setTimeout(() => {
    //         this.v1 = this.specialities; // Access the getter after a delay
    //     }, 2000); // Delay of 2000 milliseconds (2 seconds)
    //     }
    }
        
    
}
</script>