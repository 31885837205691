<template>
    <div class="detail_page_personal"> 
        <h3>{{ detail.service_provider_name }}</h3>
        <div class="qualifictn">{{ detail.service_provider_qualification }}</div>
        <!-- <div class="star-rate">
            <span v-for="(n,index) in 5" :key="index" :class="n <= detail.StarRating ? 'yellow' : 'gray'">
                <font-awesome-icon icon="fa-solid fa-star" />
            </span>
        </div> -->
        <div class="loc">
            <font-awesome-icon icon="fa-solid fa-location-dot" />
            <p>{{ detail.service_provider_address }}</p>
        </div>
        <!-- <p class="exp">{{ detail.Experience }}</p> -->
       
        {{  trimmedDescription  }}
        <div class="buttons_det">
            
            <button @click="removeToFav(detail.id)" v-if="favourite" class="alrdy-add" :class="{opacityCls: change == 'loading'}">
                <font-awesome-icon icon="fa-solid fa-heart" /> 
                Remove from Fav
            </button>
            <button @click="addToFav(detail.id)" :class="{opacityCls: change == 'loading'}"  v-else><font-awesome-icon icon="fa-solid fa-heart" />
                 Add to Fav
            </button>
            
            <!-- <button><font-awesome-icon icon="fa-solid fa-share-nodes" /> Share</button> -->
        </div>
        
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "PersonalInfo",
    data(){
        return {
            favourite:false,
            change:''
        }
    },
    props: {
        detail:{
            type: Object,
            required: true
        },

    },
    computed:{
        ...mapGetters({
        Added:"providerModule/added",
        remove:"providerModule/removed",
        
        }),
        trimmedDescription() {
            if (this.detail.service_provider_description != '' && this.detail.service_provider_description != null) {
                let des = this.detail.service_provider_description; // Initialize the variable

                if (des.startsWith('<p>') && des.endsWith('</p>')) {
                    des = des.slice(3, -4);
                }

                    return des;
                } else {
                    return this.detail.service_provider_description;
                }
            }
            
    },
    watch: {
        detail() {
            if(this.detail.favorite==1){
                this.favourite=true
            }
           
        },
        Added(newValue) {
            if(newValue){
                this.change=''
                this.favourite=true
            }
           
        },
        remove(newValue) {
            if(newValue){
                this.change=''
                this.favourite=false
            }
           
        },

    },
    mounted(){
        if(this.detail.favorite==1){
            this.favourite=true
        }
    },
    methods: {
        addToFav(id){
            this.change='loading'
            this.$store.commit("providerModule/clearFav")
            this.$store.dispatch("providerModule/addtoFav", id)
        },
        removeToFav(id){
           //alert(id);
           this.change='loading'
           this.$store.commit("providerModule/clearFav")
            this.$store.dispatch("providerModule/removeFav", id)
        }
    }
    
}
</script>