import router from '@/routers'
import bookingService from "@/api-services/services/booking.service";
// import router from '@/routers'
export const bookingModule = {
    // This makes your getters, mutations, and actions accessed by, eg: 'myModule/myModularizedNumber' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,
    state: {
        historyList:[],
        timeslots:[],
        timeslotsById:[],
        bookingSummary:[],
        bookingError:[],
        bookingList:[],
        Notimeslots:[],
        cancelBookings:[],
        bookingbyID:'',
        ConfirmationMessage:'',
        confirmationError:'',
    },
    mutations: {
        
        history:(state,{data})=> {
            state.historyList= data          
            
        },
        bookings:(state,{data})=> {
            state.bookingList= data          
            
        },
        slots:(state,{data})=> {
            state.timeslots= data          
            
        },
        noslots:(state,{data})=> {
            state.Notimeslots = data          
            
        },
        slotsById:(state,{data})=> {
            state.timeslotsById= data          
            
        },
        clearSlot:(state)=> {
            state.timeslots= ''  
            state.Notimeslots=''        
            
        },
        clearError:(state)=> {
            //alert("test")
            // state.timeslots= ''; 
            state.bookingError =''         
            
        },
        successBooking:(state,{data})=> {
            state.bookingSummary= data  
            router.push({ name: "booksummary"});      
            
        },
        confirmed:(state,{data})=> {
            state.ConfirmationMessage= data  
            router.push({ name: "confirmed"});       
            
        },
        ErrorConfirmation:(state,{data})=> {
            state.confirmationError= data      
            
        },
        ErrorBooking:(state,{data})=> {
            state.bookingError= data          
            
        },
        cancelled:(state,{data})=> {
            state.cancelBookings= data          
            router.push({ name: "mybookings"}); 
        },
        clearCancelMessage:(state)=> {
            state.cancelBookings=''
        },
        bookingDetail:(state,{data})=> {
            state.bookingbyID= data          
        },
        // clearError:(state)=> {
        //     state.bookingError=''
        // }
        
    },
    actions: {
        async getHistory({commit},pageid) {
            //alert("test")
            let bearerToken = localStorage.getItem('token');
            const response = await bookingService.getBookingHistory(bearerToken,pageid);
            
            if(response.data.Status == 1) {
            
                const data = response.data.Data
                //console.log(data);
                commit("history",{data} ); 
                
            }
            
        },
        async getTimeSlots({commit},dateid) {
            //alert("test")
            // commit("clearSlot"); 
            let bearerToken = localStorage.getItem('token');
            const response = await bookingService.getSlots(bearerToken,dateid);
            if(response.data.Status == 1) {           
                const data = response.data.Data
               //console.log(data);
                commit("slots",{data} );
            }
                else {
                    const data = response.data
                    commit("noslots",{data} );
                }
            
        },
        async loadTimeByID({commit},passToTimeslot) {
            //alert("test")
            let bearerToken = localStorage.getItem('token');
            const response = await bookingService.getSlotsByid(bearerToken,passToTimeslot);
                      
                const data = response.data.Data
               //console.log(data);
                commit("slotsById",{data} );
            
        },
        async createBooking({commit},info) {
            //alert("test")
            let bearerToken = localStorage.getItem('token');
            const response = await bookingService.CreateBooking(bearerToken,info);
            
            if(response.data.Status == 1) {            
                const data = response.data.Data
                //console.log(data);
                commit("successBooking",{data} ); 
                sessionStorage.setItem('BookingID', data.booking_id);
            }
            else {
                const data = response.data
                commit("ErrorBooking",{data} );                
            }
            
        },
        async confirmBooking({commit},info) {
            //alert("test")
            let bearerToken = localStorage.getItem('token');
            const response = await bookingService.ConfirmBooking(bearerToken,info);
            
            if(response.data.Status == 1) {            
                const data = response.data.Data
                //console.log(data);
                commit("confirmed",{data} ); 
            }
            else {
                const data = response.data
                commit("ErrorConfirmation",{data} );                
            }
            
        },
        
        async UpdateBooking({commit},info) {
            //alert("test")
            let bearerToken = localStorage.getItem('token');
            const response = await bookingService.UpdateBooking(bearerToken,info);
            
            if(response.data.Status == 1) {            
                const data = response.data.Data
                //console.log(data);
                sessionStorage.setItem('BookingID', data.booking_id);
                commit("successBooking",{data} ); 
            }
            else {
                const data = response.data
                commit("ErrorBooking",{data} );                
            }
            
        },
        async cancelBooking({commit},id) {
            //alert("test")
            let bearerToken = localStorage.getItem('token');
            const response = await bookingService.cancelBooking(bearerToken,id);
            
            if(response.data.Status == 1) {            
                const data = response.data
                //console.log(data);
                commit("cancelled",{data} ); 
            }
            // else {
            //     const data = response.data
            //     commit("ErrorBooking",{data} );                
            // }
            
        },
        async getBookings({commit},pageid) {
            //alert("test")
            let bearerToken = localStorage.getItem('token');
            const response = await bookingService.getBookings(bearerToken,pageid);        
                const data = response.data.Data
                //console.log(data);
                commit("bookings",{data} ); 
            
        },
        async bookingDetails({commit},id) {
            //alert("test")
            let bearerToken = localStorage.getItem('token');
            const response = await bookingService.getBookingDetails(bearerToken,id);        
                const data = response.data.Data
                //console.log(data);
                commit("bookingDetail",{data} ); 
            
        },
        
       
     

    },
    getters: {
        histories: (state) => state.historyList,
        slotList: (state) => state.timeslots,
        slotDet: (state) => state.timeslotsById,
        Summary: (state) => state.bookingSummary,
        error: (state) => state.bookingError,
        Bookinglist: (state) => state.bookingList,
        noslotMessage: (state) =>state.Notimeslots,
        detailsBooking: (state) =>state.bookingbyID,
        ConfrmError:(state) => state.confirmationError,
        ConfmMessage:(state) => state.ConfirmationMessage,
        cancel:(state) =>state.cancelBookings.Message
    }
};
  
//   export default userModule