<template>
    <div class="family_Block">
        <div class="updateSuccess  popup-login " v-if="showAlert" >
            <div>
                <p>Are you sure you want to delete {{ family.name }} ?</p>
                <div class="yN" >
                    <button @click="CloseAlert">Cancel</button>
                    <button @click="deleteFam(family.id)">Confirm</button>
                </div>
            </div>
        </div>
        <div class="fm_t">
            <div class="name_fm">
                <h2>{{ family.name }}</h2>
                <div class="rel">
                    <span>{{ family.relation.name }}</span>
                    <span>{{ family.age }} Years</span>
                </div>
            </div>
            <div class="fm_actions">
                <button>
                    <router-link :to="{name:'editFam',params:{famID:family.id }}"  >
                         <font-awesome-icon icon="fa-solid fa-pen" /> Edit
                     </router-link>
                </button>
                <button @click="showAlertfn"><font-awesome-icon :icon="['fas', 'trash-can']" /> Delete</button>
            </div>
        </div>
        <div class="fm_det_c">
            <div class="bf">
                <div>
                    <span>DOB <b>:</b></span>
                    <span>{{ family.dob }}</span>
                </div>
                <div>
                    <span>Gender<b>:</b></span>
                    <span v-if="family.gender_id==2">Female</span>
                    <span v-if="family.gender_id==1">Male</span>
                    <span v-if="family.gender_id==3">Others</span>
                </div>
            </div>
            <div class="bf">
                <div>
                    <span>Email<b>:</b></span>
                    <span>{{ family.email }}</span>
                </div>
                <div>
                    <span>Mobile<b>:</b></span>
                    <span>{{ family.mobile }}
                        <b class="verified-fam"  v-if="family.is_otp_verified==1"><font-awesome-icon icon="fa-solid fa-circle-check" /> Mobile Number Verified</b>
                        <b class="not-verified-fam" v-else @click="verifyFam(family.mobile)"><font-awesome-icon :icon="['fa', 'circle-xmark']" /> Verify Mobile Number</b>
                    </span>
                    
                </div>
                    
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "FamilyBlock",
    data(){
        return{
            showAlert:false
        }
    },
    props: {
        family:{
            type: Object,
            required: true
        }
    },
    computed: {
    
  },

  methods:{
    showAlertfn(){
        this.showAlert=true
    },
    CloseAlert(){
        this.showAlert=false
    },
    deleteFam(id){
        //alert(id);
            this.$store.dispatch("familyModule/deleteFamily",id)
            this.showAlert=false
        },
    verifyFam(mob){
        this.$store.dispatch('familyModule/vefifyFamily', mob);
            
    }
  }
   
    
    
}
</script>
<style>
/* .popup-login {
    background: #00000008 !important;
} */
</style>