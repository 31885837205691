<template>
    <div class="authTitle"> 
        <h2>{{ title }}</h2>
    </div>
</template>

<script>
export default {
    name: "AuthTitle",
    props: {
        title: {
            type: String,
            required: true
        },
    },
    
}
</script>