<template>
    <div class="round_msg"> 
        <div>
            <h4>{{ message.title1 }}</h4>
            <p>{{ message.Para }}</p>
            <router-link :to="{name:message.Link}">
                {{message.button}}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "RedirectSection",
    props: {
        message: {
            type: Object,
            required: true
        },
    },
}
</script>