<template>
    <div class="single_box item"> 
        <img :src="item.speciality_icon_url" >
        <h3>{{ item.speciality_name }}</h3>
        <a @click="book">Book Now</a>
    </div>
</template>

<script>
export default {
    name: "SingleSpeciality",
    props: {
        item: {
        type: Object,
        required: true
        },
    },
    data(){
        return{
      searchFilters:{
        city:'',
        location:'',
        category:this.item.category_id,
        subcategory:'',
        specialty:this.item.id,
        date:'',
        name:''
      },
        }

    },
    methods: {
        book(){
            //alert("test")
            this.$store.dispatch("providerModule/searchProviders", this.searchFilters)
        }
    }
    
}
</script>
<style>
a{
 cursor: pointer;
}
</style>