import Vue from 'vue';
import Vuex from 'vuex';
import { userModule } from './userlogin';
import { userRegister } from './userRegister';
import {searchModule } from './search';
import {ChangeModule} from './changepassword';
import {providerModule} from './serviceprovider'
import {accountModule} from './myaccount'
import {familyModule} from './family'
import {bookingModule} from './booking'
import {kycModule} from './kyc'
import {pagesModule} from './mypages'

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    userModule,
    userRegister,
    searchModule,
    ChangeModule,
    providerModule,
    accountModule,
    familyModule,
    bookingModule  ,
    kycModule  ,
    pagesModule
  }
});