
<template>
    <div class="booking_block responsive-booking-blck"> 
          <div class="date">
            {{ formattedDate }}
          </div>
          <div class="img_bk">
            <img :src="booking.service_provider.service_provider_photo_url">
          </div>
          <div class="booking_d">
            <div>
                <h3>{{ booking.service_provider.service_provider_name }}</h3>
                <span>{{ booking.service_provider.category.category_name  }}</span><br>
                <span>{{ booking.service_provider.specialty.speciality_name }}</span>
                <p class="time_bk" >{{ formattedList.booking_time_from }}-{{ formattedList.booking_time_to }}</p>
                <button :class="getStatusClass(booking.status.id)">{{ booking.status.book_status }}</button>
            </div>
          </div>
          <div class="bt_bk">
            <div>
                <button @click="saveID(booking.id)">
                    <router-link :to="{name:'BookingDetail',params:{bookingid:booking.id }}"  >
                        View Details
                    </router-link>
                    
                </button>
                <button :class = "getStatusClass1(booking.status.id)">
                  <a :href="`https://www.google.com/maps/search/?api=1&query=${booking.service_provider.service_provider_latitude},${booking.service_provider.service_provider_longitude}`" target="_blank">
                    Get Directions
                  </a>
                </button>
            </div>
          </div>
    </div>
</template>

<script>
export default {
    name: "BookingSingle",
    
    props: {
        booking:{
            type: Object,
            required: true
        }
    },
    computed: {
      formattedDate() {
          const months = [
            "January", "February", "March", "April",
            "May", "June", "July", "August",
            "September", "October", "November", "December"
          ];
          
          // Get the booking_date from this.booking and create a Date object
          const booking_date = this.booking.booking_date;
          const date = new Date(booking_date);

          // Extract day and month components
          const day = date.getDate();
          const monthIndex = date.getMonth();

          // Get the month name from the months array
          const month = months[monthIndex];

          // Return the formatted date
          return `${day}\n${month}`;
        },
        // formattedDay() {
        //         const dateObject = new Date(this.timeslot.day);

        //         // Array of day names
        //         const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        //         // Get the day of the week (0 for Sunday, 1 for Monday, etc.)
        //         const day = dayNames[dateObject.getDay()];
                
        //         return `${day} `;
        //         },
                formattedList() {
                    const formattedTime = { ...this.booking };

                    formattedTime.booking_time_from = this.formatTime12Hr(this.booking.booking_time_from);
                    formattedTime.booking_time_to = this.formatTime12Hr(this.booking.booking_time_to);

                    return formattedTime;
                 },
    },
    methods: {
      getStatusClass(id) {
        if (id === 3 || id === 4) {
          return "redCancel";
        } else if (id === 5 || id === 2) {
          return "Confm";
        } else {
          return "pndng";
        }
      },
      getStatusClass1(id) {
        if (id === 3 || id === 4) {
          return "pndngD";
        } else if (id === 5 || id === 2) {
          return "ConfmD";
        } else {
          return "pndngD";
        }
      },
      formatTime12Hr(timeString) {
            const [hours, minutes] = timeString.split(':');
            const parsedHours = parseInt(hours, 10);
            const period = parsedHours >= 12 ? 'PM' : 'AM';
            const formattedHours = parsedHours % 12 === 0 ? 12 : parsedHours % 12;

            return `${formattedHours}:${minutes} ${period}`;
        },
        // saveID(id){
        //   sessionStorage.setItem('bookId', id);
        // }
    }
    
}
</script>